import sb from '@sb/util'

/*
 message_struct
 {
 id: 'string',  // use for replace
 title: 'string',
 description: 'string',
 type: 'success', 'warning', 'danger', 'default', // first 3 is alert, default is instant_noti, and some custom type like taks_expired
 priority: 'low', 'high' // high for task_expire, always display
 primary_button: {
 lalbel:
 onclick: () => {}
 }
 secondary_button:
 data: {} // custom data for event like user_created, lead_created


 }
 * */

const DISPLAY_SIZE = 3 // num of message noti should display, the last slot this for importan noti, overlap, remain slot is for recent message
const VANISH_DURATION = 5_000 // ms
const DURATION_LIMIT = 100_000 // ms to keep notif dont disappear automatically
const CLEAR_ALL_DURATION = 300_000 // 5 mins duration for agent dont want to be disturbed by notis by click clear all
const IMPORTANT_NOTI_CLOSE_DURATION = 24 * 3_600_000 // 1 day for next display of important message after agent close it intentionly

export default {
	name: 'noti-message-manager',

	data() {
		return {
			allMessages: {}, // [id] : message obje
			recentMessageIds: [], // this state for list noti exclude important noti and for calculate overlaps, always desc by creaed
		}
	},

	created() {
		this.$root.$on('push_noti_message', this.pushMessage)
		this.$root.$on('remove_noti_message', this.onRemoveNotiEvent)
		this.timeoutMaps = {}
	},

	methods: {
		pushMessage(message) {
			// always allow push showSuccess, showDanger event click clearAll
			if (
				this.muteAllNotiTimer &&
				Date.now() - this.muteAllNotiTimer < CLEAR_ALL_DURATION &&
				!['success', 'danger'].includes(lo.get(message, 'type'))
			)
				return
			this.genDefaultValue(message)
			if (this.allMessages[message.id]) return
			if (this.hasCloseNotiOptions(message)) return
			this.allMessages[message.id] = message
			this.$forceUpdate()
			this.calculateRecentIds(message)
			this.registerRemoveMsgTimeout(message)
		},

		hasCloseNotiOptions(message) {
			if (message.type === 'integration_error') {
				let options = store.matchCloseNotiOptions() || {}
				if (Date.now() - options.integration_error <= IMPORTANT_NOTI_CLOSE_DURATION) return true
				return false
			}
			if (message.id === 'payment_noti') {
				let options = store.matchCloseNotiOptions() || {}
				if (Date.now() - options.payment_noti <= IMPORTANT_NOTI_CLOSE_DURATION) return true
				return false
			}
			return false
		},

		onRemoveNotiEvent(params) {
			let msg = {}
			if (typeof params === 'string') {
				msg.id = params
			} else if (typeof params === 'object') {
				msg = params
			}
			this.removeMessage(msg)
		},

		registerRemoveMsgTimeout(message) {
			if (message.duration) {
				if (message.duration >= DURATION_LIMIT) return
				let timeout = setTimeout(() => {
					return this.removeMessage(message)
				}, message.duration)
				this.timeoutMaps[message.id] = timeout
			}
		},

		calculateRecentIds(pushedMessage) {
			// calculate recent ids
			if (lo.size(this.allMessages) <= 1) {
				this.recentMessageIds = []
			} else {
				this.recentMessageIds = [pushedMessage.id, ...this.recentMessageIds]
				if (lo.size(this.recentMessageIds) > DISPLAY_SIZE - 1) {
					this.recentMessageIds = lo.take(this.recentMessageIds, DISPLAY_SIZE - 1)
				}
			}
		},

		removeMessage(message) {
			if (message.type === 'integration_error') this.closeIntegrationError()
			if (message.type === 'overdue_tasks' || message.type === 'task_reminded') this.closeOverdueTask()
			if (message.id === 'payment_noti') this.closePaymentNoti()
			if (this.timeoutMaps[message.id]) clearTimeout(this.timeoutMaps[message.id])
			delete this.allMessages[message.id]
			this.$forceUpdate()
			// remove cecent ids
			if (this.recentMessageIds.includes(message.id)) {
				this.recentMessageIds = lo.filter(this.recentMessageIds, (id) => id !== message.id)
			}
		},

		genDefaultValue(message) {
			if (!message.id) message.id = Date.now() + sb.randomString(10)
			if (!message.created) message.created = Date.now()
			if (!message.priority) message.priority = 'low'
			if (!message.duration) message.duration = VANISH_DURATION

			// keep important noti dont disabpear
			if (message.priority === 'high') message.duration = DURATION_LIMIT
		},

		renderIntegrateError(message) {
			let intes = message.data
			let zaloIntes = lo.filter(intes, (inte) => inte.connector_type === 'zalo')
			let fbIntes = lo.filter(intes, (inte) => inte.connector_type === 'facebook')

			let desc = this.$t('detect_fb_and_zalo_inte_unstable')
			let $buttons = (
				<Fragment>
					<div class='noti_message_item_primary_btn' vOn:click={() => this.goToFBSetting(message)}>
						{this.$t('integrate')} FB
					</div>
					<div class='noti_message_item_primary_btn' vOn:click={() => this.goToZaloSeting(message)}>
						{this.$t('integrate')} OA
					</div>
				</Fragment>
			)
			let title = this.$t('unstable_integration')
			if (!lo.size(zaloIntes)) {
				let firstInteName = lo.get(fbIntes, '0.name')
				if (lo.size(fbIntes) >= 3) {
					desc = `${firstInteName} ${this.$t('and').toLowerCase()} ${lo.size(fbIntes) - 1} ${this.$t('other_fanpages')} ${this.$t('fb_inte_unstable_desc')}`
				} else if (lo.size(fbIntes) === 2) {
					let secondInteName = lo.get(fbIntes, '1.name')
					desc = `Fanpage ${firstInteName} ${this.$t('and').toLowerCase()} ${secondInteName} ${this.$t('fb_inte_unstable_desc')}`
				} else {
					desc = `Fanpage ${firstInteName} ${this.$t('fb_inte_unstable_desc')}`
				}
				$buttons = (
					<div class='noti_message_item_primary_btn' vOn:click={() => this.goToFBSetting(message)}>
						{this.$t('integrate_again')}
					</div>
				)
				title = this.$t('unstable_integration_fb')
			} else if (!lo.size(fbIntes)) {
				let firstInteName = lo.get(zaloIntes, '0.name')
				if (lo.size(zaloIntes) >= 3) {
					desc = `${firstInteName} ${this.$t('and').toLowerCase()} ${lo.size(zaloIntes) - 1} ${this.$t('other_oa')} ${this.$t('zalo_inte_unstable_desc')}`
				} else if (lo.size(zaloIntes) === 2) {
					firstInteName = lo.get(zaloIntes, '0.name')
					let secondInteName = lo.get(zaloIntes, '1.name')
					desc = `OA ${firstInteName} ${this.$t('and').toLowerCase()} ${secondInteName} ${this.$t('zalo_inte_unstable_desc')}`
				} else {
					desc = `OA ${firstInteName} ${this.$t('zalo_inte_unstable_desc')}`
				}
				$buttons = (
					<div class='noti_message_item_primary_btn' vOn:click={this.goToZaloSeting}>
						{this.$t('integrate_again')}
					</div>
				)
				title = this.$t('unstable_integration_zalo')
			}

			return (
				<div class={{noti_message_item: true, danger: true}}>
					<div class='noti_message_item_icon_wrapper big'>
						<Icon name='link-off' class='noti_message_item_icon' />
					</div>
					<div class='noti_message_item_content'>
						<div class={{text__semibold: true}}>{title}</div>
						<div class='text__muted text__sm'>{desc}</div>
						<div class='noti_message_item_buttons'>{$buttons}</div>
					</div>
					<div class='noti_message_item_close_btn' vOn:click_stop={() => this.removeMessage(message)}>
						<Icon name='x' size='16' stroke-width='2' />
					</div>
				</div>
			)
		},

		closeIntegrationError(message) {
			let currentOptions = store.matchCloseNotiOptions() || {}
			currentOptions.integration_error = Date.now()
			store.updateCloseNotiOptions(currentOptions)
		},
		closePaymentNoti(message) {
			let currentOptions = store.matchCloseNotiOptions() || {}
			currentOptions.payment_noti = Date.now()
			store.updateCloseNotiOptions(currentOptions)
		},

		goToFBSetting(message) {
			this.$router.push({name: 'settings.messenger'})
			this.removeMessage(message)
		},

		goToZaloSeting(message) {
			this.$router.push({name: 'settings.zalo'})
			this.removeMessage(message)
		},

		onClickReminderNoti(message, task) {
			store.closeTaskReminder(task.id)
			this.$root.$emit('open_tasks_modal', {task_id: task.id})
			this.removeMessage(message)
		},

		selectDelay(message, task, option) {
			this.removeMessage(message)

			if (option.id === 0) {
				let done = store.markTaskDone(task.id)
				return
			}
			let new_due_at = Date.now() + option.id
			store.updateTask({id: task.id, due_at: new_due_at}, ['due_at'])
			this.removeMessage(message)
			return
		},

		async closeOverdueTask(message) {
			let out = await store.fetchAgent(store.me().id)
			let me = lo.cloneDeep(out.body)
			if (!me || !me.id) return
			let new_close_task_noti = Date.now()
			lo.set(me, 'dashboard_setting.last_close_task_notification', new_close_task_noti)
			store.updateAgent({...me, _update_fields: ['dashboard_setting']})
		},

		renderRemindedTask(message) {
			let data = message.data || {}
			let taskCount = data.task_count || 0
			let ticketCount = data.ticket_count || 0

			let text = this.$t('you_have_count_reminded_tasks', [taskCount])
			let $buttons = <div class='btn btn__sm btn__outline_primary'>{this.$t('resolve_now')}</div>
			if (taskCount && ticketCount) {
				text = this.$t('you_have_count_reminded_tasks_and_tickets', [taskCount, ticketCount])
				$buttons = (
					<Fragment>
						<div
							class='btn btn__sm btn__outline_primary mr-3'
							vOn:click_stop={() => {
								this.$router.push({name: 'ticket-list', query: {view_id: `tvdefault_${store.me().id}_myview`}})
								this.removeMessage(message)
							}}
						>
							{this.$t('resolve_tickets')}
						</div>
						<div
							class='link'
							vOn:click_stop={() => {
								this.$root.$emit('open_tasks_modal', {filter: 'list_me_open'})
								this.removeMessage(message)
							}}
						>
							{this.$t('resolve_tasks')}
						</div>
					</Fragment>
				)
			} else if (ticketCount) {
				text = this.$t('you_have_count_reminded_tickets', [ticketCount])
			}

			return (
				<div
					class={'noti_message_item clickable'}
					vOn:click={() => {
						if (lo.size(message._overlaps)) {
							this.onClickMessageOverlapped(message)
							return
						}
						if (ticketCount) {
							this.$router.push({name: 'ticket-list', query: {view_id: `tvdefault_${store.me().id}_myview`}})
						} else {
							this.$root.$emit('open_tasks_modal', {filter: 'list_me_open'})
						}
						this.removeMessage(message)
					}}
				>
					<div class='noti_message_item_img_wrapper' style='position: relative; top: 5px'>
						<img src={require('./assets/img/reminders.png')} class='noti_message_item_img' />
					</div>
					<div class='noti_message_item_content'>
						<div class='text__semibold'>{text}</div>
						<div class='mt-3 d-flex align-items-center'>{$buttons}</div>
					</div>
					<div class='noti_message_item_close_btn' vOn:click_stop={() => this.removeMessage(message)}>
						<Icon name='x' size='16' stroke-width='2' />
					</div>
				</div>
			)
		},

		renderOverdueTask(message) {
			let data = message.data || {}
			let taskCount = data.task_count || 0
			let ticketCount = data.ticket_count || 0
			let remindedTasksCount = data.reminded_task_count || 0
			let remindedTicketsCount = data.reminded_ticket_count || 0

			let text = this.$t('you_have_count_overdue_tasks', [taskCount])
			let $buttons = <div class='btn btn__sm btn__outline_primary'>{this.$t('resolve_now')}</div>
			if (taskCount && ticketCount) {
				text = this.$t('you_have_count_overdue_tasks_and_tickets', [taskCount, ticketCount])
				$buttons = (
					<Fragment>
						<div
							class='btn btn__sm btn__outline_primary mr-3'
							vOn:click_stop={() => {
								this.$router.push({name: 'ticket-list', query: {view_id: `tvdefault_${store.me().id}_myview`}})
								this.removeMessage(message)
							}}
						>
							{this.$t('resolve_tickets')}
						</div>
						<div
							class='link'
							vOn:click_stop={() => {
								this.$root.$emit('open_overdue_tasks')
								this.removeMessage(message)
							}}
						>
							{this.$t('resolve_tasks')}
						</div>
					</Fragment>
				)
			} else if (ticketCount) {
				text = this.$t('you_have_count_overdue_tickets', [ticketCount])
				if (remindedTasksCount) {
					text = this.$t('you_have_count_overdue_tickets_and_reminded_tasks', [ticketCount, remindedTasksCount])
					$buttons = (
						<Fragment>
							<div
								class='btn btn__sm btn__outline_primary mr-3'
								vOn:click_stop={() => {
									this.$router.push({name: 'ticket-list', query: {view_id: `tvdefault_${store.me().id}_myview`}})
									this.removeMessage(message)
								}}
							>
								{this.$t('resolve_tickets')}
							</div>
							<div
								class='link'
								vOn:click_stop={() => {
									this.$root.$emit('open_tasks_modal', {filter: 'list_me_open'})
									this.removeMessage(message)
								}}
							>
								{this.$t('resolve_tasks')}
							</div>
						</Fragment>
					)
				}
			} else if (taskCount) {
				if (remindedTicketsCount) {
					text = this.$t('you_have_count_overdue_tasks_and_reminded_tickets', [taskCount, remindedTicketsCount])
					$buttons = (
						<Fragment>
							<div
								class='btn btn__sm btn__outline_primary mr-3'
								vOn:click_stop={() => {
									this.$root.$emit('open_overdue_tasks')
									this.removeMessage(message)
								}}
							>
								{this.$t('resolve_tasks')}
							</div>
							<div
								class='link'
								vOn:click_stop={() => {
									this.$router.push({name: 'ticket-list', query: {view_id: `tvdefault_${store.me().id}_myview`}})
									this.removeMessage(message)
								}}
							>
								{this.$t('resolve_tickets')}
							</div>
						</Fragment>
					)
				}
			}

			return (
				<div
					class={'noti_message_item clickable'}
					vOn:click={() => {
						if (lo.size(message._overlaps)) {
							this.onClickMessageOverlapped(message)
							return
						}
						if (ticketCount) {
							this.$router.push({name: 'ticket-list', query: {view_id: `tvdefault_${store.me().id}_myview`}})
						} else {
							this.$root.$emit('open_overdue_tasks')
						}
						this.removeMessage(message)
					}}
				>
					<div class='noti_message_item_img_wrapper' style='position: relative; top: 5px'>
						<img src={require('./assets/img/overdue_task.png')} class='noti_message_item_img' />
					</div>
					<div class='noti_message_item_content'>
						<div class='text__semibold'>{text}</div>
						<div class='mt-3 d-flex align-items-center'>{$buttons}</div>
					</div>
					<div class='noti_message_item_close_btn' vOn:click_stop={() => this.removeMessage(message)}>
						<Icon name='x' size='16' stroke-width='2' />
					</div>
				</div>
			)
		},

		renderAlert(message) {
			let title = message.title
			if (!lo.trim(title)) {
				if (message.type === 'danger') title = this.$t('failed')
				if (message.type === 'success') title = this.$t('success')
				if (message.type === 'warning') title = this.$t('warning')
			}
			let description = message.description
			let hasDesc = lo.trim(description)

			let cls = `noti_message_item ${message.type}`
			let iconName = 'info-small'
			if (message.type === 'danger') iconName = 'x'
			if (message.type === 'success') iconName = 'check'
			if (message.type === 'warning') iconName = 'exclamation-mark'

			let hasButton = message.primary_button || message.secondary_button || message.actions
			let $buttons = null
			if (hasButton) {
				let $primary_button = null
				let $secondary_button = null
				if (message.primary_button) {
					$primary_button = (
						<div class='noti_message_item_primary_btn' vOn:click={() => this.onClickPrimary(message)}>
							{lo.get(message, 'primary_button.label')}
						</div>
					)
				}
				if (message.secondary_button) {
					$secondary_button = (
						<div class='noti_message_item_secondary_btn' vOn:click={() => this.onClickSecondary(message)}>
							{lo.get(message, 'secondary_button.label')}
						</div>
					)
				}
				$buttons = (
					<div class='noti_message_item_buttons'>
						{$primary_button}
						{$secondary_button}
					</div>
				)
			}

			return (
				<div class={cls}>
					<div class='noti_message_item_icon_wrapper'>
						<Icon name={iconName} class='noti_message_item_icon' />
					</div>
					<div class='noti_message_item_content'>
						<div class={{text__semibold: true, text__truncate: hasDesc}}>{title}</div>
						{hasDesc && <div class='text__muted text__sm'>{description}</div>}
						{$buttons}
					</div>
					<div class='noti_message_item_close_btn' vOn:click_stop={() => this.removeMessage(message)}>
						<Icon name='x' size='16' stroke-width='2' />
					</div>
				</div>
			)
		},

		onClickPrimary(message) {
			let cb = lo.get(message, 'primary_button.onclick')
			if (cb && typeof cb === 'function') {
				cb()
			}
		},

		onClickSecondary() {
			let cb = lo.get(message, 'secondary_button.onclick')
			if (cb && typeof cb === 'function') {
				cb()
			}
		},

		async onInstantNotiClick(message, noti, options = {}) {
			if (!noti.data) return null
			let data = JSON.parse(noti.data) || {}

			if (noti.type === 'order_commented' || noti.type === 'order_assigned') {
				this.removeMessage(message)

				let orderid = data.order_id
				if (!orderid) return
				this.$router.push({name: 'order-list', query: {order_id: orderid}})
				return
			}

			let uid = lo.get(data, 'user.id')
				if (noti.type === 'user_campaign_converted' || noti.type === 'campaign_user_converted' || noti.type === 'user_opened_email') uid = lo.get(data, 'user_id')
			this.removeMessage(message)
			store.readNotification(noti.id)

			if (
				noti.type === 'ticket_assigned' ||
				noti.type === 'ticket_state_updated' ||
				noti.type === 'ticket_comment_added' ||
				noti.type === 'ticket_created'
			) {
				this.$root.$emit('open_ticket', {ticket_id: data.ticket_id})
				return
			}

			if (
				noti.type === 'task_status_updated' ||
				noti.type === 'task_title_updated' ||
				noti.type === 'task_note_updated' ||
				noti.type === 'task_due_date_updated' ||
				noti.type === 'task_assigned' ||
				noti.type === 'task_reminded' ||
				noti.type === 'task_expired' ||
				noti.type === 'task_mentioned' ||
				noti.type === 'task_commented'
			) {
				this.$root.$emit('open_tasks_modal', {task_id: data.task_id})
				return
			}
			if (noti.type === 'task_deleted') {
				this.$root.$emit('open_tasks_modal', {isTaskDeleted: true})
				return
			}
			this.$root.$emit('showQuickview', {user_id: uid, create_new: options.is_send_message})
		},

		renderDefaultContent(message) {
			let title = lo.trim(message.title)
			let description = message.description
			let hasDesc = lo.trim(description)

			let cls = `noti_message_item`

			let hasButton = message.primary_button || message.secondary_button || message.actions
			let $buttons = null
			if (hasButton) {
				let $primary_button = null
				let $secondary_button = null
				if (message.primary_button) {
					$primary_button = <div class='noti_message_item_primary_btn'>{lo.get(message, 'primary_button.label')}</div>
				}
				if (message.secondary_button) {
					$secondary_button = (
						<div class='noti_message_item_secondary_btn'>{lo.get(message, 'secondary_button.label')}</div>
					)
				}
				$buttons = (
					<div class='noti_message_item_buttons'>
						{$primary_button}
						{$secondary_button}
					</div>
				)
			}

			return (
				<div class={cls}>
					<div class='noti_message_item_icon_wrapper'>
						<Icon name='info-circle' class='text__primary' stroke-width='2' />
					</div>
					<div class='noti_message_item_content'>
						<div class={{text__semibold: true, text__truncate: hasDesc}}>
							{title || <em class='text__muted'>{this.$t('untitled')}</em>}
						</div>
						{hasDesc && <div class='text__muted text__sm'>{description}</div>}
						{$buttons}
					</div>
					<div class='noti_message_item_close_btn' vOn:click_stop={() => this.removeMessage(message)}>
						<Icon name='x' size='16' stroke-width='2' />
					</div>
				</div>
			)
		},

		onMouseEnterMessage(message) {
			if (!message.duration) return
			if (message.duration >= DURATION_LIMIT) return

			if (this.timeoutMaps[message.id]) {
				clearTimeout(this.timeoutMaps[message.id])
				delete this.timeoutMaps[message.id]
			}
		},

		onMouseLeaveMessage(message) {
			if (!this.timeoutMaps[message.id]) {
				this.registerRemoveMsgTimeout(message)
			}
		},

		renderNotiMessage(message, idx) {
			let $content = this.renderDefaultContent(message)
			if (isAlert(message)) $content = this.renderAlert(message)
			if (message.type === 'instant_noti')
				$content = (
					<div class='noti_message_item'>
						<div class='noti_message_item_content'>
							<NotiItem
								noti={message.data}
								vOn:click={(noti, options) => this.onInstantNotiClick(message, noti, options)}
							/>
						</div>
						<div class='noti_message_item_close_btn' vOn:click_stop={() => this.removeMessage(message)}>
							<Icon name='x' size='16' stroke-width='2' />
						</div>
					</div>
				)
			if (message.type === 'integration_error') $content = this.renderIntegrateError(message, idx)
			if (message.type === 'overdue_tasks') $content = this.renderOverdueTask(message)
			if (message.type === 'task_reminded') $content = this.renderRemindedTask(message)

			let isOverlap = lo.size(message._overlaps) >= 1
			let isOverlapDouble = lo.size(message._overlaps) >= 2
			return (
				<div
					class={{noti_message_item_wrapper: true, overlap: isOverlap, overlap_double: isOverlapDouble}}
					key={message.id}
					vOn:mouseenter={() => this.onMouseEnterMessage(message)}
					vOn:mouseleave={() => this.onMouseLeaveMessage(message)}
					vOn:click_stop={() => this.onClickMessageOverlapped(message)}
				>
					{$content}
				</div>
			)
		},

		onClickMessageOverlapped(message) {
			if (!lo.size(message._overlaps)) return
			let messages = lo.orderBy(this.allMessages, ['created'], ['desc'])
			this.recentMessageIds = lo.map(messages, (msg) => msg.id)
			// remove overlaps
			lo.each(this.allMessages, (msg) => {
				msg._overlaps = []
			})
		},

		getDisplayMessages() {
			let remainMessages = lo.filter(this.allMessages, (msg) => !this.recentMessageIds.includes(msg.id))

			// priority: 'high' > 'low' orderby alphabet, so please alwasy give message have these 2 value
			let importantMessages = lo.orderBy(remainMessages, ['priority', 'created'], ['asc', 'desc'])
			let [importantMessage, ...overlaps] = importantMessages
			if (importantMessage) {
				importantMessage._overlaps = overlaps
			}

			let messages = lo.map(this.recentMessageIds, (id) => this.allMessages[id])
			messages.push(importantMessage)

			// trim all removed messages
			return lo.filter(messages, Boolean)
		},

		removeAllNoti() {
			lo.each(this.allMessages, (message) => {
				this.removeMessage(message)
			})
			this.recentMessageIds = []

			this.muteAllNotiTimer = Date.now()
		},
	},

	render() {
		let displayMessages = this.getDisplayMessages()
		// change html tag from div to transition-group if you want to test with animation
		let lastMsg = lo.last(displayMessages) || {}

		return (
			<div style='position: absolute; left: 30px; bottom: 35px; z-index: 999'>
				<transition-group tag='div' name='noti-msg-list'>
					{lo.map(displayMessages, this.renderNotiMessage)}
				</transition-group>

				<div
					class={{
						btn: true,
						btn__xs: true,
						btn__secondary: true,
						noti_message_clear_all_btn: true,
						hidden: lo.size(this.allMessages) < 1,
					}}
					vOn:click={this.removeAllNoti}
				>
					{this.$t('clear_all')}
				</div>
			</div>
		)
	},
}

function isAlert(message) {
	const ALERT_TYPES = ['success', 'warning', 'danger']
	return ALERT_TYPES.includes(message.type)
}

function parseHyperlinkDeltaToString(delta) {
	delta = sb.parseJSON(delta) || {}
	let ops = delta.ops || []
	let result = ''

	lo.each(ops, (op) => {
		if (lo.get(op, 'insert.hyperlink')) {
			let cid = lo.get(op, 'insert.hyperlink.conversation.id', '')
			let uid = lo.get(op, 'insert.hyperlink.user.id', '')
			result += `https://${
				process.env.ENV === 'desktop' ? 'desktop.subiz.com.vn' : 'app.subiz.com.vn'
			}/convo?cid=${cid}&uid=${uid}`
		} else {
			result += op.insert
		}
	})

	return result
}
