
export default {
	name: 'channels-picker',
	props: ['picked_channels', 'up', 'excludes'],

	render() {
		let pickedchannels = this.picked_channels
		if (isAllChannel(pickedchannels)) pickedchannels = this.allChannels()
		let all = lo.size(this.picked_channels) === 0
		if (!all) all = lo.find(this.picked_channels, (ch) => ch === '*' || ch === 'all')
		if (all) pickedchannels = this.allChannels()
		const $channels = lo.map(this.allChannels(), (ch) => {
			let picked = lo.includes(pickedchannels, ch)
			return this.renderChannelTypeImg(ch, picked)
		})

		return <div class='d-flex flex-wrap'>{$channels}</div>
	},

	methods: {
		allChannels() {
			return lo.filter(
				['subiz', 'facebook', 'facebook_comment', 'zalo', 'email', 'instagram', 'instagram_comment', 'call', 'form', 'google_review', 'google_question'],
				(ch) => !lo.find(this.excludes, (ex) => ex === ch),
			)
		},

		renderChannelTypeImg(channel, picked) {
			let $x = (
				<Icon
					name='x'
					stroke-width='3'
					class='channel_picker__channel__remove_btn'
					vOn:click={(_) => this.removeChannel(channel)}
				/>
			)
			let $detail = null
			let cls = 'channel_picker__channel'
			if (!picked) {
				$x = null
				cls += ' channel_picker__channel__disabled'
			}

			if (channel === 'subiz') {
				$detail = (
					<div class={cls} v-tooltip={this.$t('website')}>
						{$x}
						<img
							vOn:click={(_) => this.addChannel('subiz')}
							src={require('../assets/img/subiz_channel.svg')}
							style='width: 30px;'
							class='mr-2'
						/>
					</div>
				)
			}

			if (channel === 'email') {
				$detail = (
					<div class={cls} v-tooltip={this.$t('Email')}>
						{$x}
						<img
							vOn:click={(_) => this.addChannel('email')}
							src={require('../assets/img/email_channel.svg')}
							style='width: 30px'
							class='mr-2'
						/>
					</div>
				)
			}

			if (channel === 'facebook_comment') {
				$detail = (
					<div class={cls} v-tooltip={this.$t('fb_comment')}>
						{$x}
						<img
							vOn:click={(_) => this.addChannel('facebook_comment')}
							src={require('../assets/img/facebook_channel.svg')}
							style='width: 30px'
							class='mr-2'
						/>
					</div>
				)
			}

			if (channel === 'facebook') {
				$detail = (
					<div class={cls} v-tooltip={this.$t('messenger')}>
						{$x}
						<img
							vOn:click={(_) => this.addChannel('facebook')}
							src={require('../assets/img/messenger_channel.svg')}
							style='width: 30px'
							class='mr-2'
						/>
					</div>
				)
			}

			if (channel === 'zalo') {
				$detail = (
					<div class={cls} v-tooltip={this.$t('zalo')}>
						{$x}
						<img
							vOn:click={(_) => this.addChannel('zalo')}
							src={require('../assets/img/zalo_channel.svg')}
							style='width: 30px'
							class='mr-2'
						/>
					</div>
				)
			}

			if (channel === 'instagram') {
				$detail = (
					<div class={cls} v-tooltip={this.$t('instagram')}>
						{$x}
						<img
							vOn:click={(_) => this.addChannel('instagram')}
							src={require('../assets/img/instagram.svg')}
							style='width: 30px'
							class='mr-2'
						/>
					</div>
				)
			}

			if (channel === 'instagram_comment') {
				$detail = (
					<div class={cls} v-tooltip={this.$t('instagram_comment')}>
						{$x}
						<img
							vOn:click={(_) => this.addChannel('instagram_comment')}
							src={require('../assets/img/instagram.svg')}
							style='width: 30px'
							class='mr-2'
						/>
					</div>
				)
			}

			if (channel === 'call') {
				$detail = (
					<div class={cls} v-tooltip={this.$t('call_center')}>
						{$x}
						<img
							vOn:click={(_) => this.addChannel('call')}
							src={require('../assets/img/phone_icon.svg')}
							style='width: 30px'
							class='mr-2'
						/>
					</div>
				)
			}
			if (channel === 'form') {
				$detail = (
					<div class={cls} v-tooltip={this.$t('Form')}>
						{$x}
						<img
							vOn:click={(_) => this.addChannel('form')}
							src={require('../assets/img/form.svg')}
							style='width: 30px'
							class='mr-2'
						/>
					</div>
				)
			}
			if (channel === 'google_review') {
				$detail = (
					<div class={cls} v-tooltip={this.$t('google_review')}>
						{$x}
						<img
							vOn:click={(_) => this.addChannel('google_review')}
							src={require('../assets/img/google_review_channel.svg')}
							style='width: 30px'
							class='mr-2'
						/>
					</div>
				)
			}
			if (channel === 'google_question') {
				$detail = (
					<div class={cls} v-tooltip={this.$t('google_question')}>
						{$x}
						<img
							vOn:click={(_) => this.addChannel('google_question')}
							src={require('../assets/img/google_question_channel.svg')}
							style='width: 30px'
							class='mr-2'
						/>
					</div>
				)
			}
			return $detail
		},

		addChannel(channel) {
			if (isAllChannel(this.picked_channels)) return
			let picked_channels = lo.filter(this.picked_channels, (c) => c !== channel)
			picked_channels.unshift(channel)
			this.$emit('change', picked_channels)
		},

		removeChannel(channel) {
			let picked_channels = this.picked_channels
			if (isAllChannel(this.picked_channels)) picked_channels = this.allChannels()
			picked_channels = lo.filter(picked_channels, (c) => c !== channel)
			if (lo.size(picked_channels) === 0) return
			this.$emit('change', picked_channels)
		},
	},
}

function isAllChannel(chans) {
	let all = lo.size(chans) === 0
	if (!all) all = lo.find(chans, (ch) => ch === '*' || ch === 'all')
	return !!all
}
