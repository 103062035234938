function getAgentDefaultAvatarUrl(agent) {
	let agentid = ''
	if (agent && agent.id) agentid = agent.id
	if (!agentid) return require('../assets/img/default_avatar.png')
	let idx = agentid.charCodeAt(agentid.length - 1)
	idx = idx % 10
	if (Number.isNaN(idx)) idx = 1
	return require(`../assets/img/avatars/avatar${idx}.svg`)
}

module.exports = {
	getAgentDefaultAvatarUrl,
}
