const ajax = require('@subiz/ajax/src/ajax.js')
const sb = require('@sb/util')
const flow = require('@subiz/flow')
var api = require('./api.js')
var config = require('@sb/config')
import KV from './kv.js'
import InMemKV from './inmem_kv.js'
import common from './common.js'
import NewStandardStore from './standard_store.js'
import uuid from 'react-native-uuid'

const WebRTCConn = require('@subiz/wsclient/webrtc.js')

function NewAccountStore(realtime, pubsub, parent) {
	let account_id = api.getAccountId()
	var dead = false
	let accKV = new KV(config.db_prefix + account_id + '.account')
	accKV.init()

	let {agent_id, access_token} = api.getCred()
	let memKV = new InMemKV()
	memKV.init()

	var me = {}

	me.destroy = () => {
		dead = true
		accKV.destroy()
	}

	let sbz_agents = {}
	me.setSubizAgent = (ags) => {
		lo.map(ags, (ag) => {
			sbz_agents[ag.id] = ag
		})
	}
	me.updatePromoteProduct = (val) => accKV.put('promote_product', val)
	me.matchPromoteProduct = () => accKV.match('promote_product')

	me.updateFromEmailCache = (email) => accKV.put('from_email_cache', email)
	me.getFromEmailCache = () => accKV.match('from_email_cache')

	me.updateAiAgentPreviewConvoId = (agid, {cid, uid}) => {
		let kv = accKV.match('ai_agent_preview_convo_id')
		if (!kv) {
			kv = {[agid]: {cid, uid}}
		} else {
			kv[agid] = {cid, uid}
		}
		accKV.put('ai_agent_preview_convo_id', kv)
	}
	me.removeAiAgentPreviewConvoId = (agid) => {
		let kv = accKV.match('ai_agent_preview_convo_id')
		if (kv[agid]) {
			delete kv[agid]
		}
		accKV.put('ai_agent_preview_convo_id', kv)
	}
	me.matchAiAgentPreviewConvoId = (agid) => {
		let kv = accKV.match('ai_agent_preview_convo_id')
		if (kv && typeof kv === 'object') {
			return kv[agid] || {}
		}
		return {}
	}

	me.updateOverdueTicketNotiClosed = () => accKV.put('overdue_ticket_noti_closed', Date.now())
	me.getOverdueTicketNotiClosed = () => accKV.match('overdue_ticket_noti_closed')

	me.updateSettingSetupAssitanceClosed = () => accKV.put('settings_setup_assistance_closed', Date.now())
	me.getSettingSetupAssitanceClosed = () => accKV.match('settings_setup_assistance_closed')

	me.matchSubizAgent = (id) => sbz_agents[id]
	me.searchUser = api.searchUser
	me.runBotForLeads = api.runBotForLeads

	me.loadScreen = (url, device) => api.load_screen(url, device)
	me.disablePushNotification = () => accKV.put('disable_push_notification', true)
	me.enablePushNotification = () => {
		pubsub.publish('enable_push_noti')
		accKV.put('disable_push_notification', false)
	}
	me.matchDisablePushNotification = () => accKV.match('disable_push_notification')

	me.setOpenOnlyFilter = (option) => accKV.put('convos_open_only', option)
	me.matchOpenOnlyFilter = () => accKV.match('convos_open_only')

	me.updateCloseNotiOptions = (option) => accKV.put('close_noti_option', option)
	me.matchCloseNotiOptions = () => accKV.match('close_noti_option')

	me.getBotTemplate = api.get_bot_template
	me.generateBotToken = api.get_bot_token
	me.getFileDetail = api.getFileDetail
	me.beginLoadRoute = () => {
		if (me.routeLoading) return
		me.routeLoading = true
		// only publish while still loading
		// this would reduce number of time showing loading when loading time is too little
		// which reducing flashes
		setTimeout(() => {
			if (me.routeLoading) pubsub.publish('route')
		}, 100)
	}

	me.endLoadRoute = () => {
		if (!me.routeLoading) return
		me.routeLoading = false
		pubsub.publish('route')
	}

	me.matchRouteLoading = () => me.routeLoading

	me.updateFcmToken = async (token, platform) => {
		let uniphoneid = ''
		// if (window.DeviceInfo) uniphoneid = await window.DeviceInfo.getUniqueId() // mobile
		return api.updateFcmToken(token, uniphoneid, platform)
	}

	me.deleteFcmToken = (token) => api.deleteFcmToken(token)
	me.lockLogin = (accid) => api.acc_lock_login(accid)
	me.updateUseTicket = (accid, v) => api.acc_update_use_ticket(accid, v)
	me.unlockLogin = (accid) => api.acc_unlock_login(accid)
	me.updateCredit = (credit) => api.acc_update_credit(credit)

	// ACCOUNT
	let accdb = new Entity(realtime, pubsub, accKV, 'account')
	me.fetchAccount = (f) => accdb.list(f)
	me.updateAccount = (acc) => accdb.update(acc)
	me.deleteAccount = (accid) => api.acc_delete_account(accid)

	me.get_hellobar = (force) => api.get_hellobar(force)
	me.set_hellobar = (hellobar) => api.set_hellobar(hellobar)

	// INVOICES
	let invdb = new Entity(realtime, pubsub, accKV, 'invoice', 'id')
	me.fetchInvoices = (f) => invdb.list(f)
	me.matchInvoice = (_) => invdb.match()
	me.updateInvoice = (inv) => invdb.update(inv)

	// PLAN
	let plandb = new Entity(realtime, pubsub, accKV, 'plan', 'name')
	me.fetchPlans = () => plandb.list()
	me.matchPlan = (_) => plandb.match()

	// EXCHANGE RATE
	let exchratedb = new Entity(realtime, pubsub, accKV, 'exchange_rate', 'id')
	me.fetchExchangeRate = () => exchratedb.list()
	me.matchExchangeRate = (_) => (exchratedb.match()['USD-VND'] || {}).exchange_rate || 21840

	me.fetchLanguage = (locale) => api.getLanguage(locale)
	me.updateLanguageMessage = (mes) => api.setLanguageMessage(mes)

	me.textToSpeech = (p) => api.text_to_speech(p)

	me.fetchDefLanguage = (locale) => api.getDefLanguage(locale)
	me.updateDefLanguageMessage = (mes) => api.setDefLanguageMessage(mes)

	me.matchCloseHelpWave = () => accKV.match('close_help_wave')
	me.closeHelpWave = (t) => accKV.put('close_help_wave', t)

	// CREDIT
	let creditdb = new Entity(realtime, pubsub, accKV, 'credit', 'id')
	me.fetchCredit = () => creditdb.list()
	me.matchCredit = (_) => creditdb.match()
	me.reportCredit = api.report_credit

	// facebook post
	let fbpostdb = NewStandardStore(realtime, pubsub, 'facebook-post')
	me.fetchFacebookPosts = (params) => fbpostdb.list(params)
	me.matchFacebookPost = fbpostdb.match
	me.fetchFacebookPostList = fbpostdb.list
	me.fetchFacebookPostIds = async (ids) => {
		let res = await await fbpostdb.fetch(ids, true)
		if (res.error) return res

		return {
			items: lo.map(ids, (id) => fbpostdb.match(id) || {id}),
		}
	}

	// AI-DATA-ENTRY
	let aidataentrydb = NewStandardStore(realtime, pubsub, 'ai-data-entry')
	me.onAiDataEntry = (o, cb) => pubsub.on2(o, 'ai-data-entry', cb)
	me.fetchAiDataEntries = (params) => aidataentrydb.list(params)
	me.fetchEntryIds = (ids, force) => aidataentrydb.fetch(ids, force)
	me.createAiDataEntry = (p) => aidataentrydb.create(p)
	me.updateAiDataEntry = (p) => aidataentrydb.update(p)
	me.removeAiDataEntry = (id) => aidataentrydb.delete(id)
	me.matchAiDataEntry = (id) => aidataentrydb.match(id)
	me.fetchAiDataEntryList = aidataentrydb.list
	me.fetchAiDataEntryIds = async (ids) => {
		let res = await await aidataentrydb.fetch(ids, true)
		if (res.error) return res

		return {
			items: lo.map(ids, (id) => aidataentrydb.match(id) || {id}),
		}
	}

	let crawl_promises_map = {}
	let crawl_body_map = {}
	let crawl_cancelled_map = {}

	const _fetchCrawlLink = ({run_id, done_cb, url}, streaming_cb) => {
		let runId = run_id
		let doneCb = done_cb
		return new Promise(async (resolve) => {
			crawl_promises_map[runId] = resolve

			let res = await api.list_crawl({url})
			if (res.error) {
				if (doneCb && typeof doneCb === 'function') doneCb()
				resolve(res)
			}

			let extralinks = lo.get(res, 'body.links') || []
			let firstLink = {id: url, num_characters: lo.get(res, 'body.num_characters'), title: lo.get(res, 'body.title')}
			let body = {[url]: firstLink}
			lo.map(extralinks, (url) => {
				body[url] = {id: url}
			})
			crawl_body_map[runId] = body
			let current = 1
			if (streaming_cb && typeof streaming_cb === 'function' && !crawl_cancelled_map[runId]) {
				streaming_cb(
					lo.map(body, (row) => row),
					lo.size(body),
					{current: 0, total: 100}, //display percentage 0% because we didnt know actual total links yet
				)
			}

			for (let i = 0; i < lo.size(extralinks); i++) {
				let link = extralinks[i]
				await sb.sleep(100)
				let extraRes = await api.list_crawl({url: link})

				let extraLinks2 = lo.get(extraRes, 'body.links') || []
				let remainLinks = []
				lo.set(body, [link, 'num_characters'], lo.get(extraRes, 'body.num_characters'))
				lo.set(body, [link, 'title'], lo.get(extraRes, 'body.title'))
				lo.each(extraLinks2, (url) => {
					if (!body[url]) {
						body[url] = {id: url}
						remainLinks.push(url)
					}
				})

				current += 1
				crawl_body_map[runId] = body
				await flow.map(
					remainLinks,
					async (url) => {
						let res = await api.list_crawl({url})
						lo.set(body, [url, 'num_characters'], lo.get(res, 'body.num_characters'))
						lo.set(body, [url, 'title'], lo.get(res, 'body.title'))
						current += 1
						crawl_body_map[runId] = body
						if (streaming_cb && typeof streaming_cb === 'function' && !crawl_cancelled_map[runId]) {
							streaming_cb(
								lo.map(body, (row) => row),
								lo.size(body),
								{current, total: lo.size(body)},
							)
						}
					},
					5,
				)
			}

			if (doneCb && typeof doneCb === 'function') doneCb()
			resolve({
				items: lo.map(body, (row) => row),
				total: lo.size(body),
			})
		})
	}

	me.fetchCrawlList = async ({run_id, done_cb, url}, streaming_cb) => {
		let res = await _fetchCrawlLink({run_id, done_cb, url}, streaming_cb)
		return res
	}

	me.removeCrawlUrls = (ids, runId) => {
		lo.each(ids, (id) => {
			if (runId && crawl_body_map[runId]) {
				let currentLinks = crawl_body_map[runId]
				currentLinks = lo.filter(currentLinks, (link) => link !== id)
			}
			pubsub.publish('standard_object', {
				object_type: 'crawl',
				action: 'delete',
				data: {id},
			})
		})
	}

	me.cancelFetchCrawlLink = ({run_id, done_cb, url}) => {
		let runId = run_id
		let doneCb = done_cb
		let currentResolve = crawl_promises_map[runId]
		crawl_cancelled_map[runId] = true
		if (currentResolve && typeof currentResolve === 'function') {
			let body = crawl_body_map[runId]
			if (doneCb && typeof doneCb === 'function') doneCb()
			currentResolve({
				items: lo.map(body, (row) => row),
				total: lo.size(body),
			})
		}
	}

	// ai entry file before submit
	let ai_entry_prepare_files_map = {}
	let ai_entry_file_status = {} // { [runId]: 'pending' || 'done' }
	let ai_entry_result = {}

	me.fetchAiEntryFileList = async ({run_id}) => {
		let runId = run_id
		let state = ai_entry_file_status[runId]
		if (state === 'done') {
			let files = ai_entry_result[runId] || []
			return {
				total: lo.size(files),
				items: files,
			}
		}

		let currentFiles = ai_entry_result[runId] || []
		let prepareFiles = ai_entry_prepare_files_map[runId] || []
		for (let i = 0; i < prepareFiles.length; i++) {
			let file = prepareFiles[i]
			let res = await common.uploadLocalFile(file, {gentext: true})
			if (res.error) continue
			currentFiles = [...currentFiles, res]
			ai_entry_result[runId] = currentFiles
		}

		return {
			total: lo.size(currentFiles),
			items: currentFiles,
		}
	}

	me.addAiEntryFiles = async (runId, files) => {
		ai_entry_prepare_files_map[runId] = files
		ai_entry_file_status[runId] = 'pending'
	}

	me.fetchAiEntryChunkList = async (p) => {
		let res = await api.list_ai_entry_chunks(p.ai_entry_id)
		if (res.error) return res
		let chunks = lo.get(res, 'body.ai_data_chunks') || []
		let anchor = lo.get(res, 'body.anchor')
		let total = lo.get(res, 'body.total')

		return {
			items: chunks,
			total,
			anchor,
		}
	}
	me.retrainAiEntry = api.retrain_ai_entry

	// SUBSCRIPTION
	let sudb = new Entity(realtime, pubsub, accKV, 'subscription', 'account_id')
	me.fetchSubscription = (f) => sudb.list(f)
	me.matchSubscription = (_) => sudb.match()[api.getAccountId()]
	me.updateSubscription = (sub) => sudb.update(sub)

	// CONVO SETTING
	let cvsdb = new Entity(realtime, pubsub, accKV, 'convo_setting', 'account_id')
	me.fetchConvoSetting = () => cvsdb.list()
	me.matchConvoSetting = (_) => cvsdb.match()[api.getAccountId()]
	me.updateConvoSetting = (setting) => cvsdb.update(setting)

	// FB FANPAGE SETTING
	let fbdb = new Entity(realtime, pubsub, accKV, 'facebook_setting', 'fanpage_id')
	me.fetchFacebookSetting = () => fbdb.list()
	me.matchFacebookSetting = () => fbdb.match()
	me.updateFacebookSetting = (setting) => fbdb.update(setting)

	// GOOGLE SETTING
	let ggdb = new Entity(realtime, pubsub, accKV, 'google_setting', 'business_location_id')
	me.fetchGoogleSetting = () => ggdb.list()
	me.matchGoogleSetting = () => ggdb.match()
	me.updateGoogleSetting = (setting) => ggdb.update(setting)

	// DOMAIN
	let sitedb = new Entity(realtime, pubsub, accKV, 'site')
	realtime.subscribe([
		'bot_debug_end',
		'bot_debug_begin_action',
		'agent_presence_updated',
		'agent_updated',
		'subiz_bill_updated',
		`ticket_view_count_updated.account.${account_id}.agent.${agent_id}`,
		`login_session_updated.account.${account_id}.agent.${agent_id}`,
	]) // ignore result

	let cacheip = {}
	let geoipLock = {}

	// use to avoid batches request when render 30 users have same ip at the same time
	const geoip = async (ip, uid) => {
		if (!ip) return {}
		if (geoipLock[ip]) {
			await sb.sleep(100)
			return await geoip(ip, uid)
		}

		geoipLock[ip] = true

		if (cacheip[ip]) {
			geoipLock[ip] = false
			return cacheip[ip]
		}
		let {body, error} = await api.geoip(ip)
		geoipLock[ip] = false
		if (error) return {error}
		cacheip[ip] = body
		return body
	}
	me.geoip = geoip

	me.match_geoip = (ip) => cacheip[ip]

	me.fetchSites = (force) => sitedb.list(force)
	me.matchSite = (_) => sitedb.match()
	me.addSite = (domain) => sitedb.create(domain)
	me.removeSite = (domain) => sitedb.remove(domain)

	// POS
	let posdb = new Entity(realtime, pubsub, accKV, 'pos')
	me.fetchPOSes = (force) => posdb.list(force)
	me.matchPOS = (id) => posdb.match(id)
	me.createPOS = (pos) => posdb.create(pos)
	me.deletePOS = (id) => posdb.remove(id)
	me.updatePOS = (pos) => posdb.update(pos)

	me.updateShippingPolicy = api.update_shipping_policy
	me.createShippingPolicy = api.create_shipping_policy
	me.deleteShippingPolicy = api.delete_shipping_policy

	me.listProvinces = api.list_provices
	me.listDistricts = api.list_districts
	me.listWards = api.list_wards

	me.getSuggestedAddresses = api.suggest_address
	me.getSuggestedAddressDetail = api.get_address_detail

	me.listIntegratedShipping = () => api.list_integrated_shipping()
	me.createIntegratedShipping = api.create_integrated_shipping
	me.updateIntegratedShipping = api.update_integrated_shipping
	me.deleteIntegratedShipping = api.delete_integrated_shipping

	me.checkNumberConnection = api.check_number_connection
	me.sendGhnOTP = api.send_ghn_otp
	me.enterGhnOTP = api.enter_ghn_otp

	me.getShippingFee = api.get_shipping_fee
	me.sendOrderToShippingProvider = api.send_order_to_shipping_provider
	me.cancelShippingOrder = api.cancel_shipping_order
	me.printShippingOrders = api.print_shipping_orders

	// TAX
	let taxdb = new Entity(realtime, pubsub, accKV, 'tax')
	me.fetchTaxes = (force) => taxdb.list(force)
	me.matchTax = (id) => taxdb.match(id)
	me.createTax = (tax) => taxdb.create(tax)
	me.deleteTax = (id) => taxdb.remove(id)
	me.updateTax = (tax) => taxdb.update(tax)

	// CANCELLATION REASONS
	let ccdb = new Entity(realtime, pubsub, accKV, 'cancellation_code', 'code')
	me.fetchCancellationReasons = (force) => ccdb.list(force)
	me.matchCancellationReason = (code) => ccdb.match(code)
	me.createCancellationReason = (cc) => ccdb.create(cc)
	me.updateCancellationReason = (cc) => ccdb.update(cc)

	let spmdb = new Entity(realtime, pubsub, accKV, 'subiz_payment_method')
	me.fetchSubizPaymentMethod = (f) => spmdb.list(f)
	me.matchSubizPaymentMethod = () => spmdb.match()
	me.updateSubizPaymentMethod = (obj) => spmdb.update(obj)
	me.createSubizPaymentMethod = (obj) => spmdb.create(obj)
	me.deleteSubizPaymentMethod = (id) => spmdb.remove(id)
	me.makeDefaultSubizPaymentMethod = api.make_default_subiz_payment_method
	me.getStripeCheckoutSession = api.get_stripe_checkout_session
	me.createStripeCheckoutSession = api.create_stripe_checkout_sessions

	// PAYMENT METHOD
	let paymentmethoddb = new Entity(realtime, pubsub, accKV, 'payment_method')
	me.fetchPaymentMethods = (force) => paymentmethoddb.list(force)
	me.matchPaymentMethod = (id) => paymentmethoddb.match(id)
	me.createPaymentMethod = (pm) => paymentmethoddb.create(pm)
	me.deletePaymentMethod = (id) => paymentmethoddb.remove(id)
	me.updatePaymentMethod = (pm) => paymentmethoddb.update(pm)
	me.makeDefaultPaymentMethod = (id) => api.make_default_payment_method(id)

	let pipelinedb = new Entity(realtime, pubsub, accKV, 'pipeline')
	me.fetchPipelines = (force) => pipelinedb.list(force)
	me.matchPipeline = (id) => pipelinedb.match(id)
	me.createPipeline = (pm) => pipelinedb.create(pm)
	me.deletePipeline = (id) => pipelinedb.remove(id)
	me.updatePipeline = (pm) => pipelinedb.update(pm)
	me.makeDefaultPipeline = api.make_default_pipeline
	me.deletePipelineStage = api.delete_pipeline_stage
	me.makePipelinePreselect = api.make_pipeline_preselect

	// Lead view
	let leadviewdb = new Entity(realtime, pubsub, accKV, 'user_view')
	me.fetchUserView = (force) => leadviewdb.list(force)
	me.matchUserView = (id) => leadviewdb.match(id)
	me.addUserView = (lv) => leadviewdb.create(lv)
	me.removeUserView = (lvid) => leadviewdb.remove(lvid)
	me.updateUserView = (lv) => leadviewdb.update(lv)

	// Ticket view
	let ticketviewdb = new Entity(realtime, pubsub, accKV, 'ticket_view', 'id', 'ticket_view')
	me.onTicketView = (o, cb) => pubsub.on2(o, 'ticket_view', cb)
	me.onTicketViewCount = (o, cb) => pubsub.on2(o, 'ticket_view_count', cb)
	me.fetchTicketViews = (force) => ticketviewdb.list(force)
	me.matchTicketView = () => ticketviewdb.match()
	me.addTicketView = (lv) => ticketviewdb.create(lv)
	me.removeTicketView = (lvid) => ticketviewdb.remove(lvid)
	me.updateTicketView = (lv) => ticketviewdb.update(lv)

	// promocode
	me.fetchPromotionPrograms = (keyword) => api.acc_list_programs(keyword)
	me.fetchProgramCodes = (programid) => api.acc_list_program_codes(programid)
	me.fetchRedeemCodes = (programid) => api.acc_list_redeem_codes(programid)
	me.fetchAgentCodes = (agid) => api.list_agent_promocodes(agid)
	me.updatePromotionCode = (programid, code) => api.acc_update_promocode(programid, code)
	me.deletePromotionCode = (programid, code) => api.acc_delete_promocode(programid, code)
	me.checkPromotionCode = (code, inv) => api.check_promocode(code, inv)
	me.fetchPromotionCode = (code) => api.acc_get_promocode(code)
	me.applyPromotionCode = (code) => api.acc_apply_promocode(code)
	me.fetchProgramInvoices = (id) => api.acc_list_program_invoices(id)

	// Ticket type
	let tickettypedb = new Entity(realtime, pubsub, accKV, 'ticket_type')
	me.fetchTicketTypes = (force) => tickettypedb.list(force)
	me.matchTicketType = () => tickettypedb.match()
	me.addTicketType = (lv) => tickettypedb.create(lv)
	me.updateTicketType = (lv) => tickettypedb.update(lv)
	me.removeTicketType = (id) => tickettypedb.remove(id)

	// Ticket Template
	let tickettemplatedb = new Entity(realtime, pubsub, accKV, 'ticket_template')
	me.fetchTicketTemplates = (force) => tickettemplatedb.list(force)
	me.matchTicketTemplate = () => tickettemplatedb.match()
	me.addTicketTemplate = (lv) => tickettemplatedb.create(lv)
	me.updateTicketTemplate = (lv) => tickettemplatedb.update(lv)
	me.deleteTicketTemplate = (id) => tickettemplatedb.remove(id)

	// SLA
	let sladb = new Entity(realtime, pubsub, accKV, 'sla_policy')
	me.fetchSLAs = (force) => sladb.list(force)
	me.matchSLA = () => sladb.match()
	me.addSLA = (sla) => sladb.create(sla)
	me.updateSLA = (sla) => sladb.update(sla)
	me.deleteSLA = (id) => sladb.remove(id)

	// PHONE DEVICES
	let phonedb = new Entity(realtime, pubsub, accKV, 'phone_device')
	me.fetchPhoneDevice = (force) => phonedb.list(force)
	me.getPhoneDeviceDetail = api.get_phone_device_detail
	me.matchPhoneDevice = (id) => phonedb.match(id)
	me.createPhoneDevice = (p) => phonedb.create(p)
	me.updatePhoneDevice = (p) => phonedb.update(p)
	me.deletePhoneDevice = (id) => phonedb.remove(id)

	// GREETING AUDIO
	let audiodb = new Entity(realtime, pubsub, accKV, 'greeting_audio')
	me.fetchGreetingAudio = (force) => audiodb.list(force)
	me.matchGreetingAudio = (id) => audiodb.match(id)
	me.createGreetingAudio = (p) => audiodb.create(p)
	me.updateGreetingAudio = (p) => audiodb.update(p)
	me.deleteGreetingAudio = (id) => audiodb.remove(id)
	me.fetchGreetingAudioList = async ({selected_id}) => {
		let res = await audiodb.list()
		if (res.error) return res

		let audios = lo.filter(audiodb.match(), (audio) => lo.get(audio, 'file.url'))
		let mains = []
		let remains = []
		lo.each(audios, (audio) => {
			if (audio.id === selected_id) mains.push(audio)
			else remains.push(audio)
		})
		remains = lo.orderBy(remains, ['created'], ['desc'])
		let items = [...mains, ...remains]
		return {
			items,
			total: lo.size(items),
		}
	}

	// BANK
	let bankdb = new Entity(realtime, pubsub, accKV, 'bank_account')
	me.fetchBankAccount = (force) => bankdb.list(force)
	me.matchBankAccount = (id) => bankdb.match(id)
	me.createBankAccount = (p) => bankdb.create(p)
	me.updateBankAccount = (p) => bankdb.update(p)
	me.deleteBankAccount = (id) => bankdb.remove(id)

	let segmentdb = new Entity(realtime, pubsub, memKV, 'segment')
	me.fetchSegments = (force) => segmentdb.list(force)
	me.matchSegment = (id) => segmentdb.match(id)
	me.createSegment = (p) => segmentdb.create(p)
	me.updateSegment = (p) => segmentdb.update(p)
	me.deleteSegment = (id) => segmentdb.remove(id)
	me.addUserToSegment = api.add_user_to_segment
	me.removeUsersFromSegment = api.remove_user_from_segment

	let businessEmailAddressDb = new Entity(realtime, pubsub, accKV, 'business_email_address', 'address')
	me.fetchBusinessEmailAddresses = (force) => businessEmailAddressDb.list(force)
	me.matchBusinessEmailAddress = (id) => businessEmailAddressDb.match(id)
	me.createBusinessEmailAddress = (p) => businessEmailAddressDb.create(p)
	me.updateBusinessEmailAddress = (p, fields) => businessEmailAddressDb.update(p, fields)
	me.deleteBusinessEmailAddress = (id) => businessEmailAddressDb.remove(id)

	let campaigndb = new Entity(realtime, pubsub, accKV, 'campaign')
	me.fetchCampaign = (force) => campaigndb.list(force)
	me.matchCampaign = (id) => campaigndb.match(id)
	me.createCampaign = async (p) => {
		let {campaign, error} = await replaceCampaignBase64Images(p)
		if (error) return {error}
		p = campaign
		return campaigndb.create(p)
	}
	me.updateCampaign = async (p) => {
		let {campaign, error} = await replaceCampaignBase64Images(p)
		if (error) return {error}
		p = campaign
		return campaigndb.update(p)
	}
	me.deleteCampaign = (id) => campaigndb.remove(id)
	me.getCampaignReport = api.get_campaign_report
	me.listCampaignSendMessages = api.get_campaign_send_messages
	me.fetchCampaignList = async ({keyword, type}) => {
		type = type || 'broadcast'
		let res = await campaigndb.list()
		if (res.error) return res

		let items = lo.filter(store.matchCampaign(), (campaign) => {
			if (campaign.type !== type) return false
			if (campaign.state === 'draft') return false
			if (type === 'telesale') {
				if (!_canViewTelesale(campaign)) return false
			}
			if (!lo.trim(keyword)) return true

			keyword = sb.unicodeToAscii(keyword).toLowerCase()
			let name = sb.unicodeToAscii(campaign.name || '').toLowerCase()
			return name.indexOf(keyword) > -1
		})
		items = lo.orderBy(items, ['created'], ['desc'])
		return {
			items,
			total: lo.size(items),
		}
	}

	function _canViewTelesale(campaign) {
		let members = lo.get(campaign, 'outbound_call.members', [])
		let managers = lo.get(campaign, 'outbound_call.managers', [])

		if (members.includes(me.me().id)) return true
		if (managers.includes(me.me().id)) return true
		if (lo.get(campaign, 'created') === me.me().id) return true

		return false
	}

	async function replaceCampaignBase64Images(campaign) {
		campaign = lo.cloneDeep(campaign)
		let channelMessages = campaign.messages || []

		for (let i = 0; i < channelMessages.length; i++) {
			let messages = lo.get(channelMessages, [i, 'messages'], [])
			for (let j = 0; j < messages.length; j++) {
				let message = messages[j]

				let {message: newMessage, error} = await common.replaceMessageBase64Images(message)
				if (error) {
					break
					return {error}
				}
				lo.set(campaign, ['messages', i, 'messages', j], newMessage)
			}
		}

		return {campaign}
	}

	function getAllCampaignBlobPaths(campaign) {
		let output = []
		lo.each(campaign.messages, (message, idx) => {
			let blobs = getAllMessageBlobPaths(message)
			blobs = lo.map(blobs, (blob) => {
				return {
					...blob,
					path: `messages.${idx}.${blob.path}`,
				}
			})
			output = [...output, ...blobs]
		})

		return output
	}

	let callsettingsdb = new Entity(realtime, pubsub, accKV, 'call_setting', 'number')
	me.fetchCallSettings = (force) => callsettingsdb.list(force)
	me.matchCallSetings = (id) => callsettingsdb.match(id)
	me.updateCallSettings = (p) => callsettingsdb.update(p)

	let znstemplatedb = new Entity(realtime, pubsub, accKV, 'zns_template', 'templateId')
	me.fetchZnsTemplate = (force) => znstemplatedb.list(force)
	me.matchZnsTemplate = (id) => znstemplatedb.match(id)

	// AGENT
	let agentdb = new Entity(realtime, pubsub, memKV, 'agent')
	me.fetchAgents = (f) => agentdb.list(f, true)
	me.fetchAgent = (id) => api.get_agent(id)
	me.updateAgentProfile = (ag) => api.update_agent_profile(ag)
	me.matchAgent = (id) => {
		if (id == 'system' || id == 'subiz')
			return {
				id: id,
				fullname: 'Hệ thống',
			}

		if (!id) return agentdb.match()
		let ag = agentdb.match()[id]
		if (!ag) ag = {id: id, fullname: 'Không xác định'}
		return ag
	}

	me.fetchAgentList = async ({type}) => {
		let res = await agentdb.list()
		if (res.error) return res

		let agents = lo.filter(agentdb.match(), (agent) => {
			return agent.type === type
		})
		agents = lo.orderBy(agents, ['state', (agent) => sb.unicodeToAscii(agent.fullname).toLowerCase()], ['asc', 'asc'])
		return {
			items: agents,
			total: lo.size(agents),
		}
	}

	me.updateAgent = async (ag, fields) => {
		const out = await agentdb.update(ag, fields)

		if (!out.error) me.updateNotiSound()

		return out
	}

	me.removeAgent = (id) => agentdb.remove(id)
	me.inviteAgent = (ag) => agentdb.create(ag)

	// NOTIFICATION
	me._noti_gate = new sb.Gate()
	realtime.onInterrupted(() => {
		me._noti_sync = false
		me.syncNoti()
	})
	me.syncNoti = async () => {
		me._noti_gate.close()
		// reset all noti category
		memKV.put('noti_order', {})
		memKV.put('noti_user', {})
		let topics = [
			`notification_created.account.${account_id}.agent.${agent_id}`,
			`notification_seen.account.${account_id}.agent.${agent_id}`,
		]
		if (process.env.ENV === 'desktop') {
			topics.push(`desktop_notification_pushed.account.${account_id}.agent.${agent_id}`)
		}
		await realtime.subscribe(topics)
		me.fetchNotis('order')
		me.fetchNotis('user')
		me._noti_sync = true
		me._noti_gate.open()
	}

	me.syncNoti()
	realtime.onEvent((ev) => {
		if (!me._noti_sync) return
		if (ev.type == 'ticket_view_count_updated') {
			let tvid = lo.get(ev, 'data.ticket_view.id')
			if (!tvid) return
			let oldtv = ticketviewdb.match()[tvid]
			if (oldtv) {
				oldtv.count = lo.get(ev, 'data.ticket_view.count', 0)
				oldtv.members = lo.get(ev, 'data.ticket_view.members')
				oldtv.last_event = lo.get(ev, 'data.ticket_view.last_event')
				oldtv.last_modified = lo.get(ev, 'data.ticket_view.last_modified', 0)
				ticketviewdb.justUpdate(oldtv)
			}
			let data = lo.get(ev, 'data.ticket_view') || {}
			pubsub.publish('ticket_view_count', data)
			return
		}

		if (ev.type === 'desktop_notification_pushed') {
			// this.code is copy paste in push_notification_sw.js line 119
			//var eventData = ev.data
			const data = lo.get(ev, 'data.desktop_notification') || {}
			//if (data.account_id !== account_id || data.recipient_id !== agent_id) return true
			let noti = data
			let title = noti.title || 'Subiz'
			let body = noti.body || 'Bạn có cập nhật mới'
			//eventData.notification && eventData.notification.body
			//? eventData.notification.body
			//: ''
			var icon = noti.icon_url || ''
			let tag = data.account_id || '1'
			let requireInteraction = false
			if (data.type === 'message_sent') {
				tag = 'message_sent'
				requireInteraction = true
				title = `${title} gửi tin nhắn`
			} else if (data.type === 'conversation_invited') {
				tag = 'message_sent'
				requireInteraction = true
			} else if (data.type === 'user_campaign_converted') {
				tag = 'user_campaign_converted'
			} else if (data.type === 'user_returned' || data.type === 'user_first_visited') {
				tag = 'user_traffic'
			} else if (data.type === 'message_pong') {
				tag = 'message_pong'
			} else if (data.type === 'task_assigned') {
				tag = 'task_assigned'
				requireInteraction = true
			} else if (data.type === 'task_mentioned') {
				tag = 'task_mentioned'
				requireInteraction = true
			} else if (data.type === 'task_reminded') {
				tag = 'task_reminded'
				requireInteraction = true
			} else if (data.type === 'task_expired') {
				tag = 'task_expired'
				requireInteraction = true
			} else if (data.type == 'incoming_call') {
				tag = data.call_id
				title = 'Cuộc gọi tới: ' + data.caller_name
				body = data.caller_number
				icon = data.caller_avatar_url
				requireInteraction = true
			} else if (data.type == 'incoming_call_expired') {
				icon = data.caller_avatar_url
				title = 'Cuộc gọi kết thúc'
				body = data.caller_number
				requireInteraction = false
			}

			if (!icon) icon = 'https://vcdn.subiz-cdn.com/file/firntezftsyopuqcgmej-Group_6168_1.png'
			let notification = {
				title,
				tag: tag,
				body: body,
				icon: icon,
				data: data,
				requireInteraction: requireInteraction,
			}
			console.log('notification::::', ev, notification)
			if (window.electronAPI && window.electronAPI.showNoti && typeof window.electronAPI.showNoti === 'function') {
				window.electronAPI.showNoti(notification)
			}
		}

		if (ev.type === 'subiz_bill_updated') {
			console.log('subiz_bill_updated', ev)
			let bill = lo.get(ev, 'data.subiz_bill')
			if (!bill) return
			pubsub.publish('bill', bill)
			return
		}

		if (ev.type === 'notification_seen') {
			let category = lo.get(ev, 'data.notification.category')
			if (!category) return
			let noti = memKV.match('noti_' + category) || {}
			noti.unread = 0
			noti.last_seen = ev.created
			memKV.put('noti_' + category, noti)
			pubsub.publish('noti', category)
			return
		}

		if (ev.type === 'notification_created') {
			let notification = lo.get(ev, 'data.notification') || {}
			let category = notification.category || ''
			if (!category) return
			if (!notification.is_instant) {
				let noti = memKV.match('noti_' + category) || {}
				let oldlen = lo.size(noti.notifications)
				noti.notifications = me.joinNotis(noti.notifications, [notification])
				memKV.put('noti_' + category, noti)
				noti.unread = noti.unread || 0

				// estimated, not alway true
				if (lo.size(noti.notifications) > oldlen) noti.unread++
				pubsub.publish('noti', category)
			}
			let setting = lo.get(me.matchSettingNotify(), 'setting', {})
			if (sb.now() >= sb.getMs(setting.instant_mute_until)) {
				pubsub.publish('instant_noti', notification)
			}
		}
	})
	me.onNoti = (o, cb) => pubsub.on2(o, 'noti', cb)
	me.onBill = (o, cb) => pubsub.on2(o, 'bill', cb)
	me.matchNotis = (category) => memKV.match('noti_' + category)
	me.fetchNotis = async (category) => {
		if (!api.getAccountId()) return
		if (!category) return
		await me._noti_gate.entry()
		let out = await api.listNotis(category, '')
		if (out.error) return {error: out.error}

		let body = out.body || {}
		let noti = memKV.match('noti_' + category) || {}
		if (!noti.next_anchor) noti.next_anchor = body.next_anchor

		noti.last_seen = body.last_seen
		noti.unread = body.unread
		noti.severity = body.severity
		noti.notifications = me.joinNotis(noti.notifications, out.body.notifications)
		memKV.put('noti_' + category, noti)
		pubsub.publish('noti', category)
		return out
	}
	me.fetchMoreNotis = async (category) => {
		if (!category) return
		await me._noti_gate.entry()

		let noti = memKV.match('noti_' + category) || {}
		let next_anchor = noti.next_anchor
		let out = await api.listNotis(category, next_anchor)
		if (out.error) return {error: out.error}

		// outdated
		noti = memKV.match('noti_' + category) || {}
		if (next_anchor != noti.next_anchor) return true

		let newnotis = lo.get(out.body, 'notifications') || []
		noti.next_anchor = lo.get(out.body, 'next_anchor', noti.next_anchor)
		noti.notifications = me.joinNotis(noti.notifications, newnotis)
		memKV.put('noti_' + category, noti)
		pubsub.publish('noti', category)
		return lo.size(out.body.notifications) > 0
	}

	let scopeM = {}
	me.buildScope = () => {
		let m = {}
		m['agent'] =
			'account:read conversation:read:own conversation:create conversation:update:own agent_group:read rule:read integration:read message_template:read message_template:update:own mesasge_template:delete:own message_template:create tag:read user:delete:own knowledge_base:read article:read' +
			' whitelist:read whitelist:update whitelist:delete whitelist:create subscription:read user:read:own user:update:own user:create attribute:read bot:read agent:read conversation_setting:read web_plugin:read file:read file:create file:update lang:read user_label:read user_view:update:own user_view:create user_view:read user_view:update:own' +
			' order:create order:read:unassigned order:read order:update:own shop_setting:read conversation_modal:read conversation_automation:read phone_device:read:own phone_device:update:own call_setting:read greeting_audio:read' +
			' ticket:invite:own ticket:update:own ticket:read:own product:create product:update:own product:read ticket_type:read segment:read segment:update:own segment:create ticket_template:create ticket_template:read ticket_template:update:own sla_policy:read live:read'
		m['view_other_convos'] = 'conversation:read'
		m['view_others'] = 'live:read conversation:read user:read order:read ticket:read'
		m['supervisor'] = 'live:read conversation:read user:read order:read ticket:read report:read'
		m['export_user'] = 'user:export' // export

		m['account_setting'] =
			m['agent'] +
			' ' +
			m['view_other_convos'] +
			' report:read ticket:read user:read user:delete live:read auto_segment:create account:update agent:update agent:delete agent:create agent_group:delete agent_group:update agent_group:create rule:delete rule:update rule:create integration:delete integration:update integration:create message_template:delete message_template:update tag:delete tag:update tag:create attribute:update attribute:create bot:update bot:delete bot:create conversation_setting:update web_plugin:update web_plugin:create web_plugin:delete webhook:read webhook:update webhook:create webhook:delete lang:create lang:update user_label:create user_label:update user_label:delete shop_setting:update shop_setting:create conversation_modal:update conversation_modal:create conversation_automation:update conversation_automation:create phone_device:read phone_device:create phone_device:update phone_device:delete call_setting:update greeting_audio:create greeting_audio:update greeting_audio:delete ticket:update order:read order:update product:update product:delete order:delete ticket_type:create ticket_type:update ticket:delete user_view:update segment:update ticket_template:update ticket:invite user:update file:delete sla_policy:update sla_policy:delete sla_policy:create knowledge_base:update knowledge_base:create knowledge_base:delete article:create article:delete article:update order:import order:export user:export segment:delete report:read'

		m['account_manage'] =
			m['account_setting'] +
			' account:update agent_group:update agent:update subscription:update payment_method:read payment_method:update'
		m['owner'] = m['account_manage'] + ' ' + m['account_setting']
		m['subiz'] = m['account_manage'] + ' ' + m['account_setting'] + ' accmgr:update'
		m['all'] = m['subiz']

		// secondary scopes (use for resource type only)
		m['ticket_type_member'] = 'ticket:create ticket:read ticket:update ticket:invite'
		m['ticket_type_manager'] = m['ticket_type_member'] + ' ticket_type:update'

		m['segment_member'] = 'user:read user:update user:invite'
		m['segment_manager'] = m['segment_member'] + ' segment:update'

		lo.map(m, (scopestr, k) => {
			scopeM[k] = {}
			let scopes = scopestr.split(' ')
			lo.map(scopes, (scope) => {
				if (m[scope]) {
					// secondary
					Object.assign(scopeM[k], scopeM[scope])
					return
				}
				scopeM[scope] = {[scope]: true}
				scopeM[k][scope] = true
			})
		})
	}
	me.buildScope()
	me.checkPerm = (perm) => {
		let scopes = store.me().scopes || []
		const primitiveScope = me.getPrimitiveScope(scopes)
		return lo.includes(Object.keys(primitiveScope), perm)
	}
	const SECONDARY_SCOPES = {
		agent: true,
		account_setting: true,
		account_manage: true,
		owner: true,
		export_user: true,
		subiz: true,
		crm: true,
		all: true,
		view_others: true,
		view_other_convos: true,
		supervisor: true,
	}

	let primitiveScopeObj = {}
	// nhan vao "agent_setting,order:read:own"
	me.getPrimitiveScope = (scopes) => {
		const string_scope = scopes.join(',')
		if (primitiveScopeObj[string_scope]) return primitiveScopeObj[string_scope]

		let out = {}
		const secondaryScope = []
		const primitiveScope = []

		lo.each(scopes, (scope) => {
			if (SECONDARY_SCOPES[scope]) {
				if (scope === 'agent') secondaryScope.unshift(scope)
				else secondaryScope.push(scope)
			} else primitiveScope.push(scope)
		})

		lo.each(secondaryScope, (scope) => {
			lo.map(scopeM[scope], (_, k) => {
				let parts = k.split(':')
				let prefix = parts[0] + ':' + parts[1]

				lo.each(out, (_, key) => {
					if (key.startsWith(prefix) && key != `${prefix}:unassigned`) delete out[key]
				})

				out[k] = true
			})
		})

		lo.each(primitiveScope, (scope) => {
			let parts = scope.split(':')
			let prefix = parts[0] + ':' + parts[1]

			lo.map(out, (_, key) => {
				if (key.startsWith(prefix) && key != `${prefix}:unassigned` && !scope.endsWith('unassigned')) delete out[key]
				return
			})
			out[scope] = true
		})
		primitiveScopeObj[string_scope] = lo.cloneDeep(out)
		return out
	}

	me.seenNotis = (category) => api.seenNotis(category)
	me.joinNotis = (a, b) => {
		a = a || []
		b = b || []
		let notiM = {}
		lo.map(a.concat(b), (noti) => {
			if (!noti || !noti.created || !noti.checkpoint) return
			let key = noti.checkpoint + '-' + noti.topic
			let old = notiM[key] || {created: 0}
			if (noti.created < old.created) return
			notiM[key] = noti
		})

		return lo.orderBy(lo.map(notiM), ['created', 'topic'], ['desc', 'desc'])
	}

	me.readNotification = (category, checkpoint, topic) => api.readNoti(category, checkpoint, topic)

	// AGENT OWNER
	me.updateAgentOwner = (id) => api.update_agent_owner(id)

	me.getMemberViewing = (memberid, convoid) => {
		let now = Date.now()
		let found = lo.find(_viewingDb, (viewing, k) => {
			if (!k.startsWith(memberid + '.')) return false
			if (now - viewing.pinged > 12000) {
				delete _viewingDb[k] // keep viewingdb clean in long running tab
				return false
			}
			return viewing.last_seen_convo_id == convoid
		})
		if (found) return true

		// fallback to agent last_seen
		let ag = agentdb.match()[memberid] || {}
		let viewing = ag.last_seen || {}
		if (now - viewing.pinged > 11000) return false
		return viewing.last_seen_convo_id == convoid
	}

	let _viewingDb = {} // per tab
	realtime.onEvent((ev) => {
		if (ev.type === 'agent_presence_updated') {
			let agid = lo.get(ev, 'data.presence.user_id')
			let agent = me.matchAgent()[agid]
			if (!agent) return

			let lastconvo = ''
			if (agent.last_seen) lastconvo = agent.last_seen.last_seen_convo_id
			if (lastconvo) pubsub.publish('viewing_convo', lastconvo) // to stop it
			agent.last_seen = ev.data.presence
			let convoid = lo.get(ev, 'data.presence.last_seen_convo_id')
			let tabid = lo.get(ev, 'data.presence.browser_tab_id', '')
			let lastviewingconvo = lo.get(_viewingDb, [agid + '.' + tabid, 'last_seen_convo_id'], '')
			_viewingDb[agid + '.' + tabid] = lo.get(ev, 'data.presence')
			if (lastviewingconvo) pubsub.publish('viewing_convo', convoid) // to stop it

			if (convoid) {
				pubsub.publish('viewing_convo', convoid) // to stop it
				setTimeout(() => pubsub.publish('viewing_convo', convoid), 12000) // try to let client know to clear viewing
			}

			agentdb.justUpdate(agent)
			return
		}

		if (ev.type === 'agent_updated') {
			let newag = lo.get(ev, 'data.agent')
			agentdb.justUpdate(newag)
			return
		}

		if (ev.type === 'login_session_updated') {
			let session = lo.get(ev, 'data.login_session') || {}
			if (session.state == 'ended' && session.access_token == access_token) {
				return parent.logout()
			}
			return pubsub.publish('loginsession')
		}
	})

	let current_call_id = ''
	me.getCurrentCall = () => {
		if (current_call_id) {
			let call = webrtcconn.matchCall(current_call_id)
			if (call && call.status != 'ended') return call
		}

		let myOutgoingCall = lo.find(
			webrtcconn.matchCall(),
			(call) =>
				(call.status == 'dialing' || call.status == 'active') &&
				(call.direction == 'outbound' || call.direction == 'outgoing'),
		)
		if (myOutgoingCall) {
			current_call_id = myOutgoingCall.call_id || ''
			return myOutgoingCall
		}

		let dialingIncomingCall = lo.find(
			webrtcconn.matchCall(),
			(call) => call.status == 'dialing' && (call.direction == 'incoming' || call.direction == 'inbound'),
		)
		if (dialingIncomingCall) {
			current_call_id = dialingIncomingCall.call_id || ''
			return dialingIncomingCall
		}

		current_call_id = ''
		return undefined
	}

	me.matchWebcallCall = (callid) => webrtcconn.matchCall(callid)

	me.onWebCallStatus = (o, cb) => pubsub.on2(o, 'call_status', cb) // dialing - hangup - answered
	me.onWebPhoneStatus = (o, cb) => pubsub.on2(o, 'webphone_status', cb) // not-ready

	me.sendDTMF = (key, callid) => webrtcconn.sendDtmf(key, callid)
	me.transferWebCall = async (number, callid) => webrtcconn.transferCall(number, callid)
	me.hangUpWebCall = (callid) => webrtcconn.hangupCall(callid)

	me.isMicAllowed = async () => {
		return await me.checkMic().result
	}
	me.getMicroStream = () => parent.micStream
	me.checkMic = () => {
		const timeout = new Promise((rs, rj) => setTimeout(rs, 500, 'Not_authorized'))
		let microPermission = parent.getMicroPermissions()
		return {
			timeout: Promise.race([timeout, microPermission]),
			result: parent.getMicroPermissions(),
		}
	}

	parent.micStream = undefined
	parent.getMicroPermissions = async () => {
		try {
			parent.micStream = await navigator.mediaDevices.getUserMedia({audio: true, video: false})
		} catch (err) {
			console.log('REJECT VIDEO PERMISSSIONN', err)
			parent.micStream = undefined
			return undefined
		}
		return parent.micStream
	}

	me.matchWebcallCall = (callid) => webrtcconn.matchCall(callid)

	me.testMic = async () => {
		parent.micStream = await navigator.mediaDevices.getUserMedia({audio: true, video: false})
	}

	// mobile only
	let beforeCall = async () => {
		if (!window.SoundMgr) return
		await window.SoundMgr.beforeStartCall()
	}

	me.makeWebCall = async (number, fromnumber, campaignid = '', outboundcallentryid = '') => {
		await beforeCall()

		// 11edc52b-2918-4d71-9058-f7285e29d894
		let callid = uuid.v4() // must be uuid or IOS callkeep wont work, see https://github.com/react-native-webrtc/react-native-callkeep/issues/125
		current_call_id = callid
		console.log('makeWebCall', campaignid, outboundcallentryid)
		let {body, error} = await webrtcconn.makeCall(
			number,
			fromnumber,
			new Promise(async (rs, rj) => {
				let stream = await parent.getMicroPermissions()
				if (stream && stream.error) return rj(stream.error)
				rs(stream)
			}),
			callid,
			campaignid,
			outboundcallentryid,
		)
		if (error) return {error}
		return body
	}

	me.answerWebCall = async (callid) => {
		await beforeCall()
		let stream = await parent.getMicroPermissions()
		current_call_id = callid
		let {body, error} = await webrtcconn.answerCall(callid, stream)
		if (error) return {error}
		return body
	}

	realtime.subscribe(['bot_debug_end', 'bot_debug_begin_action']) // ignore result
	// AGENT GROUP
	let agentGroupDB = new Entity(realtime, pubsub, memKV, 'agent_group')
	me.fetchAgentGroups = (f) => agentGroupDB.list(f)
	me.matchAgentGroup = (_) => agentGroupDB.match()
	me.updateAgentGroup = (ag) => agentGroupDB.update(ag)
	me.removeAgentGroup = (id) => agentGroupDB.remove(id)
	me.addAgentGroup = (ag) => agentGroupDB.create(ag)
	me.fetchAgentGroupList = async () => {
		let res = await agentGroupDB.list()
		if (res.error) return res

		let groups = lo.filter(agentGroupDB.match(), (gr) => isGroupIdNormal(gr.id))
		groups = lo.orderBy(groups, ['created'], ['desc'])

		return {
			items: groups,
			total: lo.size(groups),
		}
	}

	// product collection
	let productCollectionDB = new Entity(realtime, pubsub, accKV, 'product_collection')
	me.fetchProductCollections = (f) => productCollectionDB.list(f)
	me.matchProductCollection = (_) => productCollectionDB.match()
	me.createProductCollection = (pc) => productCollectionDB.create(pc)
	me.updateProductCollection = (pc) => productCollectionDB.update(pc)
	me.removeProductCollection = (id) => productCollectionDB.remove(id)
	me.addProductCollection = (pc) => productCollectionDB.create(pc)

	// RULE
	let ruledb = NewStandardStore(realtime, pubsub, 'rule')
	me.fetchRules = (query = {}) => ruledb.list(query)
	me.fetchRuleIds = ruledb.fetch
	me.matchRule = (id) => ruledb.match(id)
	me.updateRule = (rule) => ruledb.update(rule)
	me.addRule = (rule) => ruledb.create(rule)
	me.removeRule = (id) => ruledb.delete(id)
	me.updateRuleOrdered = async (ids) => {
		return api.update_rule_orders({rules: ids})
	}

	let ruleorderdb = new Entity(realtime, pubsub, accKV, 'rule_order')
	me.fetchRuleOrders = ruleorderdb.list
	me.matchRuleOrder = ruleorderdb.match

	const _isRuleDisabled = (rule) => !rule.enabled
	me.isRuleDisabled = _isRuleDisabled

	me.fetchRuleList = async (query) => {
		let res = await _fetchRuleList(query)
		if (res.error) return res

		let rules = lo.get(res, 'body.rules') || []
		let total = lo.get(res, 'body.total')
		let map = {}
		lo.each(rules, (rule) => {
			map[rule.id] = rule
		})

		let orders = lo.get(res, 'body.rule_orders') || []
		orders = lo.filter(orders, (id) => map[id])

		return {
			items: lo.map(orders, (id) => map[id] || {id}),
			total,
		}
	}

	const _fetchRuleList = async (query = {}) => {
		let res = await ruledb.list(query)
		if (res.error) return res

		let res2 = await ruleorderdb.list()
		if (res2.error) return res2

		let rules = lo.map(lo.get(res, 'body.items'), (rule) => rule)
		let total = lo.get(res, 'body.total')
		let ruleOrders = lo.map(ruleorderdb.match(), (_, id) => id)

		let hasGroup = false
		lo.each(rules, (rule) => {
			if (lo.get(rule, 'strategy') === 'agentgroup') {
				hasGroup = true
				return false // break loop
			}
		})
		if (hasGroup) await agentGroupDB.list()

		return {
			body: {
				rules,
				total,
				rule_orders: ruleOrders,
			},
		}
	}

	// RULE
	let ticketruledb = new Entity(realtime, pubsub, accKV, 'ticket_rule')
	me.fetchTicketRules = () => ticketruledb.list()
	me.matchTicketRule = (_) => ticketruledb.match()
	me.updateTicketRule = (rule) => ticketruledb.update(rule)
	me.addTicketRule = (rule) => ticketruledb.create(rule)
	me.removeTicketRule = (id) => ticketruledb.remove(id)

	me.fetchApikeys = () => api.listApikey()
	me.createApikey = (apikey) => api.createApikey(apikey)
	me.fetchLoginSessions = () => api.listLoginSession()
	me.logoutSession = (id) => api.logoutSession(id)

	// TAG
	let tagdb = new Entity(realtime, pubsub, accKV, 'tag')
	me.fetchTags = () => tagdb.list()
	me.matchTag = (_) => tagdb.match()
	me.updateTag = (tag) => tagdb.update(tag)
	me.createTag = (tag) => tagdb.create(tag)
	me.removeTag = (id) => tagdb.remove(id)
	me.fetchTagList = async ({type}) => {
		let res = await tagdb.list()
		if (res.error) return res

		let items = lo.filter(tagdb.match(), (tag) => {
			if (!type) return !tag.type
			return tag.type === type
		})
		items = lo.orderBy(items, ['created'], ['desc'])

		return {
			items,
			total: lo.size(items),
		}
	}

	// ATTRIBUTE
	let attrdb = new Entity(realtime, pubsub, accKV, 'user_attribute', 'key')
	me.fetchUserAttributes = () => attrdb.list()
	me.matchUserAttribute = (_) => attrdb.match()
	me.updateUserAttribute = (attr) => attrdb.update(attr)
	me.addUserAttribute = (attr) => attrdb.create(attr)
	me.removeUserAttribute = (id) => attrdb.remove(id)

	// LABELS
	let labeldb = new Entity(realtime, pubsub, accKV, 'label', 'id')
	me.fetchUserLabels = (f) => labeldb.list(f)
	me.matchUserLabel = (_) => labeldb.match()
	me.updateUserLabel = (label) => labeldb.update(label)
	me.removeUserLabel = async (id) => {
		let {error: err} = await api.delete_label2(id)
		if (err) return {error: err}
		return {}
	}
	me.fetchUserLabelList = async () => {
		let res = await labeldb.list()
		if (res.error) return res

		let items = lo.filter(labeldb.match(), (label) => label.source_channel !== 'facebook' && !label.deleted)
		items = lo.orderBy(items, ['created'], ['desc'])
		return {
			items,
			total: lo.size(items),
		}
	}

	// FABIKON PAGES
	me.getCodeChallenge = () => api.get_zalo_challenge()

	// Incomming Email
	let incommingEmailDB = new Entity(realtime, pubsub, accKV, 'incomming_email', 'email')
	me.fetchIncommingEmails = () => incommingEmailDB.list()
	me.matchIncommingEmail = (_) => incommingEmailDB.match()
	me.addIncommingEmail = (page) => incommingEmailDB.create(page)
	me.removeIncommingEmail = (id) => incommingEmailDB.remove(id)

	// Conversation Modal
	let convoModalDB = new Entity(realtime, pubsub, accKV, 'conversation_modal')
	me.fetchConversationModals = () => convoModalDB.list()
	me.matchConversationModal = () => convoModalDB.match()
	me.createConversationModal = (p) => convoModalDB.create(p)
	me.updateConversationModal = (p) => convoModalDB.update(p)
	me.removeConversationModal = (id) => convoModalDB.remove(id)
	me.pickConversationModal = (cid, mid, text, uid) => api.pickConversationModal(cid, mid, text, uid)

	// Message Template
	let messageTemplateDB = NewStandardStore(realtime, pubsub, 'template')
	me.applyMessage = (ev) => api.apply_message(ev)
	me.fetchMessageTemplates = (params, force) => messageTemplateDB.list(params, force)
	me.matchMessageTemplate = (id) => messageTemplateDB.match(id)
	me.addMessageTemplate = async (message) => {
		let {message: newMessage, error} = await common.replaceMessageBase64Images(message.message)
		if (error) return {error: 'invalid_attachment_url'}
		message.message = newMessage
		return messageTemplateDB.create(message)
	}
	me.updateMessageTemplate = async (message) => {
		let {message: newMessage, error} = await common.replaceMessageBase64Images(message.message)
		if (error) return {error: 'invalid_attachment_url'}
		message.message = newMessage

		return messageTemplateDB.update(message)
	}
	me.removeMessageTemplate = (id) => messageTemplateDB.delete(id)

	let msgtmpl_list_cache = {}
	me.fetchTemplateList = async ({run_id, limit = 20, anchor, is_email, keyword}) => {
		let res = await messageTemplateDB.list()
		if (res.error) return res

		let allTemplates = lo.get(res, 'items') || []
		allTemplates = _filterMsgTemplates(allTemplates, {is_email, keyword})
		allTemplates = lo.orderBy(allTemplates, ['created'], ['desc'])

		let items = []
		let next_anchor = ''
		for (let i = 0; i < limit; i++) {
			let fromidx = 0
			if (anchor) fromidx = lo.findIndex(allTemplates, (msgtmpl) => msgtmpl.id === anchor)
			let msgtmpl = allTemplates[fromidx + i]
			if (msgtmpl) items.push(msgtmpl)
			next_anchor = lo.get(allTemplates, [fromidx + i + 1, 'id'])
		}
		return {
			items,
			total: lo.size(allTemplates),
			next_anchor,
		}
	}

	const _filterMsgTemplates = (templates, {is_email, keyword}) => {
		let result = lo.filter(templates, (msgtmpl) => {
			let allow = msgtmpl.is_public || msgtmpl.creator === me.me().id
			if (!allow) return false
			let typeMatch = true
			let keywordMatch = true
			if (is_email) {
				if (is_email === 'true') typeMatch = msgtmpl.channel_type === 'email'
				else if (is_email === 'false') typeMatch = msgtmpl.channel_type !== 'email'
			}
			if (lo.trim(keyword)) {
				keyword = sb.unicodeToAscii(keyword).toLowerCase()
				let shortcut = lo.get(msgtmpl, 'keys', []).join(',')
				shortcut = sb.unicodeToAscii(shortcut).toLowerCase()
				let content = lo.get(msgtmpl, 'message.text')
				if (msgtmpl.channel_type === 'email') {
					let fields = lo.get(msgtmpl, 'message.fields', [])
					let subject = lo.find(fields, (f) => f.key == 'subject') || {}
					content = subject.value
				}
				content = sb.unicodeToAscii(content).toLowerCase()
				keywordMatch = shortcut.indexOf(keyword) > -1 || content.indexOf(keyword) > -1
			}

			return typeMatch && keywordMatch
		})
		return result
	}

	// WEBHOOK
	let webhookdb = new Entity(realtime, pubsub, accKV, 'webhook')
	me.fetchWebhooks = (force) => webhookdb.list(force)
	me.matchWebhook = (_) => webhookdb.match()
	me.updateWebhook = (wh) => webhookdb.update(wh)
	me.removeWebhook = (id) => webhookdb.remove(id)
	me.createWebhook = (wh) => webhookdb.create(wh)
	me.listWebhookDeliveries = (id) => api.list_webhook_deliveries(id)
	me.fetchWebhookList = async () => {
		let res = await webhookdb.list()
		if (res.error) return res

		let items = lo.map(webhookdb.match(), (wh) => wh)
		//items = lo.orderBy(items, ['created'], ['desc'])

		return {
			items,
			total: lo.size(items),
		}
	}

	let webhookdeliverydb = new Entity(realtime, pubsub, accKV, 'webhook_delivery')
	me.fetchLastWebhookDeliveries = () => webhookdeliverydb.list(true)
	me.matchLastWebhookDeliveries = () => webhookdeliverydb.match()
	me.fetchDelivery = (id, did) => api.get_webhook_delivery(id, did)

	// Bot
	let botDB = new Entity(realtime, pubsub, memKV, 'bot')
	me.fetchBots = (force) => botDB.list(force)
	me.matchBot = (_) => botDB.match()
	me.createBot = (bot) => botDB.create(bot)
	me.updateBot = async (bot) => {
		let {bot: newBot, error} = await replaceBotBase64Images(bot)
		if (error) return {error: 'invalid_attachment_url'}
		bot = newBot
		return botDB.update(bot)
	}
	me.removeBot = (id) => botDB.remove(id)
	me.fetchBotList = async ({keyword}) => {
		let res = await botDB.list()
		if (res.error) return res

		let items = lo.filter(store.matchBot(), (bot) => {
			if (!lo.trim(keyword)) return true

			keyword = sb.unicodeToAscii(keyword).toLowerCase()
			let name = sb.unicodeToAscii(bot.fullname || '').toLowerCase()
			return name.indexOf(keyword) > -1
		})
		items = lo.orderBy(items, ['updated'], ['desc'])
		return {
			items,
			total: lo.size(items),
		}
	}
	me.matchBotList = () => {
		let bots = botDB.match()
		bots = lo.orderBy(bots, ['updated'], ['desc'])
		return {
			records: lo.map(bots, (bot) => bot),
			total: lo.size(bots),
		}
	}
	me.testBot = (p) => api.test_bot(p)
	me.testBot2 = (p) => api.test_bot_2(p)
	me.reportBot = (p) => api.report_bot(p)
	me.reportBotNew = api.report_bot_new
	me.reportBotActions = api.report_bot_actions
	me.updateBotState = async (p) => {
		let {body, error} = await api.update_bot_state(p)
		if (error) return {error}
		return botDB.justUpdate(body)
	}

	async function replaceBotBase64Images(bot) {
		bot = lo.cloneDeep(bot)
		let blobs = getAllBotBlobPaths(bot)

		if (isBlobUrl(bot.avatar_url) || isDataUrl(bot.avatar_url)) {
			blobs.push({path: 'avatar_url', url: bot.avatar_url})
		}
		let error = ''
		await flow.map(blobs, 5, async (blob) => {
			let {url, path} = blob
			let file
			if (isBlobUrl(url)) file = await sb.blobUrlToFile(url)
			if (isDataUrl(url)) file = await sb.dataURLToFile(url)
			let res = await common.uploadLocalFile(file)
			if (res.error) {
				error = res.error
			}
			let newUrl = res.url
			lo.set(bot, path, newUrl)
		})

		return {bot, error}
	}

	function getAllBotBlobPaths(bot) {
		let output = []
		let nodes = [{node: bot, currentActionPath: 'action'}]
		while (true) {
			let nexts = []
			lo.each(nodes, ({node, currentActionPath}) => {
				let resumeMessage = lo.get(node, 'action.ask_question.resume_message', {})
				lo.each(resumeMessage.attachments, (att) => {
					if (att.type === 'generic') {
						lo.each(att.elements, (ele, eleIndex) => {
							if (isBlobUrl(ele.image_url) || isDataUrl(ele.image_url)) {
								output.push({
									path: `${currentActionPath}.ask_question.resume_message.attachments.${attIndex}.elements.${eleIndex}.image_url`,
									url: ele.image_url,
								})
							}
						})
					} else {
						if (isBlobUrl(att.url) || isDataUrl(att.url)) {
							output.push({
								path: `${currentActionPath}.ask_question.messages.${mIdx}.attachments.${attIndex}.url`,
								url: att.url,
							})
						}
					}
				})

				let messages = lo.get(node, 'action.ask_question.messages')
				lo.each(messages, (message, mIdx) => {
					let attachments = lo.get(message, 'attachments')
					lo.each(attachments, (att, attIndex) => {
						if (att.type === 'generic') {
							lo.each(att.elements, (ele, eleIndex) => {
								if (isBlobUrl(ele.image_url) || isDataUrl(ele.image_url)) {
									output.push({
										path: `${currentActionPath}.ask_question.messages.${mIdx}.attachments.${attIndex}.elements.${eleIndex}.image_url`,
										url: ele.image_url,
									})
								}
							})
						} else {
							if (isBlobUrl(att.url) || isDataUrl(att.url)) {
								output.push({
									path: `${currentActionPath}.ask_question.messages.${mIdx}.attachments.${attIndex}.url`,
									url: att.url,
								})
							}
						}
					})
				})
				let action = node.action || {}
				lo.each(action.nexts, (next, nextIdx) => {
					nexts.push({node: next, currentActionPath: currentActionPath + `.nexts.${nextIdx}.action`})
				})
			})
			if (nexts.length === 0) break
			nodes = nexts
		}
		return output
	}

	function isBlobUrl(url = '') {
		return url.startsWith('blob')
	}

	function isDataUrl(url = '') {
		return url.startsWith('data:')
	}

	// Web checks
	let webcheckDB = new Entity(realtime, pubsub, accKV, 'webcheck')
	me.fetchWebcheck = async (f) => {
		await webcheckDB.list(f)
	}

	me.matchWebcheck = (_) => webcheckDB.match()
	me.createWebcheck = (wc) => webcheckDB.create(wc)
	me.updateWebcheck = (wc) => webcheckDB.update(wc)
	me.removeWebcheck = (id) => webcheckDB.remove(id)
	me.getDetailWebcheck = api.get_detail_webcheck
	me.getDailySummaryWebcheck = api.get_summary_webcheck

	// Web plugin template
	let webPluginTemplateDB = new Entity(realtime, pubsub, accKV, 'web_plugin_template')
	me.fetchWebPluginTemplates = (force) => webPluginTemplateDB.list(force)
	me.matchWebPluginTemplate = (_) => webPluginTemplateDB.match()
	me.updateWebPluginTemplate = (plugin) => webPluginTemplateDB.update(plugin)
	me.removeWebPluginTemplate = (id) => webPluginTemplateDB.remove(id)
	me.updateWebPluginState = async (p) => {
		let {body, error} = await api.update_web_plugin_state(p)
		if (error) return {error}
		return webPluginDB.justUpdate(body)
	}

	let notifProfileDB = new Entity(realtime, pubsub, accKV, 'notif_profiles', 'avatar_url')
	me.fetchNotifProfiles = (force) => notifProfileDB.list(force)
	me.matchNotifProfiles = (_) => notifProfileDB.match()

	// Web plugin
	let webPluginDB = new Entity(realtime, pubsub, accKV, 'web_plugin')
	me.fetchWebPlugins = (force) => webPluginDB.list(force)
	me.matchWebPlugin = (_) => webPluginDB.match()
	me.fetchWebPluginX = (accid) => api.list_web_plugin_x(accid)
	me.updateWebPluginX = (p) => api.update_web_plugin_x(p)
	me.fetchWebPluginList = async ({type}) => {
		let res = await webPluginDB.list()
		if (res.error) return res

		let items = lo.filter(store.matchWebPlugin(), (plugin) => {
			if (!type) return plugin.type !== 'chatbox'

			return plugin.type === type
		})
		items = lo.orderBy(items, ['updated'], ['desc'])

		return {
			items,
			total: lo.size(items),
		}
	}

	me.createWebPlugin = async (plugin) => {
		let hasEmailNotif = lo.get(plugin, 'conversion_notification.enabled')
		if (!hasEmailNotif) return webPluginDB.create(plugin)

		let message = lo.get(plugin, 'conversion_notification.user_email', {})
		let {message: newMessage, error} = await common.replaceMessageBase64Images(message)
		if (error) return {error: 'invalid_attachment_url'}
		lo.set(plugin, 'conversion_notification.user_email', newMessage)
		return webPluginDB.create(plugin)
	}
	me.updateWebPlugin = async (plugin) => {
		let hasEmailNotif = lo.get(plugin, 'conversion_notification.enabled')
		if (!hasEmailNotif) return webPluginDB.update(plugin)

		let message = lo.get(plugin, 'conversion_notification.user_email', {})
		let {message: newMessage, error} = await common.replaceMessageBase64Images(message)
		if (error) return {error: 'invalid_attachment_url'}
		lo.set(plugin, 'conversion_notification.user_email', newMessage)
		return webPluginDB.update(plugin)
	}
	me.removeWebPlugin = (id) => webPluginDB.remove(id)
	me.reportWebplugin = (p) => api.report_web_plugin(p)
	me.getWebpluginConversions = api.get_web_plugin_conversions
	me.updateWebPluginState = async (p) => {
		let {body, error} = await api.update_web_plugin_state(p)
		if (error) return {error}
		return webPluginDB.justUpdate(body)
	}

	me.summaryCampaign = (id) => {
		let campaignSummary = accKV.match('campaignSummary_' + id) || {}
		let isExpired = lo.get(campaignSummary, 'expired', 0) < Date.now()
		if (!isExpired) return campaignSummary

		// so we dont call api multiple time
		campaignSummary.expired = Date.now() + 60000
		accKV.put('campaignSummary_' + id, campaignSummary)

		setTimeout(async () => {
			let {body, error} = await api.summary_campaign(id)
			if (error) {
				campaignSummary = accKV.match('campaignSummary_' + id) || {}
				campaignSummary.expired = 0
				accKV.put('campaignSummary_' + id, campaignSummary)
				return
			}

			campaignSummary = body
			campaignSummary.expired = Date.now() + 60000
			accKV.put('campaignSummary_' + id, campaignSummary)
		})

		return campaignSummary
	}

	// Uploaded image
	let uploadedImageDB = new Entity(realtime, pubsub, accKV, 'uploaded_image', 'url')
	me.fetchUploadedImages = (_) => uploadedImageDB.list()
	me.matchUploadedImage = (_) => uploadedImageDB.match()
	me.addUploadedImage = (url) => uploadedImageDB.create(url)
	me.removeUploadedImage = (url) => uploadedImageDB.remove(url)

	// integration
	let inteDb = new Entity(realtime, pubsub, memKV, 'integration')
	me.fetchIntegrations = () => inteDb.list(true)
	me.matchIntegration = (_) => inteDb.match()
	me.removeIntegration = (id) => inteDb.remove(id)
	me.updateIntegration = (inte) => inteDb.update(inte)
	me.validateEmailIntegration = api.validate_email_integration

	me.addFacebookPage = (accesstoken, pageids, comments) => api.update_facebook_page(accesstoken, pageids, comments)
	me.addFacebookPageForBusiness = (code) => api.update_facebook_page_for_business(code)
	me.addInstagramPage = (accesstoken, pageids, comments) =>
		api.update_facebook_page(accesstoken, pageids, comments, true)

	me.getFbPagesFromAccessToken = async (accesstoken) => {
		let url =
			'https://graph.facebook.com/v16.0/me/accounts?limit=50&fields=id,name,picture,about,description,website,access_token,instagram_business_account%7Bid,username,profile_picture_url,name,biography,ig_id,followers_count,follows_count,media_count,website%7D&access_token=' +
			accesstoken
		let pages = []
		for (;;) {
			let out = await ajax.setParser('json').get(url)
			if (!out) break
			if (out.error) return {error: out.error}
			url = lo.get(out.body, 'paging.next')
			pages = pages.concat(out.body.data)
			if (!url) break
		}
		return {pages}
	}

	me.getIgPagesFromAccessToken = async (accesstoken) => {
		let out = await me.getFbPagesFromAccessToken(accesstoken)
		if (out.error) return out

		let pages = lo.filter(out.pages, (p) => p.instagram_business_account)
		return {pages}
	}

	// BLACK LIST IP
	let blackListIpDB = new Entity(realtime, pubsub, accKV, 'black_list_ip', 'ip')
	me.fetchBlackListIps = () => blackListIpDB.list()
	me.matchBlackListIp = (_) => blackListIpDB.match()
	me.addBlackListIp = (ip) => blackListIpDB.create(ip)
	me.removeBlackListIp = (ip) => blackListIpDB.remove(ip)

	// BLACK LIST USER
	let blackListUserDB = new Entity(realtime, pubsub, accKV, 'black_list_user', 'user_id')
	me.fetchBlackListUsers = () => blackListUserDB.list()
	me.matchBlackListUser = (_) => blackListUserDB.match()
	me.addBlackListUser = (ip) => blackListUserDB.create(ip)
	me.removeBlackListUser = (ip) => blackListUserDB.remove(ip)

	// WHITE LIST DOMAIN
	let whiteListDomainDB = new Entity(realtime, pubsub, accKV, 'white_list_domain', 'domain')
	me.fetchWhiteListDomains = () => whiteListDomainDB.list()
	me.matchWhiteListDomain = (_) => whiteListDomainDB.match()
	me.addWhiteListDomain = (domain) => whiteListDomainDB.create(domain)
	me.removeWhiteListDomain = (domain) => whiteListDomainDB.remove(domain)

	// SETTING NOTIFY
	let settingNotifyDB = new Entity(realtime, pubsub, accKV, 'setting_notify')
	me.fetchSettingNotify = () => settingNotifyDB.list()
	me.matchSettingNotify = (_) => settingNotifyDB.match()
	me.updateSettingNotify = (notify) => settingNotifyDB.update(notify)

	// BLOCK EMAIL
	let blockEmailDB = new Entity(realtime, pubsub, accKV, 'blocked_emails')
	me.fetchBlockEmails = (force) => blockEmailDB.list(force)
	me.matchBlockEmail = (_) => blockEmailDB.match()
	me.findBlockEmails = async (id) => {
		let {anchor, body, error} = await api['list_blocked_emails'](id)
		if (error) return {error}

		let objM = body
		let old = me.matchBlockEmail()
		await accKV.put('blocked_emails', objM)
		if (!lo.isEqual(old, objM)) pubsub.publish('account')
		return objM
	}
	me.createBlockEmail = (p) => blockEmailDB.create(p)
	me.removeBlockEmail = (id) => blockEmailDB.remove(id)

	// BLOCK EMAIL
	let bounceEmailDB = new Entity(realtime, pubsub, accKV, 'bounced_emails')
	me.fetchBounceEmails = (force) => bounceEmailDB.list(force)
	me.matchBounceEmail = (_) => bounceEmailDB.match()
	me.findBounceEmails = async (id) => {
		let {anchor, body, error} = await api['list_bounced_emails'](id)
		if (error) return {error}

		let objM = body
		let old = me.matchBounceEmail()
		await accKV.put('bounced_emails', objM)
		if (!lo.isEqual(old, objM)) pubsub.publish('account')
		return objM
	}
	me.removeBounceEmail = (id) => bounceEmailDB.remove(id)

	// BLOCK NUMBER
	let blockNumberDB = new Entity(realtime, pubsub, accKV, 'block_number', 'number')
	me.fetchBlockNumbers = () => blockNumberDB.list()
	me.matchBlockNumber = () => blockNumberDB.match()
	me.createBlockNumber = (p) => blockNumberDB.create(p)
	me.removeBlockNumber = (id) => blockNumberDB.remove(id)

	let kbDB = new Entity(realtime, pubsub, accKV, 'knowledge_base')
	me.fetchKnowledgeBases = (force) => kbDB.list(force)
	me.matchKnowledgeBase = () => kbDB.match()
	me.createKnowledgeBase = (p) => kbDB.create(p)
	me.removeKnowledgeBase = (id) => kbDB.remove(id)
	me.updateKnowledgeBase = (kb) => kbDB.update(kb)
	me.fetchKnowledgeBaseDetail = api.get_knowledge_base_detail
	me.updateKBTree = api.update_knowledge_base_tree

	let articleDB = new Entity(realtime, pubsub, accKV, 'article')
	me.fetchArticles = (force) => articleDB.list(force)
	me.matchArticle = () => articleDB.match()
	me.createArticle = (p) => articleDB.create(p)
	me.removeArticle = (id) => articleDB.remove(id)
	me.updateArticle = (art, fields) => articleDB.update(art, fields)
	me.getArticle = (art_id) => api.get_article(art_id)
	me.listArticleReport = (art_id) => api.list_article_reports(art_id)
	me.listArticleVersions = (art_id) => api.list_article_versions(art_id)
	me.getArticleVersion = (art_id, version) => api.get_article_version(art_id, version)

	let articleCategoryDB = new Entity(realtime, pubsub, accKV, 'article_category')
	me.fetchArticleCategories = (force) => articleCategoryDB.list(force)
	me.matchArticleCategory = () => articleCategoryDB.match()
	me.createArticleCategory = (p) => articleCategoryDB.create(p)
	me.removeArticleCategory = (id) => articleCategoryDB.remove(id)
	me.updateArticleCategory = (cat) => api.update_article_category(cat)

	// let articleTopicDB = new Entity(realtime, pubsub, accKV, 'article_topic')
	// me.fetchArticleTopics = (force) => articleTopicDB.list(force)
	// me.matchArticleTopics = () => articleTopicDB.match()
	// me.createArticleTopic = (tp) => articleTopicDB.create(tp)
	// me.removeArticleTopic = (id) => articleTopicDB.remove(id)
	me.fetchTopicKb = (kbid) => api.list_article_topic(kbid)
	me.createTopicKb = (tp) => api.create_article_topic(tp)
	me.updateTopicKb = (tp) => api.update_article_topic(tp)
	me.removeTopicKb = (id) => api.remove_article_topic(id)

	me.addTopicToArticle = (article_id, topic_id) => api.add_topic_article(article_id, topic_id)
	me.removeTopicInArticle = (article_id, topic_id) => api.remove_topic_article(article_id, topic_id)
	me.getKbEvents = (kbid, data) => api.get_kb_event(kbid, data)

	let aidb = new Entity(realtime, pubsub, accKV, 'ai_agent')
	me.fetchAiAgents = (f) => aidb.list(f)
	me.matchAiAgent = () => aidb.match()
	me.createAiAgent = (p) => aidb.create(p)
	me.updateAiAgent = (p) => aidb.update(p)
	me.removeAiAgent = async (id) => {
		let res = await aidb.remove(id)
		return res
	}
	me.testAiAgent = api.test_ai_agent
	me.fetchAiAgentList = async (query) => {
		let res = await aidb.list()
		if (res.error) return res

		let items = lo.map(res, (agent, id) => agent)
		items = lo.orderBy(items, ['created'], ['desc'])
		return {
			items,
			total: lo.size(items),
		}
	}
	me.matchAiAgentList = () => {
		let agentM = me.matchAiAgent()
		let items = lo.map(agentM, (agent, id) => agent)
		items = lo.orderBy(items, ['created'])

		return {records: items, total: lo.size(items)}
	}

	me.fetchAiQnaSuggestion = api.list_ai_qna_sugesstion
	me.updateAiQnaSuggestion = api.update_ai_qna_suggestion
	me.removeAiQnaSuggestion = api.delete_ai_qna_suggestion

	let workflow_report_map = {}
	let workflow_sessions = {}
	let workflowDB = new Entity(realtime, pubsub, memKV, 'workflow')
	me.fetchWorkflow = (force) => workflowDB.list(force)
	me.matchWorkflow = (id) => {
		if (!id || id == '*') return workflowDB.match()
		return workflowDB.match()[id]
	}

	me.fetchWorkflowList = async (query) => {
		let res = await workflowDB.list()
		if (res.error) return res

		let workflows = lo.map(res, (wf) => wf)
		if (lo.trim(query.keyword)) {
			workflows = lo.filter(workflows, (wf) => {
				let keyword = sb.unicodeToAscii(query.keyword).toLowerCase()
				let name = sb.unicodeToAscii(wf.name).toLowerCase()
				return name.indexOf(keyword) > -1
			})
		}
		workflows = _sortWorkflows(workflows)
		return {
			items: workflows,
			total: lo.size(workflows),
		}
	}
	me.matchWorkflowList = () => {
		let map = workflowDB.match()
		let workflows = lo.map(map, (wf) => wf)
		workflows = _sortWorkflows(workflows)
		return {
			records: workflows,
			total: lo.size(workflows),
		}
	}

	const _sortWorkflows = (workflows) => {
		workflows = lo.orderBy(workflows, ['created'], ['desc'])
		let actives = []
		let remains = []
		lo.each(workflows, (workflow) => {
			if (workflow.disabled) {
				remains.push(workflow)
			} else {
				actives.push(workflow)
			}
		})

		return [...actives, ...remains]
	}

	me.matchAutomation = (id) => {
		if (!id || id == '*') return workflowDB.match()
		return workflowDB.match()[id]
	}

	me.createWorkflow = async (p) => {
		let res = workflowDB.create(p)
		if (lo.get(res, 'error')) return res
		let workflow = lo.get(res, 'body') || {}
		console.log('createWorkflow', workflow)
		if (workflow.id) {
			workflow_report_map[workflow.id] = workflow
		}
		return res
	}
	me.updateWorkflow = async (p) => {
		let res = await workflowDB.update(p)
		if (lo.get(res, 'error')) return res
		let workflow = lo.cloneDeep(res)
		console.log('updateWorkflow', workflow)
		if (workflow.id) {
			workflow_report_map[workflow.id] = workflow
		}
		return res
	}
	me.removeWorkflow = async (id) => {
		let res = await workflowDB.remove(id)
		if (lo.get(res, 'error')) return res
		delete workflow_report_map[id]
		return res
	}
	me.fetchWorkflowDetail = async (id) => {
		let res = await api.get_detail_workflow(id)
		if (!lo.get(res, 'error')) {
			let workflow = lo.get(res, 'body.workflow') || {}
			workflow_report_map[id] = workflow
		}
		return res
	}
	me.fetchWorkflowReport = api.get_workflow_report
	me.listWorkflowSessions = async (workflow_id, params) => {
		let res = await api.get_workflow_session_list(workflow_id, params)
		if (res.error) return res
		let sessions = lo.get(res, 'body.workflow_sessions') || []
		lo.each(sessions, (session) => {
			workflow_sessions[session.id] = session
		})
		return res
	}
	me.fetchWorkflowSession = async ({workflow_id, session_id}) => {
		let res = await api.get_workflow_session_detail({workflow_id, session_id})
		if (res.error) return res
		let session = lo.get(res, 'body.workflow_session')
		if (session) {
			workflow_sessions[session.id] = session
		}
		pubsub.publish('workflow_session', session)
		return res
	}
	me.commandWorkflowSession = async (params) => {
		let res = await api.command_workflow_session(params)
		if (res.error) return res
		let session = lo.get(res, 'body.workflow_session')
		if (session) {
			workflow_sessions[session.id] = session
		}
		pubsub.publish('workflow_session', session)
		return res
	}
	me.createWorkflowSession = api.create_workflow_session
	me.runPreviewAction = api.run_preview_action
	me.matchWorkflowSession = (id) => workflow_sessions[id]
	me.listWorkflowLogs = api.get_workflow_logs
	me.matchWorkflowDetail = (id) => {
		return workflow_report_map[id]
	}
	me.sendTestEmail = async (msg) => {
		let ev = {
			touchpoint: {
				channel: 'email',
				source: sb.getMsgField({data: {message: msg}}, 'from'),
			},
			by: {
				type: 'agent',
				id: me.me().id,
			},
			data: {
				message: msg,
				type: 'message_sent',
			},
		}
		let res = await api.sendMsgEvent(ev)
		return res
	}

	me.payReferrer = (agid, p) => api.pay_referrer(agid, p)

	me.fetchReferredCustomer = async (agid) => {
		let res = await api.list_referrer_customers(agid)
		if (res.error) return res
		return res.body
	}

	me.fetchReferredPayout = async (agid) => {
		let res = await api.list_referrer_payouts(agid)
		if (res.error) return res
		return res.body
	}

	me.fetchReferredBills = async (agid) => {
		let res = await api.list_referrer_bills(agid)
		if (res.error) return res
		return res.body
	}
	me.getReferredAgent = async (agid) => {
		let res = await api.get_referred_agent(agid)
		if (res.error) return res
		return res.body
	}

	me.updateBankInfo = async (bank_info) => {
		const res = await api.update_bank_info(bank_info)
		if (res.error) return res
		return res.body
	}

	me.getInviteAccountLink = async () => {
		const res = await api.get_invite_account_link()
		if (res.error) return res
		return res.body
	}
	me.sendInviteEmail = (email_data) => {
		const {emails, scope} = email_data
		return api.send_invite_email(emails, scope)
	}

	me.onViewingConvo = (o, cb) => pubsub.on2(o, 'viewing_convo', cb)
	me.onLoginSession = (o, cb) => pubsub.on2(o, 'loginsession', cb)
	me.onAccount = (o, cb) => pubsub.on2(o, 'account', cb)
	me.onAccount2 = (cb) => pubsub.on('account', cb)
	me.onRoute = (o, cb) => pubsub.on2(o, 'route', cb)
	me.onInstantNoti = (o, cb) => pubsub.on2(o, 'instant_noti', cb)
	me.onSettingHellobar = (o, cb) => pubsub.on2(o, 'setting_hellobar', cb)
	me.onWorkflowSession = (o, cb) => pubsub.on2(o, 'workflow_session', cb)

	me.makeCall = (p) => {
		let param = lo.cloneDeep(p)
		param.touchpoint.id = param.touchpoint.id.replace(/\W/g, '')
		return api.make_call(param)
	}
	me.checkUserAllowZccCall = api.check_user_allow_zcc_call
	me.sendZccCallPermission = api.send_allow_zcc_call

	let $audio = null
	// create audio tag to play webcall
	let createAudioTag = () => {
		if ($audio) return
		if (!document.createElement) return // mobile

		$audio = document.createElement('audio')
		$audio.id = 'web_call_audio'
		$audio.style = 'display: none'
		$audio.autoplay = 'autoplay'
		document.body.appendChild($audio)
	}
	createAudioTag()

	let webrtcconn = new WebRTCConn({
		accid: account_id,
		agid: agent_id,
		access_token: api.getCred().access_token,
		realtime,
		env: window,
		onEvent: async (ev) => {
			// publish call status event
			if ((ev.type || '').startsWith('call')) {
				let callid = lo.get(ev, 'data.call_info.call_id')
				if (!callid) return

				let direction = lo.get(ev, 'data.call_info.direction')
				let number = lo.get(ev, 'data.call_info.from_number', '')

				if (direction === 'outgoing' || direction == 'outbound') number = lo.get(ev, 'data.call_info.to_number')
				let info = (await store.fetchCallInfo(number)) || {}

				ev = lo.cloneDeep(ev)
				lo.set(ev, 'data.call_info.caller_info', info)
				pubsub.publish('call_status', ev)
			}
		},
		onTrack: (event) => {
			if (event.track.kind != 'audio') return
			if ($audio) $audio.srcObject = event.streams[0]
		},
		collect: (metric, callid, ts) => {
			if (metric == 'join_call' || metric == 'listen_call' || metric == 'transfer_call') {
				api.collect('webrtc_connection_time', ts)
			}
		},
	})
	window.webrtcconn = webrtcconn

	me.createUserAccessToken = api.createUserAccessToken

	me.recordSearch = (query) => {
		let qs = accKV.match('search_query') || {}
		qs[query] = {query, at: sb.now()}
		if (lo.size(qs) > 20) {
			// trim the last latest 20 queries
			let newqs = {}
			lo.take(lo.orderBy(lo.map(qs), ['at', 'query'], ['desc', 'asc']), 20).map((q) => {
				if (!q) return
				newqs[q.query] = q.at
			})
			qs = newqs
		}

		accKV.put('search_query', qs)
	}
	me.matchSearchQuery = () =>
		lo.map(
			lo.orderBy(
				lo.map(accKV.match('search_query')).filter((v) => !!v),
				'at',
				'desc',
			),
			'query',
		)

	me.me = () => {
		if (!api.getCred()) return {}
		let {agent_id} = api.getCred()
		let agent = me.matchAgent()[agent_id] || {agent_id, account_id: api.getAccountId(), id: agent_id}
		agent.account = accdb.match()[api.getAccountId()] || {}
		return agent
	}

	me.canViewPopupReport = (plugin) => {
		const APPO_ACCOUNT_ID = 'acqpgfjvysssmqmahpkb' // temp hot fix for appo, they dont want agent see popup report of anotther

		if (!api.getCred()) return false
		let {agent_id} = api.getCred()
		if (api.getAccountId() !== APPO_ACCOUNT_ID) return true

		let agent = me.matchAgent()[agent_id] || {id: agent_id}
		let scopes = agent.scopes || []
		let isAdmin = scopes.includes('account_manage') || scopes.includes('account_setting')

		if (isAdmin) return true
		let allAgents = lo.get(plugin, 'conversion_notification.all_agents')
		let assignees = lo.get(plugin, 'conversion_notification.agents', [])
		return allAgents || assignees.includes(agent_id)
	}

	me.search_sub = (keyword) => api.search_sub(keyword)
	me.search_ref = (keyword) => api.search_ref(keyword)
	me.fetch_sub = (accid) => api.fetch_sub(accid)
	me.acc_bank_transfer = ({accid, credit_id, amount, description, currency}) =>
		api.acc_bank_transfer({accid, credit_id, amount, description, currency})
	me.accmgr_calculate_invoice = api.accmgr_calculate_invoice
	me.calculateSubscriptionAmount = api.calculate_subscription_amount
	me.acc_delete_agent = (ag) => api.acc_delete_agent(ag)
	me.acc_update_invoice = api.acc_update_invoice
	me.create_invoice = (accid, invoice) => api.create_invoice(accid, invoice)
	me.acc_update_agent_state = (ag) => api.acc_update_agent_state(ag)
	me.acc_fetch_invoice = (accid, creditid) => api.acc_fetch_invoice(accid, creditid)
	me.acc_fetch_sites = (accid, creditid) => api.acc_fetch_sites(accid)
	me.sendZns = api.sendMsgEvent
	me.getFieldsByView = api.get_fields_by_view
	me.importLeads = api.import_leads
	me.acc_fetch_inte = (accid) => api.acc_fetch_inte(accid)
	me.convert_invoice_html = (accid, invoice) => api.convert_invoice_html(accid, invoice)
	me.list_invoice_comments = (accid, topic) => api.list_invoice_comments(accid, topic)
	me.download_invoice = (accid, invoiceid) => api.download_invoice(accid, invoiceid)
	me.post_invoice_comment = (accid, topic, content) => api.post_invoice_comment(accid, topic, content)
	me.fetch_payment_logs = api.fetch_payment_logs
	me.acc_update_agent = (ag, fields) => api.update_agent(ag, fields)
	me.requestInvoice = api.request_invoice
	me.listBills = api.list_bills
	me.listCredits = api.list_credits
	me.shortenPayment = api.shorten_payment
	me.fetchCreditLogs = api.fetch_credit_logs
	me.fetchCreditReports = api.fetch_credit_reports
	let setup_features = {}
	me.fetchSetupFeatures = async () => {
		let res = await api.list_setup_features()
		if (res.error) return res

		setup_features = lo.get(res, 'body') || {}
		pubsub.publish('account', {object_type: 'setup_features'})
		return setup_features
	}

	me.clearSetupFeatures = () => {
		let obj = {}
		lo.each(me.FEATURE_KEYS, (keys) => {
			lo.each(keys, (feat) => {
				obj[feat] = ''
			})
		})
		api.update_setup_features(obj)
	}
	me.updateSetupFeatures = async (p) => {
		let xFields = []
		lo.each(p, (_, key) => xFields.push(key))
		let res = await api.update_setup_features({...p, _update_fields: xFields})
		if (res.error) return res

		setup_features = lo.get(res, 'body') || {}
		pubsub.publish('account', {object_type: 'setup_features'})
		return setup_features
	}
	me.matchSetupFeatures = () => setup_features

	me.isUserStageDisplayed = () => {
		return true
		const ACCOUNTIDS = ['acpxkgumifuoofoosble']
		return ACCOUNTIDS.includes(account_id)
	}

	me.getFeaturesProgess = (section) => {
		// section is empty mean all section
		let features = []
		lo.each(me.FEATURE_KEYS, (keys) => {
			lo.each(keys, (feat) => {
				features.push(feat)
			})
		})
		if (me.FEATURE_KEYS[section]) {
			features = me.FEATURE_KEYS[section]
		}

		let done = 0
		let total = lo.size(features)
		lo.each(setup_features, (value, key) => {
			if (lo.includes(features, key)) {
				if (value) done += 1
			}
		})

		let result = Math.round((done * 10_000) / total) / 100
		return result > 100 ? 100 : result
	}
	me.FEATURE_KEYS = {
		website: ['chat_window', 'bot_greeting', 'popup', 'subiz_live'],
		fb_and_insta: ['integrate_facebook', 'facebook_auto_setting'],
		zalo: ['integrate_zalo_oa'],
		call_center: ['integrate_call'],
		rule: ['add_agent', 'create_rule'],
	}

	me.preLoadSound = () => {
		const agent = lo.cloneDeep(me.me())
		const sound = lo.get(agent, 'dashboard_setting.web_notificaiton_sound', 'alert')
		const volume = lo.get(agent, 'dashboard_setting.web_notification_volume', 0)
		let audioFile
		const soundName = volume.toString() + '_' + sound
		switch (soundName) {
			case '0_alert':
				audioFile = require('../src/assets/media/alert.mp3')
				break
			case '0_message_noti_loud':
				audioFile = require('../src/assets/media/message_noti_loud.mp3')
				break
			case '0_doorbell':
				audioFile = require('../src/assets/media/doorbell.mp3')
				break
			case '0_ding-2':
				audioFile = require('../src/assets/media/ding-2.mp3')
				break
			case '0_ding-3':
				audioFile = require('../src/assets/media/ding-2.mp3')
				break
			case '100_alert':
				audioFile = require('../src/assets/media/alert_loud.mp3')
				break
			case '100_message_noti_loud':
				audioFile = require('../src/assets/media/message_noti_loud_2.mp3')
				break
			case '100_doorbell':
				audioFile = require('../src/assets/media/doorbell_loud.mp3')
				break
			case '100_ding-2':
				audioFile = require('../src/assets/media/ding-2_loud.mp3')
				break
			case '100_ding-3':
				audioFile = require('../src/assets/media/ding-3_loud.mp3')
				break
			default:
				break
		}
		return audioFile
	}
	me.tab_id = Math.random().toString(36).substring(2, 15)

	// global noti sound
	me.global_noti_sound = new Audio()
	me.global_noti_sound.src = me.preLoadSound()
	me.global_noti_sound.load()
	me.global_noti_sound.preload = 'auto'
	me.global_noti_sound.currentTime = 0

	me.updateNotiSound = () => {
		me.global_noti_sound.src = me.preLoadSound()
		me.global_noti_sound.load()
	}

	me.playNotificationSound = () => {
		let intv = setInterval(() => {
			if (dead) return clearInterval(intv)
			const sound_master = JSON.parse(window.myLocalStorage.getItem('sound_master'))
			if (!navigator.userActivation.hasBeenActive) return

			if (
				!sound_master ||
				Date.now() > sound_master.time_stamp ||
				(me.tab_id === sound_master.id && Date.now() < sound_master.time_stamp)
			) {
				window.myLocalStorage.setItem('sound_master', JSON.stringify({id: me.tab_id, time_stamp: Date.now() + 3000}))
			}
		}, 2000)

		let intv2 = setInterval(() => {
			if (dead) return clearInterval(intv2)
			const sound_master = JSON.parse(window.myLocalStorage.getItem('sound_master'))
			const play_request = JSON.parse(window.myLocalStorage.getItem('play_request'))
			const agent = lo.cloneDeep(me.me())
			const number_of_repeat = lo.get(agent, 'dashboard_setting.web_new_conversation_repeat_count', 0)

			if (!play_request || !sound_master) return

			if (document.hasFocus()) {
				window.myLocalStorage.setItem('play_request', null)
				return
			}

			if (sound_master.id !== me.tab_id) return

			if (play_request.should_repeat && number_of_repeat) {
				let count_play = play_request.count_repeat || 0

				if (Date.now() - play_request.last_play < 3000) return

				if (count_play < number_of_repeat) {
					console.log('play repeat')
					me.global_noti_sound.play().catch((e) => console.log('PLAYSOUND ERR', e))
					const new_play_request = {...play_request, count_repeat: count_play + 1, last_play: Date.now()}
					window.myLocalStorage.setItem('play_request', JSON.stringify(new_play_request))
					return
				}

				window.myLocalStorage.setItem('play_request', null)
				return
			}
			me.global_noti_sound.play().catch((e) => console.log('PLAYSOUND ERR', e))
			window.myLocalStorage.setItem('play_request', null)
			window.myLocalStorage.setItem('last_time_play_noti_sound', Date.now().toString())
		}, 1500)
	}

	me.detectUnLoadTab = () => {
		window.addEventListener('beforeunload', (event) => {
			event.returnValue = ''
			const sound_master = JSON.parse(window.myLocalStorage.getItem('sound_master'))
			if (sound_master && sound_master.id === me.tab_id) window.myLocalStorage.setItem('sound_master', null)
		})
	}

	// will delete and replace function in all files after release ticket features
	me.hasTicketFeature = () => {
		let subscription = me.matchSubscription()
		if (!subscription) return false
		return lo.get(subscription, 'use_ticket') > 0
	}
	me.hasKnowLedgeBaseFeature = () => {
		return lo.get(me.me(), 'account.id') === 'acpxkgumifuoofoosble'
	}

	me.setSkipTimeExpiredWarning = (time) => {
		window.myLocalStorage.setItem('skip_time_expired_warning', time)
	}

	me.getSkipTimeExpiredWarning = () => {
		return window.myLocalStorage.getItem('skip_time_expired_warning') || 0
	}

	me.setSkipExpModalTime = (time) => window.myLocalStorage.setItem('skip_expired_modal_time', time)
	me.getSkipExpModalTime = () => window.myLocalStorage.getItem('skip_expired_modal_time') || 0

	me.createBankTransferRequest = (p) => api.create_bank_transfer_request(p)
	return me
}

// handler: {
//  update:
//  create:
//  list:
//  onEvent: ev => {}
//}
function Entity(realtime, pubsub, kv, name, key, publishtopic) {
	if (!key) key = 'id'
	let syncStatus = 'offline' // 'online'
	let restinterval = 2000 // 60 sec

	if (!publishtopic) publishtopic = 'account'
	let fetchQueue = new flow.batch(2000, restinterval, async () => {
		if (!api.getAccountId()) return
		await this.list()
		return []
	})

	let updateTopic = name + '_updated'
	let createTopic = name + '_created'
	let removeTopic = name + '_deleted'
	realtime.onEvent((ev) => {
		if (ev.type != updateTopic && ev.type != createTopic && ev.type != removeTopic) return
		let obj = lo.get(ev, ['data', name])
		if (!obj) return

		if (ev.type == updateTopic || ev.type == createTopic) {
			return this.justUpdate(obj)
		}

		if (ev.type != removeTopic) {
			// just refetch
			return this.list(true)
		}
	})

	realtime.onInterrupted(() => {
		syncStatus = 'offline'
	})
	realtime.subscribe([updateTopic, removeTopic]) // ignore result

	this.match = () => {
		if (syncStatus !== 'online') fetchQueue.push()
		return kv.match(name) || {}
	}

	this.clearAnchor = async (obj) => {
		_anchor = ''
	}

	this.cleanMem = async (obj) => {
		let id = lo.get(obj, key)
		if (!id) return
		let old = kv.match(name) || {}
		delete old[id]
		await kv.put(name, old)
	}

	this.justUpdate = async (obj) => {
		let id = lo.get(obj, key)
		if (!id) return this.list()
		let old = kv.match(name) || {}
		old[id] = obj
		await kv.put(name, old)
		pubsub.publish(publishtopic, {object_type: name, data: obj})
		return obj
	}

	this.update = async (params, fields) => {
		let {body: obj, error: err} = await api['update_' + name](params, fields)
		if (err) return {error: err, body: obj}

		await this.justUpdate(obj)
		pubsub.publish('standard_object', {
			object_type: name,
			action: 'update',
			data: obj,
		})
		return lo.get(kv.match(name), lo.get(obj, key))
	}

	this.create = async (params) => {
		let {body: obj, error: err} = await api['create_' + name](params)
		if (err) return {error: err, body: obj}
		if (Array.isArray(obj)) return lo.map(obj, (o) => this.justUpdate(o))
		pubsub.publish('standard_object', {
			object_type: name,
			action: 'create',
			data: obj,
		})
		return {body: await this.justUpdate(obj)}
	}

	this.list = async (force) => {
		if (!api.getAccountId()) return
		// try subscribe first
		if (!force && syncStatus === 'online') return kv.match(name)
		let {error: suberr} = await realtime.subscribe([updateTopic, removeTopic])
		let {anchor, body, error} = await api['list_' + name]()
		if (error) return {error}

		_anchor = anchor
		let objM = {}
		lo.map(body, (obj) => {
			if (!obj || !obj[key]) return
			objM[obj[key]] = obj
		})
		let old = kv.match(name)
		await kv.put(name, objM)
		if (!lo.isEqual(old, objM)) pubsub.publish(publishtopic, {object_type: name})
		syncStatus = suberr ? 'offline' : 'online'
		return objM
	}

	let _anchor = ''
	this.fetchMore = async () => {
		let {body, error, anchor} = await api['list_' + name](_anchor)
		if (error) return {error}
		_anchor = anchor
		let old = lo.cloneDeep(kv.match(name)) || {}
		lo.map(body, (obj) => {
			if (!obj || !obj[key]) return
			old[obj[key]] = obj
		})
		await kv.put(name, old)
		pubsub.publish(publishtopic, {object_type: name})
		return lo.size(body)
	}

	this.remove = async (id) => {
		let {error: err, body, code} = await api['delete_' + name](id)
		if (err) return {error: err, code, body}

		let old = kv.match(name) || {}
		delete old[id]
		await kv.put(name, old)
		pubsub.publish(publishtopic, {object_type: name})
		pubsub.publish('standard_object', {
			object_type: name,
			action: 'delete',
			data: {id},
		})
		return {}
	}
}

function isGroupIdNormal(id = '') {
	return !id.startsWith('gr84')
}

export default NewAccountStore
