import store from '@sb/store'
var sb = require('@sb/util')
import {Time} from '@sb/com'
import EditTicketModal from '../ticket/edit_tickket_modal'
import {getTicketStateInfo, getTicketTypeName} from '../ticket/com'

const LIMIT = 100
const DISPLAY_COUNT = 5

export default {
	name: 'user_tickets',
	props: ['uid', 'cid'],
	data() {
		return {
			loading: false,

			isFull: false,
			total: 0,
			tickets: [],
			allUserIds: [],
		}
	},
	mounted() {
		this.initFetch()
		store.onTicket(this, (ticketM) => {
			let shouldUpdate = false
			lo.each(ticketM, (ticket) => {
				let uids = lo.get(ticket, 'users') || []
				uids = lo.map(uids, (user) => user.id)

				let hasTicketId = lo.find(this.tickets, (t) => t.id === ticket.id)
				let newTicketOfUser = lo.find(uids, (uid) => lo.includes(this.allUserIds, uid))

				if (!hasTicketId && newTicketOfUser) {
					shouldUpdate = true
					return false // break
				}
			})
			// display new ticket realtime
			if (shouldUpdate) this.fetchUserTickets()
		})
	},
	watch: {
		uid(uid) {
			this.initFetch()
		},
	},

	methods: {
		async initFetch() {
			await this.setAllUserIds()
			this.getUserTickets()
		},

		async setAllUserIds() {
			if (!this.uid) return
			let result = [this.uid]
			let secondaries = []
			let user = store.matchUser(this.uid, true)
			if (!user) {
				await store.fetchUser(this.uid)
				user = store.matchUser(this.uid, true) || {id: this.uid}
			}
			if (user.primary_id) {
				let primaryUser = store.matchUser(user.primary_id)
				secondaries = lo.get(primaryUser, 'secondaries') || []
				secondaries.push({id: user.primary_id})
			} else {
				secondaries = lo.get(user, 'secondaries') || []
			}
			lo.each(secondaries, (user) => {
				if (!lo.includes(result, user.id)) result.push(user.id)
			})
			this.allUserIds = result
		},

		getCondition() {
			return {
				condition: {
					all: [
						{
							key: 'users.id',
							type: 'text',
							text: {
								op: 'eq',
								eq: this.allUserIds,
							},
						},
					],
				},
				limit: LIMIT,
				order_by: 'smart',
			}
		},

		getUserTickets() {
			if (!this.uid) return
			const conditions = this.getCondition()
			let tickets = store.matchListTicket(conditions)
			let body = lo.get(tickets, 'body')
			if (!body) {
				this.fetchUserTickets()
				return
			}
			this.total = lo.get(body, 'total', 0)
			this.tickets = lo.get(body, 'tickets', [])
			this.nextAnchor = lo.get(body, 'next_anchor', '')
			// refetch ticket 
			setTimeout(() => {
				this.fetchUserTickets()
			}, 0)
		},

		async fetchUserTickets() {
			if (!this.uid) return
			if (!lo.size(this.allUserIds)) return
			this.loading = true
			const conditions = this.getCondition()

			const res = await store.listTickets(conditions)

			this.total = lo.get(res, 'body.total', 0)
			this.tickets = lo.get(res, 'body.tickets', [])
			this.nextAnchor = lo.get(res, 'body.next_anchor', '')
			this.loading = false
		},

		renderTicketsCid(tickets) {
			return lo.map(tickets, (ticket) => {
				let {type} = getTicketStateInfo(ticket)
				const ticketType = store.matchTicketType()[ticket.type_id]
				const ticketTypeName = getTicketTypeName(ticketType)

				let cls = ''

				let $priority =
					ticket.priority === 'urgent' ? (
						<Icon name='alert-square-filled' title={this.$t('urgent')} size='18' class='text__danger mr-2' />
					) : null

				if (ticket.state === 'closed') {
					cls = 'text__muted'
				}

				return (
					<div
						to={{name: 'ticket-detail', params: {id: ticket.id}}}
						target='_blank'
						class='user_ticket_row'
						vOn:click={() => this.$emit('ticketSelect', ticket.id)}
					>
						<div class='d-flex flex-row justify-content-between align-items-center' style='font-size: 14px;'>
							<div class='d-flex align-items-center'>
								<div class={`mr-2 square square__${type}`}></div>
								<div class={`text__${type}`} style='font-size: 14px; font-weight: 500;'>
									<span>
										{ticketTypeName} {'  /  '} {ticket.number}
									</span>
									<span class='ml-2'>{$priority}</span>
								</div>
							</div>
							<div class='text__muted'>
								<Time time={ticket.created} date_only />
							</div>
						</div>

						<div class={cls} style='font-size: 16px; font-weight: 500;'>
							{lo.trim(ticket.title) ? lo.trim(ticket.title) : <i class='text__muted'>{this.$t('untitled')}</i>}
						</div>
					</div>
				)
			})
		},

		renderMore() {
			if (this.total <= DISPLAY_COUNT) return

			return (
				<div class='mt-3'>
					<button type='button' class='btn btn__white btn__sm' vOn:click={this.toggleShowMore}>
						{this.isFull ? this.$t('see_less') : `${this.$t('more')} ${this.total - DISPLAY_COUNT}`}
					</button>
				</div>
			)
		},

		toggleShowMore() {
			this.isFull = !this.isFull
		},
	},
	render() {
		let tickets = this.tickets
		if (!this.isFull) {
			tickets = lo.take(tickets, DISPLAY_COUNT)
		}
		let $loading = null

		if (this.total)
			$loading = (
				<div class='text__muted ml-3' style='color: #adb5bd;'>
					{this.total}
				</div>
			)
		if (this.loading) $loading = <Spinner static class='ml-2 mb-1' mode='dark' />
		return (
			<div class='user_tickets'>
				<div class='user_profile__head'>
					<div class='profile_section_title' style='display: flex; align-items: center;' >
						{this.$t('ticket')} {$loading}
					</div>
					<div style='flex: 1'></div>
					<div
						class='link action link__primary d-flex align-items-center'
						vOn:click={() => this.$root.$emit('open_edit_ticket_modal', this.cid, this.uid)}
					>
						{this.$t('create')}&nbsp;
						<Icon name='file-plus' size='18' stroke-width='1.5' />
					</div>
				</div>
				{lo.size(tickets) > 0 && (
					<div class='ml-4 mr-4 mt-3'>
						{this.renderTicketsCid(tickets)}
						{this.renderMore()}
					</div>
				)}
			</div>
		)
	},
}
