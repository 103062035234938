import store from '@sb/store'
import sb from '@sb/util'
import {changeLanguage} from './languages.js'

import VueRouter from 'vue-router'
import MainLayout from './main_layout.js'
import AccmgrLayout from './accmgr/accmgrlayout.js'
// import Vue from 'vue'
Vue.use(VueRouter)

const ZaloList = () => import('./settings/zalo.js')
const Endchat = () => import('./settings/endchat.js')
const Label = () => import('./settings/label.js')
const LeadSetting = () => import('./settings/leadsetting.js')
const ListTemplate = () => import('./profile/message-template/listTemplate.js')

const newreports = {
	path: '/new-reports',
	component: () => import('./reports/report.js'),
	meta: {menus: ['reports']},
	name: 'newreport',
	children: [
		{path: 'call', name: 'newreports.call', component: () => import('./reports/report_call.js')},
		{
			path: 'call-list',
			name: 'newreports.call-list',
			component: () => import('./reports/report_call_list.js'),
			children: [{path: 'me', name: 'newreports.my-call-list'}],
		},
		{path: 'convo', name: 'newreports.convo', component: () => import('./reports/report_convo.js')},
		{
			path: 'convo-list',
			name: 'newreports.convo-list',
			component: () => import('./reports/report_convo_list.js'),
			children: [{path: 'me', name: 'newreports.my-convo-list'}],
		},
		{
			path: 'sales',
			name: 'newreports.sale',
			component: () => import('./reports/report_sale'),
			children: [{path: 'me', name: 'newreports.my-sales'}],
		},
		{
			path: 'agent',
			name: 'newreports.agent',
			component: () => import('./reports/report_agent.js'),
			children: [{path: 'me', name: 'newreports.me'}],
		},
		{path: 'user', name: 'newreports.user', component: () => import('./reports/report_new.js')},
		{
			path: 'ticket/volume',
			name: 'newreports.ticket.volume',
			component: () => import('./reports/report_ticket_volume.js'),
		},
		{
			path: 'ticket/resolve',
			name: 'newreports.ticket.resolve',
			component: () => import('./reports/report_ticket_resolve.js'),
		},
		{
			path: 'ticket/satisfaction',
			name: 'newreports.ticket.satisfaction',
			component: () => import('./reports/report_ticket_satisfaction.js'),
		},
		{path: 'ticket/sla', name: 'newreports.ticket.sla', component: () => import('./reports/report_ticket_sla.js')},
		{
			path: 'ticket/sla-violation-list',
			name: 'newreports.ticket.sla-violation-list',
			component: () => import('./reports/report_ticket_sla_violation_list.js'),
		},
		{
			path: 'ticket/rating-list',
			name: 'newreports.ticket.rating-list',
			component: () => import('./reports/report_ticket_rating_list.js'),
		},
		{
			path: 'ticket/agent-performance',
			name: 'newreports.ticket.agent-performance',
			component: () => import('./reports/report_ticket_agent.js'),
		},
	],
}

const settings = {
	path: '/settings',
	component: () => import('./settings/setting_layout.js'),
	children: [
		{path: '/', name: 'settings.general', component: () => import('./settings/general.js')},
		{path: 'payment', name: 'settings.payment', component: () => import('./settings/payment.js')},
		{
			path: 'subscription-info',
			name: 'settings.subscription-info',
			component: () => import('./settings/payment-info.js'),
		},
		{
			path: 'subiz-payment-methods',
			name: 'settings.subiz-payment-methods',
			component: () => import('./settings/subiz-payment-methods.js'),
		},
		{
			path: 'marketing-credit',
			name: 'settings.marketing-credit',
			component: () => import('./settings/marketing_credits.js'),
		},
		{
			path: 'message-template',
			name: 'settings.message-template',
			component: () => import('./profile/message-template/listTemplate.js'),
		},
		{
			path: 'business-hours',
			name: 'settings.business-hours',
			component: () => import('./settings/business-hours/BusinessHours.vue'),
		},
		{path: 'agents', name: 'settings.agents', component: () => import('./settings/agent/agent.js')},
		{
			path: 'agents-group',
			name: 'settings.agents-group',
			component: () => import('./settings/agent/group.js'),
			children: [{path: '/settings/agent-group/new', name: 'settings.agents-group-add'}],
		},
		{
			path: 'blacklist-ips',
			name: 'settings.blacklist-ips',
			component: () => import('./settings/blacklist.js'),
			children: [
				{path: 'blacklist-ips/new', name: 'add-blacklist-ip'},
				{path: 'email', name: 'settings.blacklist-email'},
				{path: 'phone', name: 'settings.blacklist-phone'},
				{path: 'bounced-email', name: 'settings.blacklist-bounced-email'},
			],
		},
		{path: 'rule-setting', name: 'settings.rule-setting', component: () => import('./settings/rule/listRule.js')},
		{path: 'add-rule', name: 'settings.add-rule', component: () => import('./settings/rule/detailRule.js')},
		{path: 'email', name: 'settings.email', component: () => import('./settings/email/emails.js')},
		{path: 'email-add', name: 'settings.email-add', component: () => import('./settings/email/emails.js')},
		{path: 'email-dkim', name: 'settings.email-dkim', component: () => import('./settings/email/emails.js')},
		{path: 'dkim-add', name: 'settings.dkim-add', component: () => import('./settings/email/emails.js')},
		{path: 'add-email', name: 'settings.add-email', component: () => import('./settings/email/emails.js')},
		{
			path: 'website',
			name: 'settings.website',
			component: () => import('./settings/website.js'),
			children: [{path: '/website/install', name: 'site-install'}],
		},
		{path: 'messenger', name: 'settings.messenger', component: () => import('./settings/facebook.js')},
		{path: 'instagram', name: 'settings.instagram', component: () => import('./settings/instagram.js')},
		{path: 'zalo', name: 'settings.zalo', component: ZaloList},
		{path: 'google-review', name: 'settings.google', component: () => import('./settings/google_review.js')},
		{path: 'facebook-comment', name: 'settings.facebook-comment', component: () => import('./settings/facebook.js')},
		{path: 'auto-endchat', name: 'settings.auto-endchat', component: Endchat},
		{
			path: 'label',
			name: 'settings.label',
			component: Label,
			children: [{path: '/settings/label/new', name: 'add-label'}],
		},
		{path: 'lead', name: 'settings.lead', component: LeadSetting},
		{
			path: '/language',
			component: () => import('./settings/language.js'),
			children: [
				{path: '/', name: 'settings.language', component: () => import('./settings/language.js')},
				{path: 'translate', name: 'settings.language.translate', component: () => import('./settings/language.js')},
			],
		},

		{path: 'translation_def', name: 'settings.translation_def', component: () => import('./settings/language_def.js')},
		{
			path: 'tags',
			name: 'settings.tags',
			component: () => import('./settings/tags.js'),
			children: [{path: '/settings/tags/new', name: 'add-tag'}],
		},
		{
			path: 'order-tags',
			name: 'settings.order-tags',
			component: () => import('./settings/order_tags'),
			children: [
				{path: '/settings/order-tags/new', name: 'add-order-tag'},
				{path: '/settings/order-tags/edit/:id', name: 'edit-order-tag'},
			],
		},
		{
			path: 'bank-accounts',
			name: 'settings.bank-accounts',
			component: () => import('./settings/bank-accounts'),
		},
		{
			path: 'user-attributes',
			name: 'settings.user-attributes',
			component: () => import('./settings/user-attribute/list.js'),
			children: [
				{path: 'new', name: 'add-attr'},
				{path: 'edit/:key', name: 'edit-attr'},
			],
		},
		{
			path: 'display-attributes',
			name: 'settings.display-attributes',
			component: () => import('./settings/custom_user_attributes.js'),
		},
		{
			path: 'webhooks',
			name: 'settings.webhook',
			component: () => import('./settings/webhook/list_webhooks.js'),
		},
		{
			path: 'webhooks/:webhookid',
			name: 'settings.webhook-detail',
			component: () => import('./settings/webhook/detail_webhook.js'),
		},

		{
			path: 'woocommerce',
			name: 'settings.woocommerce',
			component: () => import('./settings/woocommerce/list_woo.js'),
		},
		{
			path: 'woocommerce/:inteid',
			name: 'settings.woo-detail',
			component: () => import('./settings/woocommerce/detail_woo.js'),
		},

		{path: 'web-monitor', name: 'settings.web-monitor-list', component: () => import('./settings/web_monitor/list.js')},
		{
			path: 'web-monitor/:id/report',
			name: 'settings.web-monitor-report',
			component: () => import('./settings/web_monitor/report'),
		},
		{path: 'payment-methods', name: 'payment-method-list', component: () => import('./order/payment_methods')},
		{path: 'shipping-settings', name: 'shipping-settings', component: () => import('./order/shipping-settings.js')},

		{path: 'pos', name: 'pos-list', component: () => import('./product/pos.js')},
		{path: 'taxes', name: 'tax-list', component: () => import('./product/taxes.js')},
		{
			path: 'cancellation-codes',
			name: 'cancellation-codes',
			component: () => import('./settings/cancellation_code.js'),
		},
		{path: 'currencies', name: 'currency-list', component: () => import('./product/currencies.js')},
		{path: 'pipelines', name: 'pipeline-list', component: () => import('./pipeline/pipeline_list.js')},
		{path: 'pipeline-new', name: 'pipeline-new', component: () => import('./pipeline/pipeline_new.js')},
		{path: 'pipeline/:id/edit', name: 'pipeline-edit', component: () => import('./pipeline/pipeline_edit.js')},
		{
			path: 'pipeline/:pid/stage/:sid/edit',
			name: 'pipeline-stage-edit',
			component: () => import('./pipeline/pipeline_stage_edit.js'),
		},
		{
			path: 'conversation-modal',
			name: 'settings.conversation-modal',
			component: () => import('./settings/conversation_modals.js'),
		},
		{
			path: 'call-center',
			name: 'settings.call-center',
			component: () => import('./settings/call-center/call-center.js'),
		},
		{
			path: 'ticket-type/:id/satisfaction',
			name: 'settings.ticket-type-satisfaction',
			component: () => import('./settings/ticket/satisfaction.js'),
		},
		{
			path: 'ticket-type/:id/auto-reply',
			name: 'settings.ticket-type-auto-reply',
			component: () => import('./settings/ticket/auto_reply.js'),
		},
		{
			path: 'ticket-type/:id/sla',
			name: 'settings.ticket-type-sla',
			component: () => import('./settings/ticket/ticket_sla_list.js'),
		},
		{
			path: 'ticket-type/:id/email',
			name: 'settings.ticket-type-email',
			component: () => import('./settings/ticket/ticket_email.js'),
		},
		{
			path: 'ticket-type/:id/custom-attributes',
			name: 'settings.ticket-type-custom-attr',
			component: () => import('./settings/ticket/ticket_custom_attrs.js'),
		},
		{
			path: 'ticket-type/:id/general',
			name: 'settings.ticket-type-general',
			component: () => import('./settings/ticket/ticket_general.js'),
		},
		{
			path: 'ticket-type/:id/templates',
			name: 'settings.ticket-type-template',
			component: () => import('./settings/ticket/ticket_template.js'),
		},
		{
			path: 'ticket-type/:id/rules',
			name: 'settings.ticket-type-assignment-rule',
			component: () => import('./settings/ticket/ticket_rules.js'),
		},
		{
			path: 'ticket-type/:id/rules/:ruleid',
			name: 'settings.ticket-type-assignment-rule-detail',
			component: () => import('./settings/ticket/ticket_rule_detail.js'),
		},
		{
			path: 'ticket-type/new',
			name: 'settings.ticket-type-new',
			component: () => import('./settings/ticket/ticket_new.js'),
		},
	],
}

const profile = {
	path: '/profile',
	component: () => import('./profile/layout.js'),
	children: [
		{path: '/', name: 'profile.self', component: () => import('./profile/self.js')},
		{path: '/security', name: 'profile.security', component: () => import('./profile/security.js')},
		{path: '/apikey', name: 'profile.apikey', component: () => import('./profile/apikey.js')},
		{
			path: '/profile/setting-notification',
			name: 'profile.setting-notification',
			component: () => import('./profile/notification.js'),
		},
		{
			path: '/profile/referral',
			name: 'profile.referral',
			component: () => import('./profile/referral.js'),
		},
		{
			path: '/message-template',
			name: 'message-template',
			component: () => import('./profile/message-template/listTemplate.js'),
		},
	],
}

const knowledgebase = {
	path: '/knowledgebase',
	name: 'knowledge_base',
	component: () => import('./knowledge_base/knowledge_base_layout.js'),
	children: [
		{
			path: '/',
			name: 'knowledgebase.self',
			component: () => import('./knowledge_base/knowledge_base.js'),
		},
		{
			path: 'detail',
			name: 'knowledgebase.detail',
			component: () => import('./knowledge_base/knowledge_base_detail.js'),
		},
	],
}

const routes = [
	{path: '/thanh', name: 'thanh', component: () => import('./thanh.js')},
	{path: '/duc', name: 'duc', component: () => import('./duc.js')},
	{
		path: '/welcome',
		component: () => import('./onboarding/onboarding.js'),
		children: [
			{path: '/', name: 'onboarding', component: () => import('./onboarding/onboarding.js')},
			{path: '/welcome/chatbox', name: 'onboarding.chatbox', component: () => import('./onboarding/onboarding.js')},
			{
				path: '/welcome/chatbox_install',
				name: 'onboarding.chatbox_install',
				component: () => import('./onboarding/onboarding.js'),
			},
			{path: '/chatbox_domain', name: 'onboarding.chatbox_domain', component: () => import('./live/live.js')},
			{path: '/chatbox_code', name: 'onboarding.chatbox_code', component: () => import('./live/live.js')},
			{path: '/zalo', name: 'onboarding.zalo', component: () => import('./live/live.js')},
			{path: '/messenger', name: 'onboarding.messenger', component: () => import('./live/live.js')},
		],
	},
	{
		path: '/activities/:uid?',
		redirect: {name: 'activities'}, // redirect old url
		children: [{path: 'convo/:cid', redirect: (to) => ({name: 'activities', query: to.params})}],
	},
	{
		path: '/',
		// move logic redirect to mainLayout because router was loaded imidiately and cannot handle logic after store.init
		//redirect: {name: 'activities'},
		name: 'main-layout',
		component: MainLayout,
		children: [
			{
				path: '/subscriptions',
				name: 'old-subscription',
				component: () => import('./trial-subscription/subscriptions.js'),
			},
			{path: '/onboarding2', name: 'onboarding2', component: () => import('./onboarding/onboarding2.js')},
			{
				path: '/zns-template/new',
				name: 'settings.zns-template-new',
				component: () => import('./settings/zns-template/new.js'),
			},
			{
				path: '/acc',
				name: 'account-manager',
				component: AccmgrLayout,
				children: [
					{
						path: '/acc/mgr',
						name: 'accmgr',
						component: () => import('./accmgr/accmgr.js'),
					},
					{path: '/acc/ref', name: 'refmgr', component: () => import('./accmgr/referrer.js')},
					{path: '/acc/promo', name: 'promomgr', component: () => import('./accmgr/programs.js')},
				],
			},
			{
				path: '/accmgr',
				component: () => import('./accmgr/accmgr.js'),
				children: [{path: '/', name: 'accmgr', component: () => import('./accmgr/accmgr.js')}],
			},
			{
				path: '/live',
				component: () => import('./live/live.js'),
				children: [
					{path: '/', name: 'liveoverview', component: () => import('./live/live.js')},
					{path: 'view', name: 'liveview', component: () => import('./live/live.js')},
				],
			},
			{path: 'convo', name: 'activities', component: () => import('./activities/activities.js')},
			{path: 'lead2', name: 'lead2', redirect: {name: 'lead'}},
			{path: 'lead', name: 'lead', component: () => import('./lead/leads.js')},
			{path: 'segment', name: 'segment', component: () => import('./lead/segment.js')},
			{
				path: 'campaign-list',
				name: 'campaign-list',
				component: () => import('./campaign/campaign_list.js'),
				children: [
					{
						path: 'add-campaign',
						name: 'add-campaign',
					},
				],
			},
			{
				path: 'telesale-list',
				name: 'telesale-list',
				component: () => import('./campaign/telesale_list.js'),
			},
			{
				path: 'campaign/:id/report',
				name: 'edit-campaign-report',
				component: () => import('./campaign/campaign_report.js'),
			},
			{
				path: 'telesale/:id/report',
				name: 'telesale-campaign-report',
				component: () => import('./campaign/telesale_report.js'),
			},
			{
				path: 'telesale-campaign/:id/execute',
				name: 'telesale-campaign-execute',
				component: () => import('./campaign/telesale_campaign_execute.js'),
			},
			{path: 'web_plugin', name: 'web_plugin', component: () => import('./web_plugins/web_plugins.js')},
			{
				path: 'web-plugin-template',
				name: 'web-plugin-template',
				component: () => import('./web_plugins/web_plugins.js'),
			},
			{path: 'web_plugin/create', name: 'web-plugin-create', component: () => import('./web_plugins/wp_create.js')},
			{
				path: 'chatbox',
				name: 'chatbox',
				component: () => import('./web_plugins/chatbox/chatbox.js'),
				children: [
					{path: 'design', name: 'chatbox-design'},
					{path: 'condition', name: 'chatbox-condition'},
				],
			},
			{
				path: 'web_plugin/popup/:id',
				name: 'popup',
				component: () => import('./web_plugins/popup/popup.js'),
				children: [
					{path: 'design', name: 'popup-design'},
					{path: 'design-2', name: 'popup-design-2'},
					{path: 'condition', name: 'popup-condition'},
					{path: 'report', name: 'popup-report'},
					{path: 'meta', name: 'popup-meta'},
				],
			},
			{
				path: 'web_plugin/notification/:id',
				name: 'notification',
				component: () => import('./web_plugins/notification/notification.js'),
				children: [
					{path: 'design', name: 'notif-design'},
					{path: 'condition', name: 'notif-condition'},
					{path: 'report', name: 'notif-report'},
					{path: 'meta', name: 'notif-meta'},
				],
			},
			{
				path: 'bot/:id',
				name: 'bot',
				component: () => import('./bot/bot.js'),
				children: [
					{path: 'build', name: 'bot-build'},
					{path: 'target', name: 'bot-target'},
					{path: 'setting', name: 'bot-setting'},
					{path: 'report', name: 'bot-report'},
					{path: 'template/edit', name: 'bot-template-edit'},
					{path: 'conditions', name: 'bot-condition'},
				],
			},
			{path: 'bots', name: 'bot-list', component: () => import('./bot/bot_list.js')},
			{path: 'bot-not-permission', name: 'bot-permission', component: () => import('./bot/bot_permission.js')},
			{path: 'bot-clone', name: 'bot-clone', component: () => import('./bot/bot_clone')},
			{path: 'bot-blank-clone', name: 'bot-blank-clone', component: () => import('./bot/bot_clone_blank')},
			settings,
			{
				path: 'products',
				name: 'product-list',
				component: () => import('./product/product_list'),
			},
			{
				path: 'product-collections',
				name: 'product-collection-list',
				component: () => import('./product/product_collection_list'),
			},
			{path: 'orders', name: 'order-list', component: () => import('./order/order_list.js')},
			{
				path: 'tickets',
				name: 'ticket-list',
				component: () => import('./ticket/ticket_list.js'),
			},
			{
				path: 'tickets/:id',
				name: 'ticket-detail',
				component: () => import('./ticket/ticket_list.js'),
			},
			profile,
			newreports,
			knowledgebase,
			{
				path: 'trial-invoice',
				name: 'trial.invoice-detail',
				component: () => import('./trial-subscription/draft-invoice.js'),
			},
			{
				path: 'trial-payment',
				name: 'trial.invoice-payment',
				component: () => import('./trial-subscription/payment.js'),
			},
			{
				path: 'trial-subscriptions',
				name: 'trial.subscriptions',
				component: () => import('./trial-subscription/subscriptions.js'),
			},
			{
				path: 'request-support',
				name: 'trial.request_support',
				component: () => import('./trial-subscription/request_advice_subs.js'),
			},
			{
				path: 'request-support-success',
				name: 'trial.request_support-success',
				component: () => import('./trial-subscription/request_support_successfully.js'),
			},
			{path: '/invoices', redirect: {name: 'settings.subscription-info'}},
			{
				path: 'ai-agents',
				name: 'settings.ai-agent-list',
				component: () => import('./settings/ai-agent/list.js'),
			},
			{
				path: 'ai-data-entries',
				name: 'settings.ai-data-entry-list',
				component: () => import('./settings/ai-agent/data-entries.js'),
			},
			{
				path: 'ai-qna-suggestions',
				name: 'settings.ai-data-suggestions',
				component: () => import('./settings/ai-agent/list-suggestions.js'),
			},
			{
				path: 'automations',
				name: 'settings.automation-list',
				component: () => import('./settings/automation/list.js'),
			},
			{
				path: 'automations/new',
				name: 'settings.automation-new',
				component: () => import('./settings/automation/detail_wrapper.js'),
			},
			{
				path: 'automations/:id/report',
				name: 'settings.automation-report',
				component: () => import('./settings/automation/detail_wrapper.js'),
			},
			{
				path: 'automations/:id/settings',
				name: 'settings.automation-settings',
				component: () => import('./settings/automation/detail_wrapper.js'),
			},
			{
				path: 'automations/:id/flow',
				name: 'settings.automation-flow',
				component: () => import('./settings/automation/detail_wrapper.js'),
			},
			{
				path: 'automations/:id',
				name: 'settings.automation-edit',
				component: () => import('./settings/automation/detail_wrapper.js'),
			},
			//{path: 'products', name: 'product-list', component: () => import('./product/product_list.js')},
			//{path: '/product/new', name: 'product-new', component: () => import('./product/product_new.js')},
			//{path: '/products/:id', name: 'product-edit', component: () => import('./product/product_edit.js')},
		],
	},
	{
		path: '/agent',
		name: 'account',
		redirect: {name: 'account_profile.self'},
		component: () => import('./account_layout.js'),
		children: [
			{path: '/apikey', name: 'account_profile.apikey', component: () => import('./profile/apikey.js')},
			{
				path: '/',
				name: 'account_profile.self',
				component: () => import('./profile/self.js'),
				redirect: {name: 'account_profile.accounts'},
			},
			{path: 'security', name: 'account_profile.security', component: () => import('./profile/security.js')},
			{path: 'accounts', name: 'account_profile.accounts', component: () => import('./profile/accounts.js')},
			{
				path: 'referral',
				name: 'account_profile.referral',
				component: () => import('./profile/referral.js'),
			},
		],
	},
	{path: '/logout', name: 'logout', component: () => import('./signin.js')},
	{path: '/login', name: 'login', component: () => import('./signin.js')},
	{path: '/signin', name: 'signin', component: () => import('./signin.js')},
	{path: '/register', name: 'register', component: () => import('./signin.js')},
	{path: '/forgot', name: 'forgot', component: () => import('./signin.js')},
	{path: '/recover-password', name: 'recover-password', component: () => import('./RecoverPassword.js')},

	{
		path: '/invite/:invite_id',
		name: 'invite',
		component: () => import('./invite_agent.js'),
	},
	{path: '/error', name: 'error', component: () => import('./error.js')},
	{path: '/testbot', name: 'testbot', component: () => import('./testbot.js')},
	{path: '/testclass', name: 'testclass', component: () => import('./testclass.js')},
	{path: '/delete-account', name: 'delete-account', component: () => import('./deleteAccount.js')},
	{path: '/privacy-policy', name: 'privacy-policy', component: () => import('./privacy_policy.js')},
	{
		path: '/terms-of-service',
		name: 'terms-of-service',
		component: () => import('./terms_of_service.js'),
	},
	{path: '*', name: 'not-found', component: () => import('./404.js')},
]

export const router = new VueRouter({
	base: window.subiz_route_base,
	routes,
	mode: window.subiz_route_hash === true ? undefined : 'history',
})

const ANONYMOUSLY = [
	'login',
	'logout',
	'register',
	'register-success',
	'forgot',
	'forgot-success',
	'recover-password',
	'active-account',
	'bot-template-list',
	'bot-template-detail',
	'invite',
	'not-found',
	'signin',
	'error',
	'delete-account',
	'terms-of-service',
	'privacy-policy',
]

// keep lang query accross all routes
router.beforeEach((to, from, next) => {
	if (to.path === from.path) return next()
	if (from.query.lang && to.query.lang) return next()
	if (from.query.lang) return next({path: to.path, query: {lang: from.query.lang}})
	next()
})

router.beforeEach((to, from, next) => {
	if (location.hostname == 'app.subiz.com.vn') {
		// remember dont return in this or cause blank screen
		Array.isArray(window._fbq) && window._fbq.push(['track', 'PixelInitialized', {url: to.path}])
		Array.isArray(window.dataLayer) && window.dataLayer.push({event: 'pageview', page: {path: to.path}})
		if (window.ga) {
			console.log('send ga route change', to.path)
			window.ga('set', 'page', to.path)
			window.ga('send', 'pageview')
		}
	}

	if (
		sb.mobilecheck() &&
		!['error', 'not-found', 'register', 'login', 'logout', 'forgot', 'delete-account'].includes(to.name)
	)
		next({name: 'error'})

	// Dont know why have to change language each time router pass. Temporary comment this logic
	let me = store.me()
	//changeLanguage(me.lang)

	// if (!to.matched.length) return next({name: 'main-layout', }) // https://github.com/vuejs/vue-router/issues/977

	let publicPath = to.matched.some((m) => ANONYMOUSLY.indexOf(m.name) >= 0)
	if (publicPath) return next()
	if (me && me.id) {
		// already login

		// but is owner and havenot complete onboarding
		// if (!to.name.startsWith('onboarding')) return next({name: 'onboarding', query: {redirect: to.fullPath}})

		return next()
	}

	// must redirect to login if the agent is not logged in
	return next({name: 'login', query: {redirect: to.fullPath}})
})

router.beforeEach((to, from, next) => {
	if (store.beginLoadRoute) store.beginLoadRoute()
	next()
})

router.afterEach(() => store.endLoadRoute && store.endLoadRoute())
