import store from '@sb/store'
import sb from '@sb/util'
import {formatDistanceStrict, format} from 'date-fns'
import {getDateFnsLocale} from '../languages.js'
import UserProfile from '../activities/user_profile.js'
import Conversation from '../activities/conversation/conversation.js'
import FacebookPrivateReply from '../activities/facebook_private_reply.js'
import Histories from './ticket_detail_histories.js'

const unassignedAvatar = require('../assets/img/unassigned.png')
import {getTicketTypeName, getTicketStateInfo, canViewTicket, displayOverdueText} from './com.js'
import LexicalEditor from '../commons/lexical-editor.js'
import UserDisplayName from '../commons/user_display_name.js'
import DueDateDropdown from './due_date_dropdown.js'
import TicketSLAModal from './ticket_sla_modal.js'
import AddTicketUserModal from './search_customer_modal.js'
import {computeErrorMessage} from '../commons/compute_error_message.js'

export default {
	name: 'ticket-detail-component',
	props: ['ticket_id', 'inconvo'],
	data() {
		return {
			loading: true,
			error: null,
			softLoading: false,
			tab: 'info', // info, user, convo
			relatedConvos: [], // use to render convotab dropdown, make sure fethc data of convo for render
			selectedUserId: '',
			selectedConvoId: '',
			// desc and title
			initNote: {
				format: 'html',
			},
			title: '',
			attrs: [],

			// members edit
			members: [],
			isMemberModalOpen: false,
			memberKeyword: '',
			loadingInviteAgents: [],

			// sla modal
			isSLAModalOpened: false,

			// new convo
			isShowFacebookPrivateReply: false,

			isAddTicketCustomerModalOpen: false,
			emailSending: false,
		}
	},

	watch: {
		ticket_id() {
			this.loadData()
		},
	},

	mounted() {
		this.loadData()
		this.loopReadTicket()
		store.onTicket(this, async (ticketM) => {
			if (!this.trueTicketId) return
			if (!ticketM) return
			if (ticketM && !ticketM[this.trueTicketId]) return

			let ticket = ticketM[this.trueTicketId]
			// try to realtime update tile and description if current agent are not editing
			if (ticket && lo.get(ticket, 'title') !== this.title && !this.isTitleInputFocused) {
				this.title = ticket.title
			}
			let isDescChanged = !lo.isEqual(lo.get(ticket, 'description'), this.initNote)
			if (ticket && isDescChanged && !this.isDescInputFocused) {
				this.initNote = lo.cloneDeep(lo.get(ticket, 'description') || {format: 'html'})
				await this.$nextTick()
				this.$refs.note_input && this.$refs.note_input.setInitialContent()
			}

			this.$forceUpdate()
		})
		this.seeingInternal = setInterval(() => {
			// let isbottom = this.distanceBottom < 100
			// if (isbottom && document.hasFocus() && !this.hidden) store.markReadConvo(this.cid)
			store.markSeeingConvo(this.trueTicketId)
		}, 10000)

		this.$root.$on('ticketEmailSuccess', this.onEmailSuccess)
	},

	destroyed() {
		store.markSeeingConvo('')
		if (this.seeingInternal) clearInterval(this.seeingInternal)
		if (this.intervalReadTicketId) clearInterval(this.intervalReadTicketId)
		this.$root.$off('ticketEmailSuccess', this.onEmailSuccess)
	},

	methods: {
		onEmailSuccess(ticketid, cid) {
			if (ticketid !== this.trueTicketId) return
			this.selectTabConvo(cid)
		},

		renderHeader(ticket) {
			let type = store.matchTicketType()[ticket.type_id] || {}
			let $bell = null
			let members = lo.get(ticket, 'read_receipts') || []
			let member = lo.find(members, (mem) => mem.id === store.me().id)
			if (lo.get(member, 'is_subscribed') === 'off') {
				$bell = <Icon name='bell-off' class='text__muted ml-3 text__secondary' size='16' title={this.$t('muted')} />
			}

			if (this.inconvo) {
				let {text, type} = getTicketStateInfo(ticket)
				if (ticket.state === 'open') {
					text = this.$t('open')
				}
				let $status = (
					<div class={{'d-flex': true, 'align-items-center': true}}>
						<div class={`dot dot__lg dot__${type}`} style='margin-top: 0' />
						<span class='ml-2'>{text}</span>
					</div>
				)

				return (
					<div class='ticket_detail_page_left_inconvoheader'>
						<div>
							<div>
								<span class='text__lg text__semibold'>
									{this.$t('ticket')}&nbsp;{getTicketTypeName(type, '')}
								</span>
								<span
									class='text__lg text__uppercase text__semibold'
									vOn:click={() => sb.copyToClipboard(this.trueTicketId)}
								>
									#{ticket.number}
								</span>
								{$bell}
							</div>
							<div>{$status}</div>
						</div>
						<div style='flex: 1'></div>

						{this.renderHeaderMoreActionsButton(ticket)}
					</div>
				)
			}
			return (
				<div class='ticket_detail_page_left_header'>
					<div class='btn btn__sm btn__white mr-3' vOn:click={() => this.$emit('back')}>
						<Icon name='chevron-left' size='16' class='mr-2' stroke-width='2' style='margin-top: -2px' />
						{this.$t('back')}
					</div>
					<div class='text__secondary text__semibold' vOn:click={() => this.$emit('back')}>
						{getTicketTypeName(type)}
					</div>

					<Icon name='chevron-right' size='16' class='ml-2 mr-2 text__secondary' stroke-width='2' />
					<div class='text__uppercase text__semibold' vOn:click={() => sb.copyToClipboard(this.trueTicketId)}>
						{ticket.number}
					</div>
					{$bell}
					{ticket.deleted > 0 && (
						<div class='ml-4 ticket_detail_delete_notif text__xs'>
							{this.$t('moved_to_trash')} <Time time={ticket.deleted} ago suffix />.{' '}
							<a href='javascript:;' vOn:click={this.restoreTicket}>
								{this.$t('restore')}
							</a>
						</div>
					)}
					<div style='flex: 1'></div>

					{this.renderHeaderMoreActionsButton(ticket)}
				</div>
			)
		},

		async onSelectSubscribeOption(v) {
			let res
			if (v === 'off') {
				res = await store.unsubscribeTicket([this.trueTicketId])
			} else {
				res = await store.subscribeTicket([this.trueTicketId])
			}
			if (lo.get(res, 'error')) {
				this.$showError(res.error)
			} else {
				this.$showSuccess(this.$t('success'))
			}
		},

		renderHeaderMoreActionsButton(ticket) {
			let moreItems = [
				{
					id: ticket.deleted ? 'restore_ticket' : 'delete_ticket',
					label: ticket.deleted ? this.$t('restore') : this.$t('delete_ticket'),
					icon: (
						<Icon
							style='position: relative; top: -2px;'
							size='18'
							name={ticket.deleted ? 'restore' : 'trash'}
							class='text__muted'
						/>
					),
				},
			]

			let members = lo.get(ticket, 'read_receipts') || []
			let member = lo.find(members, (mem) => mem.id === store.me().id)
			if (lo.get(member, 'is_subscribed') === 'off') {
				moreItems.push({
					id: 'unmute_ticket',
					label: this.$t('unmute'),
					icon: <Icon style='position: relative; top: -2px;' size='18' name='bell' class='text__muted' />,
				})
			} else {
				moreItems.push({
					id: 'mute_ticket',
					label: this.$t('mute'),
					icon: <Icon style='position: relative; top: -2px;' size='18' name='bell-off' class='text__muted' />,
				})
			}

			return (
				<Dropdown
					dropdown_width={200}
					items={moreItems}
					mode='custom'
					right={this.inconvo}
					vOn:select={(item) => this.onSelectMoreAction(item.id, ticket)}
				>
					<div
						class='btn btn__sm btn__white align-items-center justify-content-center'
						style='display: inline-flex; height: 32px'
					>
						<Icon name='dots' size='16' stroke-width='2' />
					</div>
				</Dropdown>
			)
		},

		onSelectMoreAction(action, ticket) {
			if (action === 'toggle_follow') {
				this.toggleAgentFollow(store.me().id)
				return
			}
			if (action === 'delete_ticket') {
				this.deleteTicket()
				return
			}
			if (action === 'restore_ticket') {
				this.restoreTicket()
				return
			}
			if (action === 'mute_ticket') {
				this.onSelectSubscribeOption('off')
				return
			}
			if (action === 'unmute_ticket') {
				this.onSelectSubscribeOption('')
				return
			}
		},

		loopReadTicket() {
			this.intervalReadTicketId = setInterval(this.readTicket, 60_000)
		},

		readTicket() {
			if ((this.ticket_id || '').startsWith('tk')) {
				store.readTickets([this.ticket_id])
				return
			}
			if (!this.trueTicketId) return
			store.readTickets([this.trueTicketId])
		},

		async loadData() {
			this.readTicket()
			let idorNumber = this.ticket_id // or number
			let ticket = store.matchTicketFromIdOrNumber(idorNumber)
			let shouldSync = true
			if (!ticket) {
				this.loading = true
				let res = await store.fetchTicket(idorNumber)
				if (res.error) {
					this.error = res.error || {}
					this.loading = false
					return
				}
				ticket = res
				this.error = null
				shouldSync = false
			}
			let convoids = lo.cloneDeep(lo.get(ticket, 'associated_conversations') || [])
			await store.fetchConvos(convoids)
			let relatedConvos = lo.map(convoids, (cid) => store.matchConvo(cid))
			this.relatedConvos = lo.filter(relatedConvos, Boolean)
			this.loading = false
			this.trueTicketId = ticket.id
			store.markSeeingConvo(this.trueTicketId)
			this.readTicket()
			this.fillData()
			if (shouldSync) {
				this.softLoading = true
				await store.fetchTicket(this.trueTicketId)
				this.softLoading = false
				this.fillData()
			}
		},

		async fillData() {
			let ticket = store.matchTicket(this.trueTicketId)
			let description = lo.get(ticket, 'description') || {format: 'html'}
			this.initNote = lo.cloneDeep(description)
			this.title = lo.get(ticket, 'title', '')
			await this.$nextTick()
			this.setTitleInputHeight()
			this.setCustomAttributesData()
		},

		setCustomAttributesData() {
			let ticket = store.matchTicket(this.trueTicketId) || {}
			let attrs = lo.get(ticket, 'attrs') || []
			let type = store.matchTicketType()[ticket.type_id]
			let defs = lo.get(type, 'defs') || []

			let results = []
			lo.each(defs, (attrdef) => {
				let attr = lo.find(attrs, (attr) => attr.key === attrdef.key)
				if (!attr) {
					results.push({
						key: attrdef.key,
						type: attrdef.type || 'text',
					})
					return
				}

				attrs = lo.filter(attrs, (attr) => attr.key !== attrdef.key)
				results.push(attr)
			})

			results = results.concat(attrs)
			this.attrs = results
		},

		renderCustomAttrTextInputs(attr, idx) {
			return (
				<div class='d-flex flex-column flex__1'>
					<div class='d-flex align-items-center'>
						<input
							class='form-control form-control__light form-control-sm'
							value={attr.text}
							vOn:input={(e) => this.onChangeCustomAttr(idx, {text: e.target.value})}
						/>
						<Icon
							name='plus'
							title={this.$t('add_value')}
							size='16'
							class='ml-2 x-icon'
							vOn:click={() => this.addCustomAttrOtherValue(idx)}
						/>
					</div>
					{lo.map(attr.other_values, (v, oidx) => {
						return (
							<div class='d-flex align-items-center mt-2'>
								<input
									class='form-control form-control__light form-control-sm'
									value={v}
									ref={`custom_attr_value_input_${idx}_other_value_${oidx}`}
									vOn:input={(e) => this.onChangeCustomAttrOtherValue(idx, oidx, e.target.value)}
								/>
								<Icon
									name='circle-minus'
									title={this.$t('remove')}
									size='16'
									class='ml-2 x-icon'
									vOn:click={() => this.onRemoveCustomAttrOtherValue(idx, oidx)}
								/>
							</div>
						)
					})}
				</div>
			)
		},

		renderCustomAttrTextAreas(attr, idx) {
			return (
				<div class='d-flex flex-column flex__1'>
					<div class='d-flex'>
						<textarea
							rows='2'
							style='resize: none'
							class='form-control form-control__light form-control-sm'
							value={attr.text}
							vOn:input={(e) => this.onChangeCustomAttr(idx, {text: e.target.value})}
						/>
						<Icon
							name='plus'
							title={this.$t('add_value')}
							size='16'
							class='ml-2 x-icon mt-2'
							vOn:click={() => this.addCustomAttrOtherValue(idx)}
						/>
					</div>
					{lo.map(attr.other_values, (v, oidx) => {
						return (
							<div class='d-flex mt-2'>
								<textarea
									rows='2'
									style='resize: none'
									class='form-control form-control__light form-control-sm'
									value={v}
									ref={`custom_attr_value_input_${idx}_other_value_${oidx}`}
									vOn:input={(e) => this.onChangeCustomAttrOtherValue(idx, oidx, e.target.value)}
								/>
								<Icon
									name='circle-minus'
									title={this.$t('remove')}
									size='16'
									class='ml-2 x-icon mt-2'
									vOn:click={() => this.onRemoveCustomAttrOtherValue(idx, oidx)}
								/>
							</div>
						)
					})}
				</div>
			)
		},

		toggleCustomAttrCheckboxValue(idx, value) {
			let attrs = lo.cloneDeep(this.attrs)
			let attr = lo.get(attrs, idx) || {}

			let values = []
			if (lo.trim(attr.text)) values.push(attr.text)
			lo.each(attr.other_values, (v) => {
				if (lo.trim(v)) values.push(v)
			})
			if (values.includes(value)) {
				values = lo.filter(values, (v) => v !== value)
			} else {
				values.push(value)
			}

			let obj = {text: '', other_values: []}
			if (lo.size(values)) {
				let [text, ...otherValues] = values
				obj.text = text
				obj.other_values = otherValues
			}
			this.onChangeCustomAttr(idx, obj)
		},

		renderCustomAttrCheckboxes(attr, idx, attrdef) {
			let items = lo.get(attrdef, 'items') || []
			let values = []
			if (lo.trim(attr.text)) values.push(attr.text)
			lo.each(attr.other_values, (v) => {
				if (lo.trim(v)) values.push(v)
			})
			return (
				<div class='d-flex flex-wrap flex__1'>
					{lo.map(items, (item, iidx) => {
						return (
							<div class='form-check mr-3 mb-2' style='width: calc(50% - 10px); overflow: hidden'>
								<input
									name={`custom_attr_${attr.key}_cb`}
									id={`custom_attr_${attr.key}_cb_${item.value}`}
									type='checkbox'
									class='form-check-input form-check-input--bold'
									checked={values.includes(item.value)}
									vOn:change={() => this.toggleCustomAttrCheckboxValue(idx, item.value)}
								/>
								<label
									for={`custom_attr_${attr.key}_cb_${item.value}`}
									class='form-check-label text__sm'
									style='padding-left: 5px'
									title={item.label}
								>
									{item.label}
								</label>
							</div>
						)
					})}
				</div>
			)
		},

		renderCustomAttrRadios(attr, idx, attrdef) {
			let items = lo.get(attrdef, 'items') || []
			return (
				<div class='d-flex flex-wrap flex__1'>
					{lo.map(items, (item, iidx) => {
						return (
							<div class='form-check mr-3 mb-2' style='width: calc(50% - 10px); overflow: hidden'>
								<input
									name={`custom_attr_${attr.key}_cb`}
									id={`custom_attr_${attr.key}_cb_${item.value}`}
									type='radio'
									class='form-check-input form-check-input--bold'
									checked={attr.text === item.value}
									vOn:change={() => this.onChangeCustomAttr(idx, {text: item.value})}
								/>
								<label
									for={`custom_attr_${attr.key}_cb_${item.value}`}
									class='form-check-label text__sm'
									style='padding-left: 5px'
									title={item.label}
								>
									{item.label}
								</label>
							</div>
						)
					})}
				</div>
			)
		},

		renderCustomAttrDropdown(attr, idx, attrdef) {
			let items = lo.get(attrdef, 'items') || []
			items = lo.map(items, (item) => ({id: item.value, label: item.label}))

			if (attr.text) {
				items.unshift({id: '', html: <em>{this.$t('unselect')}</em>})
			}

			return (
				<div class='flex__1'>
					<Dropdown2
						items={items}
						mode='input'
						extra_cls={'form-control form-control__light dropdown_input__small'}
						selected={attr.text}
						placeholder={this.$t('select')}
						vOn:select={(item) => this.onChangeCustomAttr(idx, {text: item.id})}
					/>
				</div>
			)
		},

		renderCustomAttrDateInputs(attr, idx) {
			let date = attr.datetime ? format(new Date(attr.datetime), 'yyyy-MM-dd') : ''
			return (
				<div class='d-flex flex-column flex__1'>
					<div class='d-flex align-items-center'>
						<input
							type='date'
							class='form-control form-control__light form-control-sm'
							value={date}
							vOn:input={(e) =>
								this.onChangeCustomAttr(idx, {datetime: e.target.value ? new Date(e.target.value).toISOString() : ''})
							}
						/>
						<Icon
							name='plus'
							title={this.$t('add_value')}
							size='16'
							class='ml-2 x-icon'
							vOn:click={() => this.addCustomAttrOtherValue(idx)}
						/>
					</div>
					{lo.map(attr.other_values, (v, oidx) => {
						let date = v ? format(new Date(v), 'yyyy-MM-dd') : ''
						return (
							<div class='d-flex align-items-center mt-2'>
								<input
									type='date'
									class='form-control form-control__light form-control-sm'
									value={date}
									ref={`custom_attr_value_input_${idx}_other_value_${oidx}`}
									vOn:input={(e) =>
										this.onChangeCustomAttrOtherValue(
											idx,
											oidx,
											e.target.value ? new Date(e.target.value).toISOString() : '',
										)
									}
								/>
								<Icon
									name='circle-minus'
									title={this.$t('remove')}
									size='16'
									class='ml-2 x-icon'
									vOn:click={() => this.onRemoveCustomAttrOtherValue(idx, oidx)}
								/>
							</div>
						)
					})}
				</div>
			)
		},

		renderCustomAttrDateTimeInputs(attr, idx) {
			let date = attr.datetime ? format(new Date(attr.datetime), 'yyyy-MM-dd HH:mm') : ''
			return (
				<div class='d-flex flex-column flex__1'>
					<div class='d-flex align-items-center'>
						<input
							type='datetime-local'
							class='form-control form-control__light form-control-sm'
							value={date}
							vOn:input={(e) =>
								this.onChangeCustomAttr(idx, {datetime: e.target.value ? new Date(e.target.value).toISOString() : ''})
							}
						/>
						<Icon
							name='plus'
							title={this.$t('add_value')}
							size='16'
							class='ml-2 x-icon'
							vOn:click={() => this.addCustomAttrOtherValue(idx)}
						/>
					</div>
					{lo.map(attr.other_values, (v, oidx) => {
						let date = v ? format(new Date(v), 'yyyy-MM-dd HH:mm') : ''
						return (
							<div class='d-flex align-items-center mt-2'>
								<input
									type='datetime-local'
									class='form-control form-control__light form-control-sm'
									value={date}
									ref={`custom_attr_value_input_${idx}_other_value_${oidx}`}
									vOn:input={(e) =>
										this.onChangeCustomAttrOtherValue(
											idx,
											oidx,
											e.target.value ? new Date(e.target.value).toISOString() : '',
										)
									}
								/>
								<Icon
									name='circle-minus'
									title={this.$t('remove')}
									size='16'
									class='ml-2 x-icon'
									vOn:click={() => this.onRemoveCustomAttrOtherValue(idx, oidx)}
								/>
							</div>
						)
					})}
				</div>
			)
		},

		renderCustomAttrBoolean(attr, idx) {
			let checked = lo.get(attr, 'boolean')
			return (
				<div class='flex__1 d-flex align-items-center'>
					<Sw checked={checked} vOn:change={(checked) => this.onChangeCustomAttr(idx, {boolean: checked})} />
				</div>
			)
		},

		renderCustomAttrNumberInputs(attr, idx) {
			return (
				<div class='d-flex flex-column flex__1'>
					<div class='d-flex align-items-center'>
						<input
							type='number'
							class='form-control form-control__light form-control-sm'
							value={attr.number}
							vOn:input={(e) => this.onChangeCustomAttr(idx, {number: +e.target.value})}
						/>
						<Icon
							name='plus'
							title={this.$t('add_value')}
							size='16'
							class='ml-2 x-icon'
							vOn:click={() => this.addCustomAttrOtherValue(idx)}
						/>
					</div>
					{lo.map(attr.other_values, (v, oidx) => {
						return (
							<div class='d-flex align-items-center mt-2'>
								<input
									class='form-control form-control__light form-control-sm'
									type='number'
									value={v}
									ref={`custom_attr_value_input_${idx}_other_value_${oidx}`}
									vOn:input={(e) => this.onChangeCustomAttrOtherValue(idx, oidx, e.target.value)}
								/>
								<Icon
									name='circle-minus'
									title={this.$t('remove')}
									size='16'
									class='ml-2 x-icon'
									vOn:click={() => this.onRemoveCustomAttrOtherValue(idx, oidx)}
								/>
							</div>
						)
					})}
				</div>
			)
		},

		renderCustomAttrRow(ticket, attr, idx) {
			let type = store.matchTicketType()[ticket.type_id] || {id: ticket.type_id}
			let defs = lo.get(type, 'defs') || []
			let attrdef = lo.find(defs, (def) => def.key === attr.key) || {type: 'text'}

			let $content = this.renderCustomAttrTextInputs(attr, idx)
			if (attrdef.type === 'text') {
				if (attrdef.multiple_line) $content = this.renderCustomAttrTextAreas(attr, idx)
				if (attrdef.select === 'dropdown') $content = this.renderCustomAttrDropdown(attr, idx, attrdef)
				if (attrdef.select === 'radio') $content = this.renderCustomAttrRadios(attr, idx, attrdef)
				if (attrdef.select === 'checkbox') $content = this.renderCustomAttrCheckboxes(attr, idx, attrdef)
			}
			if (attrdef.type === 'number') $content = this.renderCustomAttrNumberInputs(attr, idx)
			if (attrdef.type === 'boolean') $content = this.renderCustomAttrBoolean(attr, idx)
			if (attrdef.type === 'datetime') {
				if (attrdef.is_date_only) $content = this.renderCustomAttrDateInputs(attr, idx)
				else $content = this.renderCustomAttrDateTimeInputs(attr, idx)
			}

			return (
				<div class='ticket_detail_custom_attr_row'>
					<div style='width: 90px' class='text__truncate no-shrink text__sm mr-3' title={attrdef.label || attr.key}>
						{attrdef.label || attr.key}
					</div>
					{$content}
				</div>
			)
		},

		onUcidChange(uid, cid, isEditorFocused, isMounted) {
			if (!this.inconvo) {
				// onUcidChange always trigger first time when UserProfile mounted
				if (!isMounted) {
					this.selectTabConvo(cid)
					//this.$root.$emit('showQuickview', {user_id: uid, convo_id: cid})
				}
			} else {
				// onUcidChange always trigger first time when UserProfile mounted, so add these logic to avoid unexpteced redirect when click view customer
				if (!isMounted) {
					this.$emit('ucidChange', uid, cid)
				}
			}
		},

		async onChange(obj) {
			if (this.saving) return
			this.saving = true
			let id = this.trueTicketId
			let fields = []
			lo.each(obj, (_, key) => {
				fields.push(key)
			})
			let res = await store.updateTicket({
				id,
				...obj,
				_update_fields: fields,
			})
			this.saving = false
			if (res.error) {
				this.$showError(res.error)
				return
			}
			this.$showSuccess(this.$t('success'))
		},

		renderPriority(ticket) {
			let $content = (
				<div class='d-flex align-items-center' key='no_priority'>
					<Icon name='antenna-bars-1' class='mr-2 text__secondary' size='16' style='position: relative; top: -4px;' />
					{this.$t('low')}
				</div>
			)
			if (ticket.priority === 'urgent') {
				$content = (
					<div class='d-flex align-items-center' key='urgent_priority'>
						<Icon name='alert-square-filled' class='mr-2 text__danger' size='16' />
						{this.$t('urgent')}
					</div>
				)
			}
			if (ticket.priority === 'high') {
				$content = (
					<div class='d-flex align-items-center' key='high_priority'>
						<Icon name='antenna-bars-5' stroke-width='2' class='mr-2 text__secondary' size='16' />
						{this.$t('high')}
					</div>
				)
			}

			let items = [
				{
					id: '',
					icon: <Icon name='antenna-bars-1' size='16' class='text__secondary' style='position: relative; top: -6px' />,
					label: this.$t('low'),
				},
				{
					id: 'high',
					icon: <Icon name='antenna-bars-5' stroke-width='2' size='16' class='text__secondary' />,
					label: this.$t('high'),
				},
				{
					id: 'urgent',
					icon: (
						<Icon name='alert-square-filled' class='text__danger' size='16' style='position: relative; top: -2px' />
					),
					label: this.$t('urgent'),
				},
			]
			return (
				<Dropdown
					mode='custom'
					items={items}
					vOn:select={(item) => this.onChange({priority: item.id})}
					dropdown_width={200}
					style='overflow: hidden'
				>
					<div class='btn btn__sm btn__white align-items-center' style='display: inline-flex'>
						{$content}
						<Icon name='chevron-down' stroke-width='2' size='16' class='text__muted ml-3' />
					</div>
				</Dropdown>
			)
		},

		renderStatus(ticket) {
			let {type, text} = getTicketStateInfo(ticket)
			let $content = (
				<div class='d-flex align-items-center' key='status_new'>
					<Icon name='circle-filled' class={`mr-2 text__${type}`} size='16' />
					{text}
				</div>
			)

			let items = lo.map(['open', 'hold', 'closed'], (state) => {
				let {type, text} = getTicketStateInfo({state})
				return {
					id: state,
					icon: <Icon name='circle-filled' size='16' class={`text__${type}`} />,
					label: text,
				}
			})
			return (
				<Dropdown
					mode='custom'
					items={items}
					vOn:select={(item) => this.onChange({state: item.id})}
					dropdown_width={150}
					style='overflow: hidden'
				>
					<div class='btn btn__sm btn__white align-items-center' style='display: inline-flex'>
						{$content}
						<Icon name='chevron-down' stroke-width='2' size='16' class='text__muted ml-3' />
					</div>
				</Dropdown>
			)
		},

		onSelectAssignee(agentid) {
			let obj = {
				assignee: agentid,
			}
			let ticket = store.matchTicket(this.trueTicketId)
			let currentState = lo.get(ticket, 'state')
			if (!currentState || currentState === 'unassigned') obj.state = 'open'
			this.onChange(obj)
		},

		renderAssignee(ticket) {
			let assignee = lo.get(ticket, 'assignee')
			let $assignee = (
				<div class='d-flex align-items-center'>
					<img width='18' src={unassignedAvatar} style='border-radius: 50%' />
					<div class='ml-3 text__truncate'>{this.$t('unassigned')}</div>
				</div>
			)
			if (assignee) {
				let agent = lo.get(store.matchAgent(), assignee, {})
				$assignee = (
					<div class='d-flex align-items-center'>
						<Avatar notooltip agent={agent} size='16' />
						<div class='ml-2 text__truncate' title={sb.getAgentDisplayName(agent)}>
							{sb.getAgentDisplayName(agent)}
						</div>
					</div>
				)
			}

			let agents = lo.filter(store.matchAgent(), (agent) => agent.type === 'agent' && agent.state === 'active')
			agents = lo.sortBy(agents, [
				(agent) => {
					let name = lo.get(agent, 'fullname') || ''
					name = sb.unicodeToAscii(name).toLowerCase()
					return name
				},
			])
			let items = lo.map(agents, (agent) => ({
				id: agent.id,
				img: agent.avatar_url || sb.getAgentDefaultAvatarUrl(agent),
				label: sb.getAgentDisplayName(agent),
			}))

			return (
				<Dropdown
					mode='custom'
					items={items}
					vOn:select={(item) => this.onSelectAssignee(item.id)}
					dropdown_width={200}
					style='overflow: hidden'
				>
					<div class='btn btn__sm btn__white align-items-center' style='display: inline-flex'>
						{$assignee}
						<Icon name='chevron-down' stroke-width='2' size='16' class='text__muted ml-3' />
					</div>
				</Dropdown>
			)
		},

		renderReporter(ticket) {
			let reporter = lo.get(ticket, 'reporter')
			let agent = lo.get(store.matchAgent(), reporter, {})
			let $reporter = <div class='text__truncate text__muted'>{this.$t('none')}</div>
			if (reporter) {
				$reporter = (
					<div class='d-flex align-items-center'>
						<Avatar notooltip agent={agent} size='16' />
						<div class='ml-2 text__truncate' title={sb.getAgentDisplayName(agent)}>
							{sb.getAgentDisplayName(agent)}
						</div>
					</div>
				)
			}

			let agents = lo.filter(store.matchAgent(), (agent) => agent.type === 'agent' && agent.state === 'active')
			agents = lo.sortBy(agents, [
				(agent) => {
					let name = lo.get(agent, 'fullname') || ''
					name = sb.unicodeToAscii(name).toLowerCase()
					return name
				},
			])
			let items = lo.map(agents, (agent) => ({
				id: agent.id,
				img: agent.avatar_url || sb.getAgentDefaultAvatarUrl(agent),
				label: sb.getAgentDisplayName(agent),
			}))

			return (
				<Dropdown
					mode='custom'
					items={items}
					vOn:select={(item) => this.onChange({reporter: item.id})}
					dropdown_width={200}
					style='overflow: hidden'
				>
					<div class='btn btn__sm btn__white align-items-center' style='display: inline-flex'>
						{$reporter}
						<Icon name='chevron-down' stroke-width='2' size='16' class='text__muted ml-3' />
					</div>
				</Dropdown>
			)
		},

		renderDescription(ticket) {
			return (
				<LexicalEditor
					vOn:focus={() => (this.isDescInputFocused = true)}
					vOn:blur={() => (this.isDescInputFocused = false)}
					class='edit_ticket_modal_form_desc_editor'
					placeholder={this.$t('add_description')}
					useMention
					html
					no_preview_mode
					useAutoLink
					initMessage={this.initNote}
					ref='note_input'
					vOn:change={(msg) => {
						this.onChangeNoteDesc(ticket, msg)
						this.throttleUpdateDescription()
					}}
				/>
			)
		},

		onChangeNoteDesc(ticket, msg) {
			this.initNote = msg
		},

		throttleUpdateDescription: lo.debounce(function () {
			this.updateDescription()
		}, 1200),

		async updateDescription() {
			let id = this.trueTicketId
			let storeticket = store.matchTicket(id) || {}
			let isDescChanged = !lo.isEqual(this.initNote, storeticket.description)

			if (!isDescChanged) return
			if (this.noteUpdating) return
			this.noteUpdating = true
			let res = await store.updateTicket({
				id,
				description: this.initNote,
				_update_fields: ['description'],
			})
			this.noteUpdating = false
		},

		clearFileInput(e) {
			e.target.value = ''
		},

		async addLocalFiles(e, type) {
			let $editor = this.$refs.note_input
			if ($editor && $editor.addLocalFiles && typeof $editor.addLocalFiles === 'function') {
				$editor.addLocalFiles(e, type)
			}
		},

		setTitleInputHeight(force) {
			let $input = this.$refs.title_input
			if ($input) {
				$input.style.height = 'auto' // must set auto first because scrollHeight doesnt reset if 2line => 1line
				$input.style.height = $input.scrollHeight + 'px'
			}
		},

		onChangeTitle(e) {
			this.title = e.target.value
			this.setTitleInputHeight()
			this.throttleUpdateTitle()
		},

		throttleUpdateTitle: lo.debounce(function () {
			this.updateTitle()
		}, 1200),

		async updateTitle() {
			let id = this.trueTicketId
			let storeticket = store.matchTicket(id) || {}
			let isTitleChanged = storeticket.title !== this.title

			if (!isTitleChanged) return
			if (this.titleUpdating) return
			this.titleUpdating = true
			await store.updateTicket({
				id,
				title: this.title,
				_update_fields: ['title'],
			})
			this.titleUpdating = false
		},
		onTitleInputPress(e) {
			if (e.keyCode === 13) {
				e.target.blur()
				if (this.$refs.note_input) this.$refs.note_input.focus()
				e.stopPropagation()
			}
		},

		renderTitle() {
			let cls = 'edit_ticket_modal_form_title_input'
			let style = {
				width: '100%',
				margin: 0,
				fontSize: '30px',
				padding: 0,
			}

			return (
				<textarea
					placeholder={this.$t('untitled2')}
					value={this.title}
					vOn:focus={() => (this.isTitleInputFocused = true)}
					vOn:blur={() => (this.isTitleInputFocused = false)}
					vOn:input={this.onChangeTitle}
					ref='title_input'
					class={cls}
					style={style}
					spellcheck='false'
					vOn:keypress={this.onTitleInputPress}
					rows='1'
				></textarea>
			)
		},

		renderOverviewStatus(ticket) {
			if (this.inconvo) return null

			let $syncing = null
			if (this.softLoading) {
				$syncing = (
					<fragment>
						<div class='ticket_detail_page_subtitle_seperator' />
						<div class='text__primary text__semibold'>{this.$t('syncing')}</div>
					</fragment>
				)
			}
			let {text, type} = getTicketStateInfo(ticket)
			if (ticket.state === 'open') {
				text = this.$t('open')
			}
			let $status = (
				<div class={{'d-flex': true, 'align-items-center': true}}>
					<div class={`dot dot__lg dot__${type}`} style='margin-top: 0' />
					<span class='ml-2'>{text}</span>
				</div>
			)

			let $priority = null
			if (ticket.priority === 'high') {
				$priority = (
					<div class='d-flex align-items-center'>
						<Icon name='antenna-bars-5' size='16' />
						<div class='ml-2'>{this.$t('high_priority')}</div>
					</div>
				)
			}
			if (ticket.priority === 'urgent') {
				$priority = (
					<div class='d-flex align-items-center text__danger'>
						<Icon name='alert-square-filled' size='16' />
						<div class='ml-2'>{this.$t('urgent')}</div>
					</div>
				)
			}

			return (
				<div class='d-flex align-items-center mt-2' style={{height: 24}}>
					{$status}
					<div class='ticket_detail_page_subtitle_seperator' />
					<span class='text__muted'>{this.$t('created')}&nbsp;</span>
					<Time class='text__muted' time={ticket.created} ago suffix />
					{$syncing}
				</div>
			)
		},

		renderTabs(ticket) {
			let firstUserId = lo.get(ticket, 'users.0.id')
			let firstConvoId = lo.get(this.relatedConvos, '0.id')

			let $convoTab = (
				<div
					class={{tab__item: true, tab__item__active: this.tab === 'convo'}}
					vOn:click={() => this.selectTabConvo(this.selectedConvoId ? this.selectedConvoId : firstConvoId)}
				>
					{this.$t('conversation')}
				</div>
			)
			if (lo.size(this.relatedConvos) > 1) {
				let items = lo.map(this.relatedConvos, (convo) => {
					let channel = lo.get(convo, 'touchpoint.channel')
					let label = this.$t('Website')
					let img = require('../assets/img/subiz_channel.svg')
					let desc = ''

					let byText = 'Agent'
					let ev = lo.get(convo, 'last_message_sent') || {}
					let byType = lo.get(ev, 'by.type')
					let byId = lo.get(ev, 'by.id')
					if (byType === 'user') {
						byText = this.$t('cust')
					} else if (byType === 'system' || byType === 'subiz') {
						byText = this.$t('system')
					} else {
						byText = sb.getAgentDisplayName(store.matchAgent()[byId]) || 'Agent'
					}
					desc = byText + ': ' + lo.get(convo, 'last_message_sent.data.message.text') || ''

					if (channel === 'facebook_comment') {
						label = this.$t('facebook_comment_short')
						img = require('../assets/img/facebook_channel.svg')
					}
					if (channel === 'facebook') {
						label = this.$t('Messenger')
						img = require('../assets/img/messenger_channel.svg')
					}
					if (channel === 'instagram') {
						label = this.$t('Instagram')
						img = require('../assets/img/instagram.svg')
					}
					if (channel === 'instagram_comment') {
						label = this.$t('instagram_comment_short')
						img = require('../assets/img/instagram.svg')
					}
					if (channel === 'zalo') {
						label = this.$t('Zalo')
						img = require('../assets/img/zalo_channel.svg')
					}
					if (channel === 'email') {
						label = this.$t('Email')
						img = require('../assets/img/email_channel.svg')
						let html = lo.get(convo, 'last_message_sent.data.message.text')
						desc = byText + ': ' + sb.lexicalToPlainText(html)
						if (convo.subject) desc = convo.subject
					}
					if (channel === 'form') {
						label = this.$t('Form')
						img = require('../assets/img/form.svg')
					}
					if (channel === 'google_review') {
						label = this.$t('google_review')
						img = require('../assets/img/google_review_channel.svg')
					}
					if (channel === 'google_question') {
						label = this.$t('google_question')
						img = require('../assets/img/google_question_channel.svg')
					}

					return {
						id: convo.id,
						img,
						label,
						desc,
					}
				})

				$convoTab = (
					<div class={{tab__item: true, tab__item__active: this.tab === 'convo'}}>
						<Dropdown
							mode='custom'
							items={items}
							dropdown_width={200}
							vOn:select={(item) => this.selectTabConvo(item.id)}
						>
							<div
								class='d-flex align-items-center'
								style={{paddingTop: '10px', paddingBottom: '10px', marginTop: '-10px', marginBottom: '-10px'}}
							>
								{this.$t('conversation')} <Icon name='chevron-down' class='ml-2' size='18' />
							</div>
						</Dropdown>
					</div>
				)
			}

			return (
				<div class='tab'>
					<div class={{tab__item: true, tab__item__active: this.tab === 'info'}} vOn:click={this.selectTabInfo}>
						{this.$t('information')}
					</div>
					<div
						class={{tab__item: true, tab__item__active: this.tab === 'user'}}
						vOn:click={() => this.selectTabUser(this.selectedUserId ? this.selectedUserId : firstUserId)}
					>
						{this.$t('customer')}
					</div>
					{$convoTab}
				</div>
			)
		},

		selectTabInfo() {
			this.tab = 'info'
		},

		selectTabUser(uid) {
			this.tab = 'user'
			this.selectedUserId = uid
		},

		async selectTabConvo(cid) {
			this.tab = 'convo'
			this.selectedConvoId = cid

			if (!lo.find(this.relatedConvos, (convo) => convo.id === cid)) {
				let newConvo = store.matchConvo(cid)
				if (!newConvo) {
					await store.fetchConvos([cid])
					newConvo = store.matchConvo(cid) || {id: cid}
				}
				this.relatedConvos = [...this.relatedConvos, newConvo]
			}
		},

		renderDueDate(ticket) {
			let $content = this.$t('set_due_date')

			if (ticket.due_at) {
				let fm = lo.get(store.me(), 'account.date_format') || 'dd/MM/yyyy'
				fm = fm.replace(/DD/g, 'dd')
				fm = fm.replace(/Y/g, 'y')
				let dueTextTooltip = format(ticket.due_at, `${fm}, HH:mm`)
				$content = <div>{dueTextTooltip}</div>
			}

			return (
				<DueDateDropdown
					value={ticket.due_at}
					has_clear_option
					mode='custom'
					vOn:change={(v) => this.onChange({due_at: v})}
					style='overflow: hidden'
				>
					<div class='btn btn__sm btn__white align-items-center' style='display: inline-flex'>
						<Icon name='calendar' class='mr-2 text__secondary' size='16' title={this.$t('due_date')} />
						{$content}
					</div>
				</DueDateDropdown>
			)
		},

		renderCustomAttributeSectionContent(ticket) {
			return (
				<div>
					{lo.size(this.attrs) ? (
						<div>{lo.map(this.attrs, (attr, idx) => this.renderCustomAttrRow(ticket, attr, idx))}</div>
					) : (
						<div class='text__secondary'>
							{this.$t('this_ticket_type_doesnt_settings_custom_attributes')}.{' '}
							<router-link
								to={{name: 'settings.ticket-type-custom-attr', params: {id: lo.get(ticket, 'type_id')}}}
								target='_blank'
							>
								{this.$t('setting')}
							</router-link>
						</div>
					)}
				</div>
			)
		},

		onNewMessageSuccess(uid, cid) {
			this.selectTabConvo(cid)
		},

		async messageToUser(userid) {
			let user = store.matchUser(userid)
			if (!user) {
				await store.fetchUser(userid)
				user = store.matchUser(userid) || {id: userid}
			}
			let touchpoint = {
				channel: user.channel || 'subiz',
				source: user.channel_source || 'web',
				id: user.profile_id || userid,
			}
			if (user.channel === 'account') {
				touchpoint = {
					channel: 'subiz',
					source: 'web',
					id: userid,
				}
			}
			this.selectedUserId = userid
			this.onShowNewConvo({user_id: userid, touchpoint})
		},

		async sendEmailToUser(userid) {
			let user = store.matchUser(userid, true)
			if (!user) {
				this.emailSending = userid
				await store.fetchUser(userid)
				this.emailSending = ''
				user = store.matchUser(userid, true) || {id: userid}
			}
			let email = sb.getUserTextAttr(user, 'emails') || ''
			this.$root.$emit('show_global_email', {to: email, ticket_id: this.trueTicketId})
			return

			// old logicccc
			let ticket = store.matchTicket(this.trueTicketId) || {}
			let convoids = lo.get(ticket, 'associated_conversations') || []
			convoids = lo.filter(convoids, Boolean)
			if (lo.size(convoids)) {
				this.emailSending = userid
				await store.fetchConvos(convoids)
				this.emailSending = ''
			}
			let emailConvoId = ''
			lo.each(convoids, (cid) => {
				let convo = store.matchConvo(cid) || {}
				if (lo.get(convo, 'touchpoint.channel') === 'email') {
					emailConvoId = cid
				}
			})

			if (emailConvoId) {
				this.selectTabConvo(emailConvoId)
			} else {
				let email = sb.getUserTextAttr(user, 'emails') || ''
				this.$root.$emit('show_global_email', {to: email, ticket_id: this.trueTicketId})
			}
		},

		async removeTicketUser(ticket, uid) {
			let cf = await this.$confirm({
				title: this.$t('remove_ticket_customer'),
				description: this.$t('are_you_sure'),
			})
			if (!cf) return

			let users = ticket.users || []
			users = lo.filter(users, (user) => user.id !== uid)
			await store.updateTicket({
				id: this.trueTicketId,
				users,
				_update_fields: ['users'],
			})
			this.$forceUpdate()
		},

		renderTicketInfoTab(ticket) {
			let style = {}
			if (this.tab !== 'info') style.left = '-2000px'

			let members = lo.get(ticket, 'members') || []
			let agents = lo.map(members, (mem) => {
				if (mem.membership === 'left') return
				let id = mem.id
				return store.matchAgent()[id]
			})
			agents = lo.filter(agents, Boolean)

			let $custcontent = (
				<div class=''>
					<em class='text__muted'>{this.$t('no_customer_related')}</em>
				</div>
			)
			let users = lo.get(ticket, 'users') || []
			if (lo.size(users)) {
				$custcontent = (
					<div>
						{lo.map(users, (user, idx) => {
							let isNotLast = idx < lo.size(users) - 1
							return (
								<div class={{'d-flex': true, 'align-items-center': true, 'mb-2': isNotLast}}>
									<div
										class='flex__1 d-flex align-items-center mr-4 clickable'
										style='overflow: hidden'
										vOn:click={() => this.selectTabUser(user.id)}
									>
										<Avatar user={user} size='32' />
										<div class='text__truncate ml-3 text__semibold'>
											<UserDisplayName user={user} />
										</div>
									</div>
									<div class='btn btn__white btn__sm' vOn:click={() => this.selectTabUser(user.id)}>
										{this.$t('view')}
									</div>
									<Icon
										name='trash'
										size='18'
										class='ml-3 x-icon'
										vOn:click={() => this.removeTicketUser(ticket, user.id)}
									/>
									{/*
									<button
										type='button'
										style='width: 72px; display: inline-flex'
										class='btn btn__primary mr-3 btn__sm align-items-center justify-content-center'
										disabled={this.emailSending === user.id}
										vOn:click={() => this.sendEmailToUser(user.id)}
									>
										{this.$t('Email')}
										{this.emailSending === user.id && <Icon name='loader-2' size='16' class='ml-2 rotate' />}
									</button>
									<div class='btn btn__outline_primary btn__sm' vOn:click={() => this.messageToUser(user.id)}>
										{this.$t('inbox')}
									</div>
                  */}
								</div>
							)
						})}
					</div>
				)
			}

			return (
				<div class='ticket_detail_page_right_col_inner' style={style}>
					<div class='ticket_detail_page_info_section'>
						<div class='d-flex align-items-center mb-3'>
							<div class='text__muted' style='width: 150px'>
								{this.$t('status')}
							</div>
							{this.renderStatus(ticket)}
						</div>
						<div class='d-flex align-items-center mb-3'>
							<div class='text__muted' style='width: 150px'>
								{this.$t('assignee')}
							</div>
							{this.renderAssignee(ticket)}
						</div>
						<div class='d-flex align-items-center mb-3'>
							<div class='text__muted' style='width: 150px'>
								{this.$t('priority_short')}
							</div>
							{this.renderPriority(ticket)}
						</div>
						<div class='d-flex align-items-center mb-3'>
							<div class='text__muted' style='width: 150px'>
								{this.$t('due_date')}
							</div>
							{this.renderDueDate(ticket)}
						</div>
						<div class='d-flex align-items-center'>
							<div class='text__muted' style='width: 150px'>
								{this.$t('reporter')}
							</div>
							{this.renderReporter(ticket)}
						</div>
					</div>

					<div class='ticket_detail_page_info_section'>
						<div class='d-flex align-items-center mb-2'>
							<div class='ticket_detail_page_info_section_title'>{this.$t('members')}</div>
						</div>
						<div class='d-flex align-items-center'>
							<div class='clickable' vOn:click={this.openMembersModal}>
								{lo.size(agents) > 0 ? (
									<AvatarGroup agents={agents} sm size={5} nodot />
								) : (
									<em class='text__muted'>{this.$t('no_members')}</em>
								)}
							</div>
							<div
								vOn:click={this.openMembersModal}
								class='btn btn__light ml-2 d-flex align-items-center justify-content-center'
								style='padding: 0;width: 24px; height: 24px; border-radius: 50%; display: flex;'
								v-tooltip={this.$t('add_members')}
							>
								<Icon name='plus' size='16' class='text__secondary' />
							</div>
						</div>
					</div>
					<div class='ticket_detail_page_info_section'>
						<div class='d-flex mb-2 align-items-center'>
							<div class='ticket_detail_page_info_section_title'>{this.$t('customer')}</div>
							<div class='ml-auto link' vOn:click={() => (this.isAddTicketCustomerModalOpen = true)}>
								+ {this.$t('add_lead')}
							</div>
						</div>
						{$custcontent}
					</div>
					<div class='ticket_detail_page_info_section'>
						<div class='ticket_detail_page_info_section_title mb-2'>{this.$t('custom_attributes')}</div>
						{this.renderCustomAttributeSectionContent(ticket)}
					</div>
					<div class='ticket_detail_page_info_section'>
						<div class='d-flex align-items-center mb-2'>
							<div class='ticket_detail_page_info_section_title'>{this.$t('sla_policy')}</div>
							{ticket.sla_policy && (
								<a href='javascript:;' class='ml-auto' vOn:click={() => (this.isSLAModalOpened = true)}>
									{this.$t('detail')}
								</a>
							)}
						</div>
						{ticket.sla_policy ? (
							<Fragment>
								{this.renderSLAFirstResponse(ticket)}
								{this.renderSLAEveryResonse(ticket)}
								{this.renderSLAPeriodicResonse(ticket)}
								{this.renderSLAResolution(ticket)}
							</Fragment>
						) : (
							<div>
								<a href='javascript:;' vOn:click={() => (this.isSLAModalOpened = true)}>
									{this.$t('apply_sla')}
								</a>
							</div>
						)}
					</div>
				</div>
			)
		},

		renderSLAPeriodicResonse(ticket) {
			const WARNING_DURATION = 3600_000 // 1 hour to use badge warning

			let due = lo.get(ticket, 'periodic_update_due')
			let $icon = <div style='width: 18px' />
			let $text = this.$t('periodic_response')
			let $due = null
			if (!due) {
				$due = <span class='text__muted text__sm'>{this.$t('no_need_resolve')}</span>
				$text = <span class='text__muted'>{this.$t('periodic_response')}</span>
			} else if (Date.now() <= due) {
				$icon = <Icon name='check' stroke-width='2' size='18' class='text__muted' />
				$due = (
					<span class='text__muted text__sm'>
						{this.$t('remain_short', [formatDistanceStrict(Date.now(), due, {locale: getDateFnsLocale()})])}
					</span>
				)

				if (due - Date.now() <= WARNING_DURATION) {
					$text = <b>{this.$t('periodic_response')}</b>
					$due = (
						<span class='badge badge__warning'>
							{this.$t('remain_short', [formatDistanceStrict(Date.now(), due, {locale: getDateFnsLocale()})])}
						</span>
					)
				}
			} else {
				$icon = <Icon name='check' size='18' stroke-width='2' class='text__muted' />
				$due = (
					<span class='badge badge__danger'>
						{this.$t('overdue_short', [formatDistanceStrict(Date.now(), due, {locale: getDateFnsLocale()})])}
					</span>
				)
			}

			if (ticket.periodic_update_sla_state === 'violated') {
				$icon = <Icon name='x' size='18' stroke-width='2' class='text__danger' />
			} else if (ticket.periodic_update_sla_state === 'done') {
				$icon = <Icon name='check' stroke-width='2' size='18' class='text__success' />
			} else if (ticket.periodic_update_sla_state === 'not_apply') {
				$text = <span class='text__muted'>{this.$t('periodic_response')}</span>
				$due = <span class='text__muted text__sm'>{this.$t('no_apply')}</span>
			} else if (ticket.periodic_update_sla_state === 'in_process') {
				$icon = <Icon name='check' stroke-width='2' size='18' class='text__muted' />
			}

			return (
				<div class='d-flex align-items-center mb-2'>
					{$icon}
					<div class='ml-3 flex__1 mr-3'>{$text}</div>
					{$due}
				</div>
			)
		},

		renderSLAEveryResonse(ticket) {
			const WARNING_DURATION = 3600_000 // 1 hour to use badge warning

			let due = lo.get(ticket, 'every_response_due')
			let $icon = <div style='width: 18px' />
			let $text = this.$t('next_response')
			let $due = null
			if (!due) {
			} else if (Date.now() <= due) {
				$icon = <Icon name='check' stroke-width='2' size='18' class='text__muted' />
				$due = (
					<span class='text__muted text__sm'>
						{this.$t('remain_short', [formatDistanceStrict(Date.now(), due, {locale: getDateFnsLocale()})])}
					</span>
				)

				if (due - Date.now() <= WARNING_DURATION) {
					$text = <b>{this.$t('next_response')}</b>
					$due = (
						<span class='badge badge__warning'>
							{this.$t('remain_short', [formatDistanceStrict(Date.now(), due, {locale: getDateFnsLocale()})])}
						</span>
					)
				}
			} else {
				$icon = <Icon name='check' size='18' stroke-width='2' class='text__muted' />
				$due = (
					<span class='badge badge__danger'>
						{this.$t('overdue_short', [formatDistanceStrict(Date.now(), due, {locale: getDateFnsLocale()})])}
					</span>
				)
			}

			if (ticket.every_response_sla_state === 'violated') {
				$icon = <Icon name='x' size='18' stroke-width='2' class='text__danger' />
			} else if (ticket.every_response_sla_state === 'done') {
				$icon = <Icon name='check' stroke-width='2' size='18' class='text__success' />
			} else if (ticket.every_response_sla_state === 'not_apply') {
				$text = <span class='text__muted'>{this.$t('next_response')}</span>
				$due = <span class='text__muted text__sm'>{this.$t('no_apply')}</span>
			} else if (ticket.every_response_sla_state === 'in_process') {
				$icon = <Icon name='check' stroke-width='2' size='18' class='text__muted' />
			} else if (ticket.every_response_sla_state === 'wait') {
				$icon = <Icon name='circle-dotted' stroke-width='2' size='18' class='text__muted' />
				$due = <span class='text__muted text__sm'>{this.$t('no_need_resolve')}</span>
				$text = <span class='text__muted'>{this.$t('next_response')}</span>
			}

			return (
				<div class='d-flex align-items-center mb-2'>
					{$icon}
					<div class='ml-3 flex__1 mr-3'>{$text}</div>
					{$due}
				</div>
			)
		},

		renderSLAResolution(ticket) {
			const WARNING_DURATION = 3600_000 // 1 hour to use badge warning

			let due = lo.get(ticket, 'resolution_due')
			let $icon = <div style='width: 18px' />
			let $text = this.$t('close_ticket')
			let $due = null
			let now = Date.now()
			if (ticket.state === 'close') now = lo.get(ticket, 'closed') || 0
			if (!due) {
			} else if (now <= due) {
				$due = (
					<span class='text__muted text__sm'>
						{this.$t('remain_short', [formatDistanceStrict(now, due, {locale: getDateFnsLocale()})])}
					</span>
				)

				if (due - now <= WARNING_DURATION) {
					$text = <b>{this.$t('close_ticket')}</b>
					$due = (
						<span class='badge badge__warning'>
							{this.$t('remain_short', [formatDistanceStrict(now, due, {locale: getDateFnsLocale()})])}
						</span>
					)
				}
			} else {
				$due = (
					<span class='badge badge__danger'>
						{this.$t('overdue_short', [formatDistanceStrict(now, due, {locale: getDateFnsLocale()})])}
					</span>
				)
			}
			if (ticket.resolution_sla_state === 'violated') {
				$icon = <Icon name='x' size='18' stroke-width='2' class='text__danger' />
			} else if (ticket.resolution_sla_state === 'done') {
				$icon = <Icon name='check' stroke-width='2' size='18' class='text__success' />
			} else if (ticket.resolution_sla_state === 'not_apply') {
				$text = <span class='text__muted'>{this.$t('close_ticket')}</span>
				$due = <span class='text__muted text__sm'>{this.$t('no_apply')}</span>
			} else if (ticket.resolution_sla_state === 'in_process') {
				$icon = <Icon name='check' stroke-width='2' size='18' class='text__muted' />
			}

			return (
				<div class='d-flex align-items-center'>
					{$icon}
					<div class='ml-3 flex__1 mr-3'>{$text}</div>
					{$due}
				</div>
			)
		},

		renderSLAFirstResponse(ticket) {
			const WARNING_DURATION = 3600_000 // 1 hour to use badge warning

			let due = lo.get(ticket, 'first_response_due')
			let $icon = <div style='width: 18px' />
			let $text = this.$t('first_response')
			let $due = null
			let now = Date.now()
			if (ticket.agent_first_response_at) now = ticket.agent_first_response_at

			if (!due) {
			} else if (now <= due) {
				$due = (
					<span class='text__muted text__sm'>
						{this.$t('remain_short', [formatDistanceStrict(now, due, {locale: getDateFnsLocale()})])}
					</span>
				)

				if (due - now <= WARNING_DURATION) {
					$text = <b style='font-weight: 700'>{this.$t('first_response')}</b>
					$due = (
						<span class='badge badge__warning'>
							{this.$t('remain_short', [formatDistanceStrict(now, due, {locale: getDateFnsLocale()})])}
						</span>
					)
				}
			} else {
				$due = (
					<span class='badge badge__danger'>
						{this.$t('overdue_short', [formatDistanceStrict(now, due, {locale: getDateFnsLocale()})])}
					</span>
				)
			}

			if (ticket.first_response_sla_state === 'violated') {
				$icon = <Icon name='x' size='18' stroke-width='2' class='text__danger' />
			} else if (ticket.first_response_sla_state === 'done') {
				$icon = <Icon name='check' stroke-width='2' size='18' class='text__success' />
			} else if (ticket.first_response_sla_state === 'not_apply') {
				$text = <span class='text__muted'>{this.$t('first_response')}</span>
				$due = <span class='text__muted text__sm'>{this.$t('no_apply')}</span>
			} else if (ticket.first_response_sla_state === 'in_process') {
				$icon = <Icon name='check' stroke-width='2' size='18' class='text__muted' />
			}

			return (
				<div class='d-flex align-items-center mb-2'>
					{$icon}
					<div class='ml-3 flex__1 mr-3'>{$text}</div>
					{$due}
				</div>
			)
		},

		openMembersModal() {
			this.isMemberModalOpen = true
			this.memberKeyword = ''

			let inviteds = []
			let remains = []
			lo.each(store.matchAgent(), (agent) => {
				if (agent.type !== 'agent') return
				if (this.agentIsMember(agent.id)) {
					inviteds.push(agent)
				} else {
					remains.push(agent)
				}
			})
			inviteds = lo.orderBy(inviteds, [(agent) => sb.unicodeToAscii(agent.fullname)], ['asc'])
			remains = lo.orderBy(remains, [(agent) => sb.unicodeToAscii(agent.fullname)], ['asc'])
			this.memberAgents = [...inviteds, ...remains]
		},

		agentIsMember(agid) {
			let ticket = store.matchTicket(this.trueTicketId)
			let members = lo.get(ticket, 'members') || []
			let mem = lo.find(members, (mem) => mem.id === agid)
			if (!mem) return false
			if (mem.membership === 'left') return false
			return true
		},

		renderEditMembersModal() {
			let cls = 'modal'
			if (!this.isMemberModalOpen) cls += ' modal__hide'
			let filteredAgents = lo.filter(this.memberAgents, (agent) => {
				let keyword = sb.unicodeToAscii(this.memberKeyword).toLowerCase()
				let name = sb.unicodeToAscii(agent.fullname || '').toLowerCase()
				let email = sb.unicodeToAscii(agent.email || '').toLowerCase()

				return name.indexOf(keyword) > -1 || email.indexOf(keyword) > -1
			})

			return (
				<div class={cls}>
					<div class='modal__overlay' vOn:click={() => (this.isMemberModalOpen = false)}></div>
					<div class='modal__container'>
						<div class='assign_agent modal__background'>
							<div class='assign_agent__header align-items-center' style='justify-content: unset'>
								<div>{this.$t('members')}</div>
								<div style='position: relative' class='ml-auto mr-4'>
									<Icon
										name='search'
										size='16'
										style='position: absolute; top: 8px; left: 8px; z-index: 1;'
										class='text__secondary'
									/>
									<input
										class='form-control form-control__light'
										style='width: 150px; height: 32px; padding-left: 30px;'
										placeholder={this.$t('search')}
										vModel={this.memberKeyword}
									/>
								</div>
								<Icon
									name='x'
									v-tooltip={this.$t('close')}
									class='btn__close'
									vOn:click_stop={() => (this.isMemberModalOpen = false)}
								/>
							</div>
							<div class='assign_agent__body' style='height: 550px;'>
								{lo.size(filteredAgents) ? (
									<Fragment>
										{lo.map(filteredAgents, (agent) => {
											return (
												<div class='assign_agent__member'>
													<Avatar class='assign_agent__member_avatar' agent={agent} size='36' />
													<div class='assign_agent__member_info'>
														<div class='assign_agent__member_name'>{sb.getAgentDisplayName(agent)}</div>
														<div class='assign_agent__member_email'>{agent.email}</div>
													</div>
													<button
														style='width: 80px; display: inline-flex'
														disabled={this.loadingInviteAgents.includes(agent.id)}
														class={{
															btn: true,
															btn__sm: true,
															'align-items-center': true,
															'justify-content-center': true,
															'no-shrink': true,
															btn__danger: this.agentIsMember(agent.id),
															btn__primary: !this.agentIsMember(agent.id),
														}}
														vOn:click={() => this.toggleAgentFollow(agent.id)}
													>
														{this.agentIsMember(agent.id) ? this.$t('leave') : this.$t('invite')}
														{this.loadingInviteAgents.includes(agent.id) && (
															<Icon name='loader-2' class='ml-2 rotate' size='16' />
														)}
													</button>
												</div>
											)
										})}
									</Fragment>
								) : (
									<div class='text__center text__muted' style='padding-top:80px'>
										{this.$t('no_result')}
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			)
		},

		async toggleAgentFollow(agid) {
			let id = this.trueTicketId
			let res = {}
			this.loadingInviteAgents = [...this.loadingInviteAgents, agid]
			if (this.agentIsMember(agid)) {
				res = await store.removeTicketMember(id, agid)
			} else {
				res = await store.updateTicketMember(id, {id: agid, type: 'agent', membership: 'observer'})
			}
			this.loadingInviteAgents = lo.filter(this.loadingInviteAgents, (id) => id !== agid)
			if (res.error) {
				this.$showError(res.error)
				return
			}
			this.$forceUpdate()
		},

		renderTicketUserTab() {
			let uid = this.selectedUserId
			let $content = (
				<div style='background: whitesmoke; height: 100%; padding: 10px 20px;'>
					<div class='text__semibold'>{this.$t('customer')}</div>
					<div class='mt-2 text__muted '>
						Chưa có khách hàng nào liên quan tới phiếu ghi này, để thêm khách liên quan, bạn có thể:
					</div>

					<div class='text__muted '>1. Thêm link cuộc chat của khách vào mô tả</div>
					<div class='text__muted '>2. Bình luận link cuộc chat của khách</div>
					<div class='text__muted '>
						3.{' '}
						<a href='javascript:;' vOn:click={() => (this.isAddTicketCustomerModalOpen = true)}>
							{this.$t('add_lead')}
						</a>
					</div>
				</div>
			)
			if (uid) {
				$content = <UserProfile ucid={uid} vOn:ucidChange={this.onUcidChange} vOn:showNewConvo={this.onShowNewConvo} />
			}
			let style = {}
			if (this.tab === 'info') style.left = '2000px'
			if (this.tab === 'convo') style.left = '-2000px'
			return (
				<div class='ticket_detail_page_right_col_inner' style={style}>
					{$content}
				</div>
			)
		},

		renderTicketConvoTab() {
			let style = {display: 'flex', background: 'whitesmoke'}
			if (this.tab !== 'convo') style.left = '2000px'

			return (
				<div class='ticket_detail_page_right_col_inner' style={style}>
					{this.selectedConvoId ? (
						<Conversation
							cid={this.selectedConvoId}
							ref='conversation'
							vOn:ucidChange={(uid, cid) => this.selectTabConvo(cid)}
						/>
					) : (
						<div style='background: whitesmoke; height: 100%; padding: 10px 20px;'>
							<div class='text__semibold'>{this.$t('conversation')}</div>
							<div class='mt-2 text__muted '>
								Chưa có hội thoại nào liên quan tới phiếu ghi này, để thêm hội thoại liên quan, bạn có thể:
							</div>

							<div class='text__muted '>1. Thêm link cuộc chat của khách vào mô tả</div>
							<div class='text__muted '>2. Bình luận link cuộc chat của khách</div>
						</div>
					)}
				</div>
			)
		},

		async onShowNewConvo(param) {
			if (!param) {
				this.isShowFacebookPrivateReply = false
				return
			}

			this.isShowFacebookPrivateReply = true
			this.newConvoUid = param.user_id
			this.newConvoCommentId = param.comment_id
			this.newConvoTouchPoint = param.touchpoint || {}
		},

		async restoreTicket() {
			let res = await store.restoreTicket(this.trueTicketId)
			if (res.error) {
				this.$showError(res.error)
				return
			}

			this.$forceUpdate()
		},

		async deleteTicket() {
			let res = await store.deleteTicket(this.trueTicketId)
			if (res.error) {
				this.$showError(res.error)
				return
			}
			this.$showSuccess(this.$t('success'))

			this.$forceUpdate()
		},

		onChangeCustomAttr(idx, obj) {
			let attrs = lo.cloneDeep(this.attrs)
			lo.each(obj, (val, key) => {
				lo.set(attrs, [idx, key], val)
			})
			this.attrs = attrs
			this.throttleUpdateCustomAttrs()
		},

		onRemoveCustomAttrOtherValue(idx, oidx) {
			let attrs = lo.cloneDeep(this.attrs)
			let attr = attrs[idx]
			if (!attr) return
			let otherValues = attr.other_values || []
			otherValues.splice(oidx, 1)
			attr.other_values = otherValues
			this.attrs = attrs
			this.throttleUpdateCustomAttrs()
		},

		async addCustomAttrOtherValue(idx) {
			let attrs = lo.cloneDeep(this.attrs)
			let attr = attrs[idx]
			if (!attr) return
			let otherValues = attr.other_values || []
			otherValues.push('')
			attr.other_values = otherValues
			this.attrs = attrs
			await this.$nextTick()
			let $input = this.$refs[`custom_attr_label_input_${idx}_other_value_${lo.size(otherValues) - 1}`]
			if ($input) $input.focus()
			this.throttleUpdateCustomAttrs()
		},

		onChangeCustomAttrOtherValue(idx, oidx, v) {
			let attrs = lo.cloneDeep(this.attrs)
			lo.set(attrs, [idx, 'other_values', oidx], v)
			this.attrs = attrs
			this.throttleUpdateCustomAttrs()
		},

		throttleUpdateCustomAttrs: lo.debounce(async function () {
			let id = this.trueTicketId
			let storeticket = store.matchTicket(id) || {}
			if (this.noteUpdating) return
			this.attrUpdating = true
			let res = await store.updateTicket({
				id,
				attrs: this.attrs,
				_update_fields: ['attrs'],
			})
			this.attrUpdating = false
		}, 800),

		renderError() {
			let code = lo.get(this.error, 'code')
			if (code !== 'missing_resource') {
				return (
					<div class='ticket_detail_page'>
						<div
							class='d-flex justify-content-center'
							style='position: absolute; inset: 0; z-index: 2; background: rgba(255,255,255, 0.5)'
						>
							<div class='text__center' style='padding-top: 150px; width: 400px'>
								<div class='mt-4 mb-2 text__danger' style='font-size: 24px'>
									{this.$t('error_occurs_when_load_data')}
								</div>
								<div class='text__muted'>{computeErrorMessage(this.error)}</div>
								<div class='mt-4 d-flex align-items-center justify-content-center'>
									<button
										disabled={this.loading}
										type='button'
										class='btn btn__sm btn__primary align-items-center justify-content-center'
										vOn:click={this.loadData}
										style='width: 90px; display: inline-flex'
									>
										{this.$t('reload')}
										{this.loading && <Icon name='loader-2' size='16' class='rotate ml-2' />}
									</button>
									<div class='link ml-4' vOn:click={this.goBack}>
										{this.$t('back')}
									</div>
								</div>
							</div>
						</div>
					</div>
				)
			}
			return (
				<div class='ticket_detail_page'>
					<div
						class='d-flex justify-content-center'
						style='position: absolute; inset: 0; z-index: 2; background: rgba(255,255,255, 0.5)'
					>
						<div class='text__center' style='padding-top: 150px; width: 400px'>
							<Icon name='mood-confuzed' size='64' stroke-width='1' class='text__secondary' />
							<div class='mt-4 mb-2 text__muted' style='font-size: 24px'>
								{this.$t('ticket_doesnt_existed')}
							</div>
							<div vOn:click={this.goBack} class='d-inline-flex align-items-center link mt-3'>
								<Icon name='arrow-left' size='16' class='mr-2' />
								<div>{this.$t('back')}</div>
							</div>
						</div>
					</div>
				</div>
			)
		},

		goBack() {
			this.$emit('back')
			return
		},
	},

	render() {
		if (this.error) {
			return this.renderError()
		}

		if (this.loading) {
			return (
				<div class='mt-4 ml-4'>
					<Spinner size='24' mode='blue' />
				</div>
			)
		}
		let id = this.trueTicketId
		let ticket = store.matchTicket(id) || {id}

		let wrapperstyle = {}
		if (!canViewTicket(ticket)) wrapperstyle.filter = 'blur(5px)'
		return (
			<div class={{ticket_detail_page: true, inconvo: this.inconvo}}>
				{!canViewTicket(ticket) && (
					<div
						class='d-flex justify-content-center'
						style='position: absolute; inset: 0; z-index: 2; background: rgba(255,255,255, 0.5)'
					>
						<div class='text__center' style='padding-top: 150px; width: 400px'>
							<Icon name='lock' size='64' stroke-width='1' class='text__secondary' />
							<div class='mt-4 mb-2 text__muted' style='font-size: 24px'>
								{this.$t('you_cannot_view_this_ticket', [ticket.number])}
							</div>
							<div class='text__muted'>{this.$t('you_cannot_view_this_ticket_desc')}</div>
							<a
								href='javascript:;'
								vOn:click={() => this.$emit('back')}
								class='d-inline-flex align-items-center link mt-3'
							>
								<Icon name='arrow-left' size='16' class='mr-2' />
								<div>{this.$t('back')}</div>
							</a>
						</div>
					</div>
				)}

				<div class={'ticket_detail_page__container '}>
					{this.renderHeader(ticket)}
					<div class='ticket_detail_page_left_col' style={wrapperstyle}>
						{this.renderOverviewStatus(ticket)}
						{this.inconvo ? (
							<Fragment>
								<div class='d-flex align-items-center mb-3'>
									<div class='text__muted' style='width: 150px'>
										{this.$t('status')}
									</div>
									{this.renderStatus(ticket)}
								</div>
								<div class='d-flex align-items-center mb-3'>
									<div class='text__muted' style='width: 150px'>
										{this.$t('assignee')}
									</div>
									{this.renderAssignee(ticket)}
								</div>
								<div class='d-flex align-items-center mb-3'>
									<div class='text__muted' style='width: 150px'>
										{this.$t('priority_short')}
									</div>
									{this.renderPriority(ticket)}
								</div>
								<div class='d-flex align-items-center mb-3'>
									<div class='text__muted' style='width: 150px'>
										{this.$t('due_date')}
									</div>
									{this.renderDueDate(ticket)}
								</div>
								<div class='d-flex align-items-center'>
									<div class='text__muted' style='width: 150px'>
										{this.$t('reporter')}
									</div>
									{this.renderReporter(ticket)}
								</div>
								<hr class='mt-4 mb-4' />
							</Fragment>
						) : (
							<div class='mt-3'></div>
						)}
						{this.renderTitle()}
						<div class='mt-3 mb-3'>
							{this.renderDescription(ticket)}
							<input
								type='file'
								ref='file_input'
								multiple
								style='display: none;'
								vOn:click={this.clearFileInput}
								vOn:change={this.addLocalFiles}
							/>
							<div
								class='link text__sm link__secondary d-inline-flex align-items-center mt-3'
								vOn:click={() => this.$refs.file_input.click()}
							>
								<Icon name='paperclip' size='16' class='mr-2' />
								{this.$t('add_attachments')}
							</div>
						</div>
						{this.inconvo && (
							<Fragment>
								<hr class='mt-4 mb-4' />
								<div class='text__semibold mb-2'>{this.$t('custom_attributes')}</div>
								{this.renderCustomAttributeSectionContent(ticket)}
								<hr class='mt-4 mb-4' />
								<div class='d-flex align-items-center mb-2'>
									<div class='text__semibold'>{this.$t('sla_policy')}</div>
									{ticket.sla_policy && (
										<a href='javascript:;' class='ml-auto' vOn:click={() => (this.isSLAModalOpened = true)}>
											{this.$t('detail')}
										</a>
									)}
								</div>
								{ticket.sla_policy ? (
									<Fragment>
										{this.renderSLAFirstResponse(ticket)}
										{this.renderSLAEveryResonse(ticket)}
										{this.renderSLAPeriodicResonse(ticket)}
										{this.renderSLAResolution(ticket)}
									</Fragment>
								) : (
									<div>
										<a href='javascript:;' vOn:click={() => (this.isSLAModalOpened = true)}>
											{this.$t('apply_sla')}
										</a>
									</div>
								)}
							</Fragment>
						)}
						<hr class='mt-4 mb-4' />
						<div class='text__semibold mb-3'>{this.$t('activities')}</div>
						<Histories ticket_id={this.trueTicketId} />
					</div>
				</div>
				{!this.inconvo && (
					<div class={{ticket_detail_page_right_col: true, expand: this.tab === 'convo'}} style={wrapperstyle}>
						{this.renderTabs(ticket)}
						<div class='ticket_detail_page_right_col_wrapper'>
							{this.renderTicketInfoTab(ticket)}
							{this.renderTicketUserTab(ticket)}
							{this.renderTicketConvoTab(ticket)}
						</div>
					</div>
				)}
				{this.renderEditMembersModal()}
				<TicketSLAModal
					ticket_id={this.trueTicketId}
					open={this.isSLAModalOpened}
					vOn:close={() => (this.isSLAModalOpened = false)}
				/>
				<FacebookPrivateReply
					show={this.isShowFacebookPrivateReply}
					user_id={this.newConvoUid}
					ticket_id={this.trueTicketId}
					comment_id={this.newConvoCommentId}
					vOn:changeUcid={this.onNewMessageSuccess}
					vOn:close={() => (this.isShowFacebookPrivateReply = false)}
					touchpoint={this.newConvoTouchPoint || {}}
				/>
				<AddTicketUserModal
					open={this.isAddTicketCustomerModalOpen}
					ticket_id={this.trueTicketId}
					vOn:close={() => (this.isAddTicketCustomerModalOpen = false)}
					vOn:success={() => this.$forceUpdate()}
				/>
			</div>
		)
	},
}

function isToday(time) {
	let today = new Date()
	let compareDate = new Date(time)
	return (
		today.getDate() === compareDate.getDate() &&
		today.getMonth() === compareDate.getMonth() &&
		today.getFullYear() === compareDate.getFullYear()
	)
}
